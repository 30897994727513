import {AppTextButton} from "shared/UI/UIKit/Button/components/TextButton";
import {useModal} from "shared/UI/UIKit/Modal";
import styles from "./PasswordRecovery.module.scss";
import {PasswordRecoveryModal} from "./Modal";

export const PasswordRecovery = () => {
  const {state, methods} = useModal();
  return (
    <>
      <AppTextButton onClick={methods.show} className={styles["text-button"]}>
        Забыли пароль?
      </AppTextButton>
      <PasswordRecoveryModal visible={state.visible} onHide={methods.hide}/>
    </>
  );
};