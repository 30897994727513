import {editDictionaryAction} from "features/dictionaries/redux/actions";
import type {ActionReducerMapBuilder} from "@reduxjs/toolkit";
import type {DictionaryState} from "./types";
import {
  createDictionaryItemAction,
  editDictionaryItemAction, getDictionaryAction,
  getDictionaryItemsAction,
  removeDictionaryItemAction
} from "./actions";

export function getDictionaryItemsReducerBuilder(builder: ActionReducerMapBuilder<DictionaryState>) {
  builder.addCase(getDictionaryItemsAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getDictionaryItemsAction.fulfilled, (state, action) => {
    state.loading = false;
    state.items = action.payload.data;
    state.total = action.payload.total;
  });
  builder.addCase(getDictionaryItemsAction.rejected, (state) => {
    state.loading = false;
  });
}

export function createDictionaryItemReducerBuilder(builder: ActionReducerMapBuilder<DictionaryState>) {
  builder.addCase(createDictionaryItemAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(createDictionaryItemAction.fulfilled, (state) => {
    state.loading = false;
  });
  builder.addCase(createDictionaryItemAction.rejected, (state) => {
    state.loading = false;
  });
}

export function editDictionaryItemReducerBuilder(builder: ActionReducerMapBuilder<DictionaryState>) {
  builder.addCase(editDictionaryItemAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(editDictionaryItemAction.fulfilled, (state, action) => {
    state.loading = false;
    state.items = state.items.map(item => {
      return item.id === action.payload.id ? action.payload : item;
    });
  });
  builder.addCase(editDictionaryAction.rejected, (state) => {
    state.loading = false;
  });
}

export function removeDictionaryItemReducerBuilder(builder: ActionReducerMapBuilder<DictionaryState>) {
  builder.addCase(removeDictionaryItemAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(removeDictionaryItemAction.fulfilled, (state, action) => {
    state.loading = false;
    state.items = state.items.filter(item => item.id !== action.payload);
  });
  builder.addCase(removeDictionaryItemAction.rejected, (state) => {
    state.loading = false;
  });
}

export function getDictionaryReducerBuilder(builder: ActionReducerMapBuilder<DictionaryState>) {
  builder.addCase(getDictionaryAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getDictionaryAction.fulfilled, (state, action) => {
    state.loading = false;
    state.dictionary = action.payload;
  });
  builder.addCase(getDictionaryAction.rejected, (state) => {
    state.loading = false;
  });
}