import axios, {isAxiosError} from "axios";
import {LocaleStorageService} from "shared/helpers/local-storage";
import {LSKeys} from "shared/constants/local-storage-keys";
import {getAuthorizationUrl} from "services/navigation";
import type {AxiosRequestConfig} from "axios";

export const APP_API_URL = process.env.REACT_APP_API_URL || "/api";

export const appApi = axios.create({
  baseURL: APP_API_URL,
});

export const isApiError = isAxiosError;

appApi.interceptors.request.use((request: AxiosRequestConfig) => {
  const accessToken = LocaleStorageService.getStringItem(LSKeys.edsooAccessToken);
  if (request.headers && accessToken) {
    request.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  return request;
});

appApi.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    document.location.href = getAuthorizationUrl();
  } else {
    throw error;
  }
});
