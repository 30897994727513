import {AppForm, AppFormItem, formRules} from "shared/UI/UIKit/Form";
import {AppInput} from "shared/UI/UIKit/Input";
import type {DictionaryItemFormProps} from "./types";

export const DictionaryItemForm = (props: DictionaryItemFormProps) => {
  return (
    <AppForm form={props.form}>
      <AppFormItem rules={formRules.required} label="Название" name="name">
        <AppInput placeholder="Введите название"/>
      </AppFormItem>
    </AppForm>
  );
};