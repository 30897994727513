import {useForm} from "antd/lib/form/Form";
import {useEffect} from "react";
import {AppModal} from "shared/UI/UIKit/Modal";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {clearSelectedDictionaryItem} from "features/dictionary/redux/slice";
import {editDictionaryItemAction} from "features/dictionary/redux/actions";
import type {DictionaryItemBody} from "services/api";
import type {DictionaryItemFormValues, DictionaryItemModalProps} from "../types";
import {DictionaryItemModalFooter} from "../ModalFooter/ModalFooter";
import {DictionaryItemForm} from "../../Form/DictionaryItemForm";

export const EditDictionaryItemModal = (props: DictionaryItemModalProps) => {
  const [form] = useForm<DictionaryItemFormValues>();
  const {loading, selectedDictionaryItem, dictionary} = useAppSelector(state => state.dictionaryState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedDictionaryItem) {
      form.setFieldsValue({...selectedDictionaryItem});
    }
  }, [selectedDictionaryItem]);

  const onSubmit = () => {
    form.validateFields().then(values => {
      const body: DictionaryItemBody = {
        name: values.name,
        reference: dictionary!,
      };
      dispatch(editDictionaryItemAction(body))
        .unwrap()
        .then(hideModal);
    });
  };

  const hideModal = () => {
    props.hide();
    dispatch(clearSelectedDictionaryItem());
    form.resetFields();
  };

  const footer = <DictionaryItemModalFooter onSubmit={onSubmit}
                                        hideModal={hideModal}
                                        title="Редактировать"/>;
  const content = <DictionaryItemForm form={form}/>;

  return (
    <AppModal visible={props.visible} onCancel={hideModal}
              content={content} destroyOnClose footer={footer}
              loading={loading} title={selectedDictionaryItem?.name}/>
  );
};