import {createSlice} from "@reduxjs/toolkit";
import type {DictionaryItem} from "services/api";
import type {PayloadAction} from "@reduxjs/toolkit";
import type {DictionaryState} from "./types";
import {
  createDictionaryItemReducerBuilder, editDictionaryItemReducerBuilder,
  getDictionaryItemsReducerBuilder, getDictionaryReducerBuilder, removeDictionaryItemReducerBuilder
} from "./reducers";

const initialState: DictionaryState = {
  items: [],
  total: 0,
  selectedDictionaryItem: null,
  dictionary: null,
  loading: false,
};

const dictionarySlice = createSlice({
  name: "dictionary",
  initialState,
  reducers: {
    setSelectedDictionaryItem: (state, action: PayloadAction<DictionaryItem>) => {
      state.selectedDictionaryItem = action.payload;
    },
    clearSelectedDictionaryItem: (state) => {
      state.selectedDictionaryItem = null;
    },
    clearState: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    getDictionaryItemsReducerBuilder(builder);
    createDictionaryItemReducerBuilder(builder);
    editDictionaryItemReducerBuilder(builder);
    removeDictionaryItemReducerBuilder(builder);
    getDictionaryReducerBuilder(builder);
  }
});

export const dictionaryReducer = dictionarySlice.reducer;
export const {clearState, setSelectedDictionaryItem, clearSelectedDictionaryItem} = dictionarySlice.actions;
