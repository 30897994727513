import {AppTable} from "shared/UI/UIKit/Table";
import type {UsersTableProps} from "./types";
import {getUsersTableColumns} from "./columns";

export const UsersTable = (props: UsersTableProps) => {
  const columns = getUsersTableColumns(props.onUserView, props.onUserRemove);

  return (
    <AppTable dataSource={props.dataSource}
              columns={columns} loading={props.loading}
              rowKey={(record) => record.id}
              pagination={props.tableState.pagination}
              onChange={props.onTableChange}/>
  );
};
