import {useCallback, useEffect, useState} from "react";
import {debounce} from "throttle-debounce";
import {
  getMunicipalities,
  getOrganizations,
  getRegions
} from "services/api";
import {toOptions} from "shared/helpers/utils/to-options";
import {personalDataLocationFormNames} from "common/use-personal-data-location/constants";
import {
  getDefaultTablePagination,
  useTableStateManager
} from "shared/UI/UIKit/Table";
import type {AppTableOnChange} from "shared/UI/UIKit/Table";
import type {
  UserProfileOrganization,
  UserProfileRegion
  , UserProfileMunicipality
, GetOrganizationParams} from "services/api";
import type {FormInstance} from "antd/lib/form";

const getInitialState = () => {
  return {
    sorters: [
      {
        key: "name",
        dataIndex: ["name"]
      },
      {
        key: "fullName",
        dataIndex: ["fullName"]
      }
    ],
    filters: [],
    pagination: {
      ...getDefaultTablePagination(),
      total: 0,
      current: 1,
      pageSize: 20,
      defaultPageSize: 20,
      showSizeChanger: false,
      hideOnSinglePage: true,
    }
  };
};

export const useOrganizations = (form: FormInstance) => {
  const [loading, setLoading] = useState(false);
  const [regions, setRegions] = useState<UserProfileRegion[]>([]);
  const [regionId, setRegionId] = useState("");
  const [municipalities, setMunicipalities] = useState<UserProfileMunicipality[]>([]);
  const [municipalityId, setMunicipalityId] = useState("");
  const [name, setName] = useState<string>("");
  const [organizations, setOrganizations] = useState<UserProfileOrganization[]>([]);

  const {tableState, tableMethods} = useTableStateManager(() => getInitialState());

  const fetchOrganizations = (params: GetOrganizationParams) => {
    getOrganizations(params).then(({data, total}) => {
      setOrganizations(data);

      tableMethods.setPaginationState({
        ...tableState.pagination,
        current: params.page! + 1,
        total,
      });
      setLoading(false);
    });
  };

  useEffect(() => {
    getRegions().then(res => {
      setRegions(res);
      setLoading(false);
    });
  }, []);

  const onRegionChange = (value?: string) => {
    setMunicipalities([]);
    setOrganizations([]);
    setMunicipalityId("");
    setLoading(true);
    setRegionId(value || "");
    tableMethods.setPaginationState({
      ...tableState.pagination,
      current: 1,
    });
    form.resetFields([personalDataLocationFormNames.municipality, personalDataLocationFormNames.organization]);
    if (value) {
      getMunicipalities(value).then(res => {
        setMunicipalities(res);
        setLoading(false);
      });
    }
    const sort = tableState.sorters.filter((col) => !!col.order).map((col) => `${col.order === "ascend" ? "+" : "-"}${col.dataIndex.join(".")}`);
    fetchOrganizations({
      municipalityId: undefined,
      regionId: value || undefined,
      name: name || undefined,
      page: 0,
      limit: 20,
      sort: sort.length ? sort[0] : undefined,
    });
  };

  const onMunicipalityChange = (value?: string) => {
    setMunicipalityId(value || "");
    tableMethods.setPaginationState({
      ...tableState.pagination,
      current: 1,
    });
    setOrganizations([]);
    setLoading(true);
    form.resetFields([personalDataLocationFormNames.organization]);
    const sort = tableState.sorters.filter((col) => !!col.order).map((col) => `${col.order === "ascend" ? "+" : "-"}${col.dataIndex.join(".")}`);
    fetchOrganizations({
      municipalityId: value || undefined,
      regionId: regionId || undefined,
      name: name || undefined,
      page: 0,
      limit: 20,
      sort: sort.length ? sort[0] : undefined,
    });
  };

  const fetchOrganizationsOnNameChange = useCallback(debounce(500, (query: string) => {
    setLoading(true);
    tableMethods.setPaginationState({
      ...tableState.pagination,
      current: 1,
    });
    const sort = tableState.sorters.filter((col) => !!col.order).map((col) => `${col.order === "ascend" ? "+" : "-"}${col.dataIndex.join(".")}`);
    fetchOrganizations({
      municipalityId: municipalityId || undefined,
      regionId: regionId || undefined,
      name: query || undefined,
      page: 0,
      limit: 20,
      sort: sort.length ? sort[0] : undefined,
    });
  }), [tableState, municipalityId, regionId]);

  const onNameChange = (value: string) => {
    setName(value);
    fetchOrganizationsOnNameChange(value);
  };

  const onTableChange: AppTableOnChange = (nextState) => {
    tableMethods.setTableState(nextState);
    const sort = nextState.sorters.filter((col) => !!col.order).map((col) => `${col.order === "ascend" ? "+" : "-"}${col.dataIndex.join(".")}`);

    fetchOrganizations({
      municipalityId: municipalityId || undefined,
      regionId: regionId || undefined,
      name: name || undefined,
      page: nextState.pagination.current! - 1,
      limit: 20,
      sort: sort.length ? sort[0] : undefined,
    });
  };

  return {
    locationLoading: loading,
    regionId,
    municipalityId,
    regionsOptions: toOptions(regions, "id", "name"),
    municipalitiesOptions: toOptions(municipalities, "id", "name"),
    organizationsOptions: toOptions(organizations, "id", "name"),
    organizations,
    municipalities,
    onRegionChange,
    onMunicipalityChange,
    setOrganizations,
    setMunicipalities,
    name,
    onTableChange,
    onNameChange,
    tableState,
  };
};