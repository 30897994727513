import {AppTableControls} from "common/TableControls/TableControls";
import {useUserProfileRole} from "common/use-user-profile-role";
import type {AppTableColumn} from "shared/UI/UIKit/Table";
import type {DictionaryItem} from "services/api";

export const getDictionaryTableColumns = (
  edit: (id: number) => void,
  remove: (id: number) => void
): AppTableColumn<DictionaryItem>[] => {
  const {isAdmin} = useUserProfileRole();

  const columns: AppTableColumn<DictionaryItem>[] = [
    {
      key: "id",
      title: "ID",
      dataIndex: ["id"],
      maxWidth: "10rem"
    },
    {
      key: "name",
      title: "Наименование",
      dataIndex: ["name"],
    }
  ];

  if (isAdmin) {
    columns.push({
      key: "controls",
      title: "",
      dataIndex: [],
      maxWidth: "10rem",
      render: (value: any, record: DictionaryItem) => {
        const editDictionaryItem = () => edit(record.id!);
        const removeDictionaryItem = () => remove(record.id!);

        return (
          <AppTableControls edit={editDictionaryItem} remove={removeDictionaryItem}/>
        );
      }
    });
  }

  return columns;
};