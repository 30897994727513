import type {UserProfileBody} from "services/api";
import type {SignUpFormValues} from "./types";

export const formValuesToUserProfileBody = (values: SignUpFormValues): UserProfileBody => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {repeatedPassword, password, ...formValues} = values;

  return {
    ...formValues,
    password: password.trim(),
    emailVerificationToken: "",
    passwordRecoveryToken: "",
    emailConfirmed: false,
    profileActivated: false,
    avatar: "",
  };
};