import cn from "classnames";
import {InlineMenuHelpers} from "shared/UI/UIKit/Menu";
import type {LinkMenuItemOptions} from "shared/UI/UIKit/Menu";
import {getIcon} from "./get-icon";
import styles from "./styles.module.scss";

type GetLinkItemProps = LinkMenuItemOptions & {
  isInner?: boolean;
};

export const getLinkItem = ({
  isInner,
  icon,
  wrapperClassName,
  className,
  ...rest
}: GetLinkItemProps) => {
  return InlineMenuHelpers.getLinkMenuItem({
    icon: getIcon(icon),
    wrapperClassName: cn(
      styles["item-wrapper"],
      styles["link-item__wrapper"],
      {[styles["link-item__wrapper_inner"]]: isInner},
      wrapperClassName,
    ),
    className: cn(
      styles["link-item__content"],
      {[styles["link-item__content_first-lvl"]]: !isInner},
      {[styles["link-item__content_second-lvl"]]: isInner},
      className,
    ),
    ...rest,
  });
};