import type {ActionReducerMapBuilder} from "@reduxjs/toolkit";
import type {DictionariesState} from "./types";
import {
  createDictionaryAction,
  editDictionaryAction,
  removeDictionaryAction,
  getDictionaryListAction,
} from "./actions";

export function createDictionaryReducerBuilder(builder: ActionReducerMapBuilder<DictionariesState>) {
  builder.addCase(createDictionaryAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(createDictionaryAction.fulfilled, (state, action) => {
    state.loading = false;
    state.dictionaries.push(action.payload);
  });
  builder.addCase(createDictionaryAction.rejected, (state) => {
    state.loading = false;
  });
}

export function editDictionaryReducerBuilder(builder: ActionReducerMapBuilder<DictionariesState>) {
  builder.addCase(editDictionaryAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(editDictionaryAction.fulfilled, (state, action) => {
    state.loading = false;
    state.dictionaries = state.dictionaries.map(dictionary => {
      return dictionary.id === action.payload.id ? action.payload : dictionary;
    });
  });
  builder.addCase(editDictionaryAction.rejected, (state) => {
    state.loading = false;
  });
}

export function getDictionaryListReducerBuilder(builder: ActionReducerMapBuilder<DictionariesState>) {
  builder.addCase(getDictionaryListAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getDictionaryListAction.fulfilled, (state, action) => {
    state.loading = false;
    state.dictionaries = action.payload.data;
    state.total = action.payload.total;
  });
  builder.addCase(getDictionaryListAction.rejected, (state) => {
    state.loading = false;
  });
}

export function removeDictionaryReducerBuilder(builder: ActionReducerMapBuilder<DictionariesState>) {
  builder.addCase(removeDictionaryAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(removeDictionaryAction.fulfilled, (state, action) => {
    state.loading = false;
    state.dictionaries = state.dictionaries.filter(dictionary => dictionary.id !== action.payload);
  });
  builder.addCase(removeDictionaryAction.rejected, (state) => {
    state.loading = false;
  });
}
