import {notification} from "antd";
import {appApi} from "services/api/api-instance";

export const verifyEmailToken = (token: string): Promise<string> => {
  return appApi
  .get(`/email_verification/verify/${token}`)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Не удалось подтвердить указанный е-мэйл адрес"});
      throw error;
    });
};

export const sendVerificationEmail = (token: string, showNotification = true): Promise<string> => {
  return appApi
  .post(`/email_verification/send/${token}`)
    .then(res => res.data)
    .catch(error => {
      showNotification && notification.error({message: "Не удалось отправить подтверждение на почту"});
      throw error;
    });
};
