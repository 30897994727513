import {AppCheckboxGroup} from "shared/UI/UIKit/Checkbox";
import {AppDropdown} from "shared/UI/UIKit/Dropdown";
import {AppIconButton} from "shared/UI/UIKit/Button";
import {AppSettingsIcon} from "shared/UI/UIKit/icons/Settings";
import type {AppTableSingleColumn} from "shared/UI/UIKit/Table";
import type {TableColumnsFilterProps} from "./types";
import styles from "./ColumnsFilter.module.scss";

export const TableColumnsFilter = (props: TableColumnsFilterProps) => {
  const options = props.columns
    .filter(column => (column as AppTableSingleColumn<any>).key !== "controls")
    .map(column => {
      return {
        label: (column as AppTableSingleColumn<any>).title as string,
        value: (column as AppTableSingleColumn<any>).key,
      };
  });

  const overlay = (
    <div className={styles.overlay}>
      <AppCheckboxGroup options={options} value={props.visibleKeys}
                        onChange={(value) => props.setVisibleKeys([...value, "controls"])}
                        direction="vertical"/>
    </div>
  );


  return (
    <AppDropdown overlay={overlay}>
      <AppIconButton type="primary">
        <AppSettingsIcon className={styles.icon}/>
      </AppIconButton>
    </AppDropdown>
  );
};
