import {Routes, Route} from "react-router-dom";
import {AppLayout} from "common/Layout";
import {AdminPageProtector, AuthorizedPageProtector, UnauthorizedPageProtector} from "common/Protectors";
import {homePageRoute} from "./home/config";
import {templatesPageRoute} from "./templates/config";
import {templateBlockPageRoute} from "./template-block/config";
import {notFoundPageRoute} from "./404/config";
import {logoutPageRoute} from "./logout/config";
import {workProgramsPageRoute} from "./work-programs/config";
import {workProgramBlockPageRoute} from "./work-program-block/config";
import {dictionariesPageRoute, dictionaryPageRoute} from "./dictionaries/config";
import {personalDataPageRoute} from "./personal-data/config";
import {signUpPageRoute} from "./sign-up/config";
import {authorizationPageRoute} from "./authorization/config";
import {emailConfirmPageRoute} from "./email-confirm/config";
import {emailApprovalPageRoute} from "./email-approval/config";
import {organizationsPageRoute} from "./organizations/config";
import {municipalitiesPageRoute} from "./municipalities/config";
import {regionsPageRoute} from "./regions/config";
import {usersPageRoute} from "./users/config";

export const AppRouting = () => {
  return (
    <Routes>
      <Route element={<AuthorizedPageProtector/>}>
        <Route element={<AppLayout/>}>
          <Route path={homePageRoute.path} element={homePageRoute.element}/>
          <Route path={workProgramsPageRoute.path} element={workProgramsPageRoute.element}/>
          <Route path={dictionariesPageRoute.path} element={dictionariesPageRoute.element}/>
          <Route path={dictionaryPageRoute.path} element={dictionaryPageRoute.element}/>
          <Route path={personalDataPageRoute.path} element={personalDataPageRoute.element}/>
          <Route element={<AdminPageProtector/>}>
            <Route path={templatesPageRoute.path} element={templatesPageRoute.element}/>
            <Route path={organizationsPageRoute.path} element={organizationsPageRoute.element}/>
            <Route path={municipalitiesPageRoute.path} element={municipalitiesPageRoute.element}/>
            <Route path={regionsPageRoute.path} element={regionsPageRoute.element}/>
            <Route path={usersPageRoute.path} element={usersPageRoute.element}/>
          </Route>
        </Route>
        <Route element={<AppLayout visibleSidebar={false} visibleHeader={false} visibleFooter={false}/>}>
          <Route path={templateBlockPageRoute.path} element={templateBlockPageRoute.element}/>
          <Route path={workProgramBlockPageRoute.path} element={workProgramBlockPageRoute.element}/>
        </Route>
        <Route element={<AppLayout visibleSidebar={false}/>}>
          <Route path={logoutPageRoute.path} element={logoutPageRoute.element}/>
        </Route>
      </Route>
      <Route element={<UnauthorizedPageProtector/>}>
        <Route element={<AppLayout visibleSidebar={false} visibleHeader={false} visibleWelcomeSidebar={true}/>}>
          <Route path={signUpPageRoute.path} element={signUpPageRoute.element}/>
          <Route path={authorizationPageRoute.path} element={authorizationPageRoute.element}/>
          <Route path={emailConfirmPageRoute.path} element={emailConfirmPageRoute.element}/>
          <Route path={emailApprovalPageRoute.path} element={emailApprovalPageRoute.element}/>
        </Route>
      </Route>
      <Route element={<AppLayout visibleSidebar={false}/>}>
        <Route path={notFoundPageRoute.path} element={notFoundPageRoute.element}/>
      </Route>
    </Routes>
  );
};
