import {useCallback, useEffect, useState} from "react";
import {getDictionaryListAction} from "features/dictionaries/redux/actions";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {getDataIndexedColumns, getDefaultTablePagination, useTableSearchParams} from "shared/UI/UIKit/Table";
import {useFilteredColumns} from "common/ColumnsFilter/use-filtered-columns";
import type {AppTableColumn, AppTableState} from "shared/UI/UIKit/Table";
import type {Dictionary} from "services/api";
import {clearState, setSelectedDictionary} from "../../redux/slice";
import {getDictionariesTableColumns} from "./columns";

const getInitialStateWithSorters = (
  columns: AppTableColumn<Dictionary>[]
): AppTableState => {
  return {
    sorters: getDataIndexedColumns(columns)
              .filter(({key}) => !["controls", "count"].includes(key))
              .map(({key, dataIndex}) => {
                return key === "name" ? {
                  key,
                  dataIndex,
                  order: "ascend",
                } : {
                  key,
                  dataIndex,
                };
              }),
    filters: [],
    pagination: getDefaultTablePagination(),
  };
};

export const useDictionaries = () => {
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [removeModalVisible, setRemoveModalVisible] = useState(false);
  const dispatch = useAppDispatch();
  const {dictionaries, total, loading} = useAppSelector(state => state.dictionariesState);
  const [siderVisible, setSiderVisible] = useState(false);

  const editDictionary = (id: number) => {
    const selectedDictionary = dictionaries.find(dictionary => dictionary.id === id);

    if (selectedDictionary) {
      dispatch(setSelectedDictionary(selectedDictionary));
      setEditModalVisible(true);
    }
  };

  const removeDictionary = (id: number) => {
    const selectedDictionary = dictionaries.find(dictionary => dictionary.id === id);

    if (selectedDictionary) {
      dispatch(setSelectedDictionary(selectedDictionary));
      setRemoveModalVisible(true);
    }
  };

  const columns = getDictionariesTableColumns(editDictionary, removeDictionary);
  const {filteredColumns, hiddenColumns, visibleKeys, setVisibleKeys} = useFilteredColumns(columns);

  const getTableItems = (nextTableState: AppTableState, nextFilterState: Record<string, any>) => {
    const {pagination: {current, pageSize}, sorters} = nextTableState;
    const sort = sorters.filter((col) => !!col.order).map((col) => `${col.order === "ascend" ? "+" : "-"}${col.dataIndex.join(".")}`);

    dispatch(getDictionaryListAction({
      page: current! - 1,
      limit: pageSize,
      sort,
      ...nextFilterState
    }));
  };

  const {tableState, onTableChange, filterState, filterCount, onFilterChange, onItemRemove} = useTableSearchParams(
    () => getInitialStateWithSorters(columns),
    getTableItems,
    dictionaries.length,
    total,
    hiddenColumns
  );

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  const createDictionary = () => {
    setCreateModalVisible(true);
  };

  const showSider = () => {
    setSiderVisible(true);
  };
  const hideSider = useCallback(() => {
    setSiderVisible(false);
  }, []);

  return {
    state: {
      loading,
      dictionaries,
      removeModalVisible,
      createModalVisible,
      editModalVisible,
      tableState,
      siderVisible,
      columns,
      filteredColumns,
      visibleKeys,
      filterState,
      filterCount,
    },
    methods: {
      setRemoveModalVisible,
      createDictionary,
      editDictionary,
      removeDictionary,
      setCreateModalVisible,
      setEditModalVisible,
      onTableChange,
      showSider,
      hideSider,
      setVisibleKeys,
      onFilterChange,
      onItemRemove,
    }
  };
};