import {useState} from "react";
import {AppModal} from "shared/UI/UIKit/Modal";
import {AppForm, AppFormItem, formRules, useAppForm} from "shared/UI/UIKit/Form";
import {AppInput} from "shared/UI/UIKit/Input";
import {AppButton} from "shared/UI/UIKit/Button";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {passwordRecovery} from "services/api";
import type {PasswordRecoveryModalProps,PasswordRecoveryFormValues} from "./types";

export const PasswordRecoveryModal = (props: PasswordRecoveryModalProps) => {
  const [form] = useAppForm<PasswordRecoveryFormValues>();
  const [loading, setLoading] = useState(false);

  const onHide = () => {
    form.resetFields();
    props.onHide();
  };

  const submit = () => {
    setLoading(true);
    form.validateFields().then(values => {
      passwordRecovery(values.email)
        .finally(() => {
          setLoading(false);
          onHide();
        });
    });
  };

  const content = (
    <AppForm form={form}>
      <AppFormItem required rules={formRules.requiredEmail}
                   label="Адрес электронной почты" name="email">
        <AppInput placeholder="Адрес электронной почты"/>
      </AppFormItem>
    </AppForm>
  );

  const footer = (
    <AppFlex direction="row">
      <AppButton onClick={submit}>
        Отправить
      </AppButton>
      <AppButton type="outline" onClick={onHide}>
        Отмена
      </AppButton>
    </AppFlex>
  );

  return (
    <AppModal content={content} footer={footer}
              visible={props.visible} onCancel={onHide}
              destroyOnClose loading={loading}
              title="Восстановление пароля"/>
  );
};