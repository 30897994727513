import type {AppMenuItem} from "../../../ui/Menu.types";
import type {SubMenuTitleItemProps} from "../../components/SubMenuTitleItem";
import {SubMenuTitleItem} from "../../components/SubMenuTitleItem";

export type SubMenuItemOptions = {
  key: SubMenuTitleItemProps["id"],
  label: SubMenuTitleItemProps["label"],
  className?: string,
  wrapperClassName?: string,
  icon?: SubMenuTitleItemProps["icon"],
  disabled?: SubMenuTitleItemProps["disabled"],
  onClick?: SubMenuTitleItemProps["onClick"],
  ellipsis?: SubMenuTitleItemProps["ellipsis"],
  children: AppMenuItem[],
}

export const getSubMenuItem = ({
  key,
  label,
  className,
  wrapperClassName,
  icon,
  disabled,
  onClick,
  ellipsis = {tooltip: label},
  children,
}: SubMenuItemOptions): AppMenuItem => {
  const getLabel = () => {
    return (
      <SubMenuTitleItem id={key}
                        className={className}
                        label={label}
                        icon={icon}
                        disabled={disabled}
                        onClick={onClick}
                        ellipsis={ellipsis}/>
    );
  };

  return {
    key,
    label: getLabel(),
    className: wrapperClassName,
    disabled,
    children,
  };
};