import {AppPage} from "common/Layout";
import {homeBreadcrumbItem} from "shared/UI/UIKit/Breadcrumbs";
import {getWorkProgramsUrl} from "services/navigation";
import {Templates} from "features/templates";

const breadcrumbs = [
  homeBreadcrumbItem,
  {
    title: "Рабочие программы",
    url: getWorkProgramsUrl()
  },
];

export const WorkProgramsPage = () => {
  return (
    <AppPage breadcrumbs={{
      items: breadcrumbs
    }} documentTitle="Рабочие программы">
      <Templates isPrograms/>
    </AppPage>
  );
};

