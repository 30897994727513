import {AppTable} from "shared/UI/UIKit/Table";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppButton} from "shared/UI/UIKit/Button";
import type {RegionsTableProps} from "./types";
import {getRegionsTableColumns} from "./columns";

export const RegionsTable = (props: RegionsTableProps) => {
  const columns = getRegionsTableColumns(props.edit, props.remove);

  return (
    <AppFlex>
      <AppFlex direction="row" justifyContent="flex-end">
        <AppButton onClick={props.create}>
          Создать регион
        </AppButton>
      </AppFlex>
      <AppTable dataSource={props.dataSource}
                columns={columns} loading={props.loading}
                rowKey={(record) => record.id}/>
    </AppFlex>

  );
};
