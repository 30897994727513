import {createSlice} from "@reduxjs/toolkit";
import type {PayloadAction} from "@reduxjs/toolkit";
import type {BlockingLoadingState} from "./types";
import {setBlockingLoadingAsyncBuilder} from "./reducers";

const initialState: BlockingLoadingState = {
  isBlockingLoading: false,
};

const blockingLoadingSlice = createSlice({
  name: "blockingLoading",
  initialState,
  reducers: {
    setBlockingLoading: (state, action: PayloadAction<boolean>) => {
      state.isBlockingLoading = action.payload;
    }
  },
  extraReducers: (builder) => {
    setBlockingLoadingAsyncBuilder(builder);
  }
});

export const {reducer, actions} = blockingLoadingSlice;