import {AppModal} from "shared/UI/UIKit/Modal";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {clearSelectedTemplate} from "features/templates/redux/slice";
import {removeProgramTemplateAction} from "features/templates/redux/actions";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppText} from "shared/UI/UIKit/Text";
import type {RemoveTemplateModalProps} from "../types";
import {TemplateModalFooter} from "../ModalFooter/ModalFooter";
import styles from "./Remove.module.scss";

export const RemoveTemplateModal = (props: RemoveTemplateModalProps) => {
  const {loading, selectedTemplate} = useAppSelector(state => state.templatesState);
  const dispatch = useAppDispatch();

  const confirm = () => {
    dispatch(removeProgramTemplateAction(selectedTemplate!.id.toString()))
      .unwrap()
      .then(() => {
        hideModal();
        props.onItemRemove();
      });
  };

  const hideModal = () => {
    props.hide();
    dispatch(clearSelectedTemplate());
  };

  const footer = <TemplateModalFooter onSubmit={confirm}
                                      hideModal={hideModal}
                                      title="Удалить"/>;
  const content = (
    <AppFlex unit="8" className={styles.content}>
      <AppText size={16} type="secondary">
        {
          props.isPrograms ? (
            "Подтвердите удаление рабочей программы:"
          ) : (
            "Подтвердите удаление шаблона:"
          )
        }
      </AppText>
      <AppText size={18}>
        {selectedTemplate?.name}
      </AppText>
    </AppFlex>
  );

  return (
    <AppModal visible={props.visible} onCancel={hideModal} size="auto"
              content={content} destroyOnClose footer={footer} loading={loading}
              title={props.isPrograms ? "Удаление рабочей программы" : "Удаление шаблона"}/>
  );
};