import {ReactKeycloakProvider} from "@react-keycloak/web";
import {AppLoader} from "shared/UI/UIKit/Loader";
import {LocaleStorageService} from "shared/helpers/local-storage";
import {LSKeys} from "shared/constants/local-storage-keys";
import type {AuthClientEvent,AuthClientTokens} from "@react-keycloak/core/lib/types";
import type {PropsWithChildren} from "shared/types";
import {keycloakInstance} from "./keycloak";

export const KeycloakProvider = (props: PropsWithChildren) => {
  const onTokens = (tokens: AuthClientTokens) => {
    if (tokens.token) {
      LocaleStorageService.setStringItem(LSKeys.edsooAccessToken, tokens.token);
    } else {
      LocaleStorageService.removeItem(LSKeys.edsooAccessToken);
    }
  };

  const onEvent = (eventType: AuthClientEvent) => {
    switch (eventType) {
      case "onAuthSuccess": {
        LocaleStorageService.removeItem(LSKeys.emailVerificationToken);
        break;
      }
      case "onAuthRefreshError": {
        LocaleStorageService.removeItem(LSKeys.edsooAccessToken);
        keycloakInstance.login();
        break;
      }
      case "onInitError":
      case "onAuthError":
      case "onAuthLogout": {
        LocaleStorageService.removeItem(LSKeys.edsooAccessToken);
        break;
      }
      default: {
        return;
      }
    }
  };

  return (
    <ReactKeycloakProvider authClient={keycloakInstance}
                           onTokens={onTokens}
                           autoRefreshToken
                           onEvent={onEvent}
                           LoadingComponent={<AppLoader size="large" stretched/>}
                           initOptions={{onLoad: "check-sso"}}>
      {props.children}
    </ReactKeycloakProvider>
  );
};