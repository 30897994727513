import {forwardRef} from "react";
import type {AppAsyncSelectProps} from "./AsyncSelect.types";
import type {AppSelectComponent} from "../Base/SelectBase.types";
import {AppSelectBase} from "../Base/SelectBase";
import {useAsyncSelect} from "./hooks/use-async-select";

export const AppAsyncSelect = forwardRef<AppSelectComponent, AppAsyncSelectProps>(
  (props, ref) => {
    const {fetchOptions, initialOption, delay, ...rest} = props;
    const {options, loading, onSearch} = useAsyncSelect(fetchOptions, delay, initialOption);

    return <AppSelectBase autoClearSearchValue={false} onSearch={onSearch} options={options}
                          filterOption={false} loading={loading} ref={ref} {...rest}/>;
  }
);