import {Form} from "antd";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {AppButton} from "shared/UI/UIKit/Button";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppCheckbox} from "shared/UI/UIKit/Checkbox";
import {AppForm, AppFormItem, formRules, valueEqualityValidator} from "shared/UI/UIKit/Form";
import {AppInput, AppInputPassword} from "shared/UI/UIKit/Input";
import {AppSelect} from "shared/UI/UIKit/Select";
import {AppTitle} from "shared/UI/UIKit/Title";
import {AppText} from "shared/UI/UIKit/Text";
import {AppGrid} from "shared/UI/UIKit/Grid/ui";
import {AppGridRow} from "shared/UI/UIKit/Grid/ui/components/Row/Row";
import {AppGridColumn} from "shared/UI/UIKit/Grid/ui/components/Column/Column";
import {useAuth} from "services/auth";
import {
  createUserProfile,
  sendVerificationEmail
} from "services/api";
import {getEmailConfirmUrl} from "services/navigation";
import {LocaleStorageService} from "shared/helpers/local-storage";
import {LSKeys} from "shared/constants/local-storage-keys";
import {AppBlockingLoader} from "shared/UI/UIKit/Loader";
import {usePersonalDataLocation} from "common/use-personal-data-location";
import {Captcha, useCaptcha} from "common/Captcha";
import {PasswordRecovery} from "common/PasswordRecovery";
import {passwordCreationRequirementsTooltipConfig} from "common/tooltips/PasswordCreationRequirements";
import type {UserProfile} from "services/api";
import type {SignUpFormValues} from "./types";
import {signUpFormNames} from "./constants";
import {formValuesToUserProfileBody} from "./utils";
import styles from "./SignUp.module.scss";

const repeatedPasswordRules = [
  ...formRules.required,
  valueEqualityValidator(signUpFormNames.password, "Пароли не совпадают"),
];

const EMAIL_CONFIRM_URL = getEmailConfirmUrl();

export const SignUp = () => {
  const [form] = Form.useForm<SignUpFormValues>();
  const [{captchaValue, captchaErrorMsg}, {onCaptchaValueChange, validateCaptcha}] = useCaptcha();
  const {login} = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    locationLoading,
    regionsOptions,
    onRegionChange,
    onMunicipalityChange,
    municipalitiesOptions,
    organizationsOptions,
    regionId,
    municipalityId,
  } = usePersonalDataLocation(form);

  const onSubmit = async () => {
    let profile: UserProfile | null = null;

    try {
      setLoading(true);
      const values = await form.validateFields();

      if (!validateCaptcha()) {
        return;
      }

      profile = await createUserProfile(formValuesToUserProfileBody(values));
    } catch {
      return;
    } finally {
      setLoading(false);
    }

    try {
      setLoading(true);
      LocaleStorageService.setStringItem(LSKeys.emailVerificationToken, profile!.emailVerificationToken);
      await sendVerificationEmail(profile!.emailVerificationToken, false);
    } catch {
      // do nothing
    } finally {
      setLoading(false);
      navigate(EMAIL_CONFIRM_URL);
    }
  };

  return (
    <>
      <AppFlex alignItems="center" direction="row" className={styles.login}>
        <PasswordRecovery/>
        <AppButton onClick={login}>Войти</AppButton>
      </AppFlex>
      <AppBlockingLoader loading={loading || locationLoading}>
        <AppFlex className={styles.container} unit="0" alignItems="center" justifyContent="center">
          <AppFlex className={styles["form-wrap"]} unit="30">
            <AppTitle>Создать учетную запись</AppTitle>
            <AppForm form={form}>
              <AppGrid>
                <AppGridRow>
                  <AppGridColumn unit="6" xs={6} sm={6} md={6} lg={6}>
                    <AppFormItem label="Адрес электронной почты" labelColor="gray" size="xxl"
                                 rules={formRules.requiredEmail} name={signUpFormNames.email}>
                      <AppInput placeholder="Введите Адрес электронной почты"/>
                    </AppFormItem>

                    <AppFormItem label="Пароль" labelColor="gray" size="xxl"
                                 tooltip={passwordCreationRequirementsTooltipConfig}
                                 rules={formRules.requiredPassword} name={signUpFormNames.password}>
                      <AppInputPassword autoComplete="new-password"
                                        placeholder="Введите Пароль"/>
                    </AppFormItem>

                    <AppFormItem label="Повторить пароль" labelColor="gray" size="xxl"
                                 rules={repeatedPasswordRules}
                                 name={signUpFormNames.repeatedPassword}>
                      <AppInputPassword autoComplete="new-password"
                                        placeholder="Введите Пароль"/>
                    </AppFormItem>

                    <AppFormItem label="Фамилия" labelColor="gray" size="xxl"
                                 rules={formRules.required} name={signUpFormNames.lastName}>
                      <AppInput placeholder="Введите Фамилия"/>
                    </AppFormItem>

                    <AppFormItem label="Имя" labelColor="gray" size="xxl"
                                 rules={formRules.required} name={signUpFormNames.firstName}>
                      <AppInput placeholder="Введите Имя"/>
                    </AppFormItem>
                  </AppGridColumn>
                  <AppGridColumn unit="6" xs={6} sm={6} md={6} lg={6}>
                    <AppFormItem label="Отчество" labelColor="gray" size="xxl"
                                 rules={formRules.required} name={signUpFormNames.middleName}>
                      <AppInput placeholder="Введите Отчество"/>
                    </AppFormItem>

                    <AppFormItem label="Регион" labelColor="gray" size="xxl"
                                 rules={formRules.required} name={signUpFormNames.region}>
                      <AppSelect options={regionsOptions}
                                 placeholder="Выберите регион" onChange={onRegionChange}/>
                    </AppFormItem>

                    <AppFormItem label="Район" labelColor="gray" size="xxl"
                                 rules={formRules.required} name={signUpFormNames.municipality}>
                      <AppSelect options={municipalitiesOptions}
                                 placeholder="Выберите Район" disabled={!regionId}
                                 onChange={onMunicipalityChange}/>
                    </AppFormItem>

                    <AppFormItem label="Организация" labelColor="gray" size="xxl"
                                 rules={formRules.required} name={signUpFormNames.organization}>
                      <AppSelect options={organizationsOptions}
                                 placeholder="Выберите Организация"
                                 disabled={!municipalityId}/>
                    </AppFormItem>

                    <AppFormItem rules={formRules.requiredBoolean} wrapperClassName={styles["agreement-wrapper"]}
                                 valuePropName="checked" name={signUpFormNames.processingOfPersonalData}
                                 className={styles.agreement}>
                      <AppCheckbox>
                        <AppText size={16}>
                          Согласие на обработку персональных данных
                        </AppText>
                      </AppCheckbox>
                    </AppFormItem>
                  </AppGridColumn>
                </AppGridRow>
              </AppGrid>
            </AppForm>

            <AppText type="secondary" size={16}>
              Постоянный пароль должен быть высокого уровня безопасности. Содержать заглавные и строчные буквы латинского алфавита, цифры и символы.
            </AppText>

            <Captcha value={captchaValue} onChange={onCaptchaValueChange} errorMsg={captchaErrorMsg}/>

            <AppButton onClick={onSubmit}>
              Зарегистрироваться
            </AppButton>
          </AppFlex>
        </AppFlex>
      </AppBlockingLoader>
    </>

  );
};
