import {useCallback, useEffect, useRef, useState} from "react";
import {throttle} from "throttle-debounce";
import type {AppSelectOption} from "../../";
import type {FetchOptionsFn} from "../AsyncSelect.types";

const THROTTLE_DELAY = 700;

export const useAsyncSelect = (
  fetchOptions: FetchOptionsFn,
  delay = THROTTLE_DELAY,
  initialOption?: AppSelectOption
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<AppSelectOption[]>([]);
  const fetchRef = useRef<number>(0);

  useEffect(() => {
    if (initialOption) {
      setOptions([initialOption]);
    }
  }, [initialOption]);

  const onSearch = useCallback(throttle(delay, (value: string) => {
    if (!value) {
      setOptions([]);
      setLoading(false);
      return;
    }
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    setOptions([]);
    setLoading(true);

    fetchOptions(value).then(newOptions => {
      if (fetchId !== fetchRef.current) {
        return;
      }

      setOptions(newOptions);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }), [fetchOptions, delay]);

  return {
    options,
    loading,
    onSearch,
  };
};