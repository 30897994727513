import {AppPage} from "common/Layout";
import {SignUp} from "features/sign-up";

export const SignUpPage = () => {
  return (
    <AppPage documentTitle="Создать учетную запись">
      <SignUp/>
    </AppPage>
  );
};
