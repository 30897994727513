import {AppTable} from "shared/UI/UIKit/Table";
import type {TemplatesTableProps} from "./types";
import {useTemplatesTable} from "./use-table";


export const TemplatesTable = (props: TemplatesTableProps) => {
  const {onRow} = useTemplatesTable(props);

  return (
    <AppTable dataSource={props.dataSource} onRow={onRow}
              columns={props.columns} onChange={props.onChange}
              rowKey={(record) => record.id}
              loading={props.loading} pagination={props.tableState.pagination}
              sorters={props.tableState.sorters}/>
  );
};