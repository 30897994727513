import type {UserProfile} from "services/api";
import type {ViewUserFormValues} from "./types";

export const getInitialFormValues = (user: UserProfile): ViewUserFormValues => {
  return {
    id: user.id,
    avatar: user.avatar,
    lastName: user.lastName,
    firstName: user.firstName,
    middleName: user.middleName,
    email: user.email,
    region: user.region,
    municipality: user.municipality,
    organization: user.organization,
    emailVerificationToken: user.emailVerificationToken,
    passwordRecoveryToken: user.passwordRecoveryToken,
    emailConfirmed: user.emailConfirmed,
  };
};