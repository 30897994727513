import {getNumericFieldComparator} from "shared/helpers/utils/comparators";
import type {ActionReducerMapBuilder} from "@reduxjs/toolkit";
import type {TemplateBlockState} from "./types";
import {
  setBlockAsync,
  setActiveBlockAsync,
  editProgramTemplateBlockContentAction,
  createProgramTemplateBlockAction,
  editProgramTemplateBlockAction,
  getProgramTemplateAction,
  removeProgramTemplateBlockAction,
  toggleStatusOfProgramTemplateAction,
  toggleVisibleTemplateBlockAction,
  editFewProgramTemplateBlocksAction,
} from "./actions";

export function setBlockAsyncReducerBuilder(builder: ActionReducerMapBuilder<TemplateBlockState>) {
  builder.addCase(setBlockAsync.fulfilled, (state, action) => {
    state.block = action.payload;
    state.notSaved = true;
  });
}

export function setActiveBlockAsyncReducerBuilder(builder: ActionReducerMapBuilder<TemplateBlockState>) {
  builder.addCase(setActiveBlockAsync.fulfilled, (state, action) => {
    state.block = action.payload;
    state.notSaved = false;
  });
}

export function editTemplateBlockContentReducerBuilder(builder: ActionReducerMapBuilder<TemplateBlockState>) {
  builder.addCase(editProgramTemplateBlockContentAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(editProgramTemplateBlockContentAction.fulfilled, (state, action) => {
    state.loading = false;
    state.notSaved = false;
    state.block = action.payload;
    state.template!.blocks = state.template!.blocks.map(block => {
      if (action.payload.id === block.id) {
        return action.payload;
      } else {
        return block;
      }
    });
  });
  builder.addCase(editProgramTemplateBlockContentAction.rejected, (state) => {
    state.loading = false;
  });
}

export function createProgramTemplateBlockReducerBuilder(builder: ActionReducerMapBuilder<TemplateBlockState>) {
  builder.addCase(createProgramTemplateBlockAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(createProgramTemplateBlockAction.fulfilled, (state, action) => {
    state.loading = false;
    state.notSaved = false;
    state.template!.blocks.push(action.payload);
    state.block = action.payload;
  });
  builder.addCase(createProgramTemplateBlockAction.rejected, (state) => {
    state.loading = false;
  });
}

export function getProgramTemplateReducerBuilder(builder: ActionReducerMapBuilder<TemplateBlockState>) {
  builder.addCase(getProgramTemplateAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getProgramTemplateAction.fulfilled, (state, action) => {
    state.loading = false;
    const sortedBlocks = [...action.payload.blocks].sort(getNumericFieldComparator(item => item.index, "ascend"));
    state.template = {
      ...action.payload,
      blocks: sortedBlocks,
    };
    state.block = state.template!.blocks.find(item => item.visible) || null;
  });
  builder.addCase(getProgramTemplateAction.rejected, (state) => {
    state.loading = false;
  });
}

export function toggleStatusOfProgramTemplateReducerBuilder(builder: ActionReducerMapBuilder<TemplateBlockState>) {
  builder.addCase(toggleStatusOfProgramTemplateAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(toggleStatusOfProgramTemplateAction.fulfilled, (state, action) => {
    state.loading = false;
    state.template = {
      ...action.payload,
      blocks: [...action.payload.blocks].sort(getNumericFieldComparator(item => item.index, "ascend"))
    };
  });
  builder.addCase(toggleStatusOfProgramTemplateAction.rejected, (state) => {
    state.loading = false;
  });
}

export function editTemplateBlockReducerBuilder(builder: ActionReducerMapBuilder<TemplateBlockState>) {
  builder.addCase(editProgramTemplateBlockAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(editProgramTemplateBlockAction.fulfilled, (state, action) => {
    state.loading = false;
    state.notSaved = false;
    if (state.template!.blocks.find(block => block.id === action.payload.id)) {
      state.template!.blocks = state.template!.blocks.map(block => {
        return block.id === action.payload.id ? action.payload : block;
      });
    } else {
      state.template!.blocks.push(action.payload);
    }

    state.block = action.payload;
  });
  builder.addCase(editProgramTemplateBlockAction.rejected, (state) => {
    state.loading = false;
  });
}

export function editFewTemplateBlocksReducerBuilder(builder: ActionReducerMapBuilder<TemplateBlockState>) {
  builder.addCase(editFewProgramTemplateBlocksAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(editFewProgramTemplateBlocksAction.fulfilled, (state, action) => {
    state.loading = false;
    state.notSaved = false;
    state.template!.blocks = state.template!.blocks.map(block => {
      return action.payload.find((_block) => _block.id === block.id) || block;
    });

    state.block = action.payload[0];
  });
  builder.addCase(editFewProgramTemplateBlocksAction.rejected, (state) => {
    state.loading = false;
  });
}

export function toggleVisibleOfTemplateBlockAction(builder: ActionReducerMapBuilder<TemplateBlockState>) {
  builder.addCase(toggleVisibleTemplateBlockAction.fulfilled, (state, action) => {
    state.template!.blocks = state.template!.blocks.map(block => {
      return block.id === action.payload.id ? action.payload : block;
    });

    state.block = state.template!.blocks.find(item => item.visible) || null;
  });
}

export function removeTemplateBlockReducerBuilder(builder: ActionReducerMapBuilder<TemplateBlockState>) {
  builder.addCase(removeProgramTemplateBlockAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(removeProgramTemplateBlockAction.fulfilled, (state, action) => {
    state.loading = false;
    state.notSaved = false;
    const nextBlocks = state.template!.blocks.filter(block => block.id !== action.payload);
    state.template!.blocks = nextBlocks;
    state.block = nextBlocks[0] || null;
  });
  builder.addCase(removeProgramTemplateBlockAction.rejected, (state) => {
    state.loading = false;
  });
}
