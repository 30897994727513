import {createAsyncThunk} from "@reduxjs/toolkit";
import {getCurrentUser, updateUserProfile} from "services/api";
import type {UserProfileBody} from "services/api";
import type {RootState} from "services/redux/types";

export const getProfileAction = createAsyncThunk("profile/get", async () => {
  try {
    return await getCurrentUser();
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const updateProfileAction = createAsyncThunk(
  "profile/update",
  async (nextProfileBody: UserProfileBody, {getState}) => {
    try {
      const {profileState: {profile}} = getState() as RootState;
      return await updateUserProfile(profile!.id, nextProfileBody);
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);