import {useEffect, useState} from "react";
import {AppModal} from "shared/UI/UIKit/Modal";
import {useAppForm} from "shared/UI/UIKit/Form";
import {updateRegion} from "services/api";
import type {EditRegionModalProps} from "./types";
import {RegionModalFooter} from "../ModalFooter";
import {RegionForm} from "../../Form/Region";

export const EditRegionModal = (props: EditRegionModalProps) => {
  const [form] = useAppForm();
  const [loading, setLoading] = useState(false);
  const selectedRegion = props.regions.find(item => item.id === props.id)!;

  useEffect(() => {
    if (props.visible) {
      form.setFieldsValue({...selectedRegion});
    }
  }, [props.visible]);

  const hideModal = () => {
    form.resetFields();
    props.hide();
  };

  const onSubmit = () => {
    form.validateFields().then(values => {
      setLoading(true);

      updateRegion({
        ...selectedRegion,
        ...values,
      }).then((res) => {
        props.setItem(res);
        hideModal();
      }).finally(() => {
        setLoading(false);
      });
    });
  };

  const footer = <RegionModalFooter hideModal={hideModal}
                                    onSubmit={onSubmit}
                                    title="Сохранить"/>;
  const content = <RegionForm form={form}/>;

  return (
    <AppModal visible={props.visible} onCancel={hideModal}
              content={content} destroyOnClose footer={footer}
              loading={loading} title="Редактировать регион"/>
  );
};