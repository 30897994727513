import {useCallback, useState} from "react";
import {getDefaultTablePagination, useTableSearchParams} from "shared/UI/UIKit/Table";
import {getUsers} from "services/api";
import {usePayloadedModal} from "shared/UI/UIKit/Modal";
import type {UserProfile} from "services/api";
import type {AppTableState} from "shared/UI/UIKit/Table";

const initialTableState: AppTableState = {
  sorters: [],
  filters: [],
  pagination: getDefaultTablePagination(),
};

export const useUsers = () => {
  const [siderVisible, setSiderVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<UserProfile[]>([]);
  const [total, setTotal] = useState<number>(0);
  const {state: viewModalState, methods: viewModalMethods} = usePayloadedModal<{id: number}>();
  const {state: removeModalState, methods: removeModalMethods} = usePayloadedModal<{user: UserProfile}>();

  const getTableItems = (nextTableState: AppTableState, nextFilterState: Record<string, any>) => {
    const {pagination: {pageSize, current}} = nextTableState;
    setLoading(true);
    getUsers({
      page: current! - 1,
      limit: pageSize,
      ...nextFilterState,
    })
      .then(res => {
        setUsers(res.data);
        setTotal(res.total);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const {filterState, filterCount, onTableChange, onFilterChange, onItemRemove, tableState} = useTableSearchParams(
    initialTableState,
    getTableItems,
    users.length,
    total,
  );

  const showSider = () => {
    setSiderVisible(true);
  };
  const hideSider = useCallback(() => {
    setSiderVisible(false);
  }, []);

  const onUserView = (userId: number) => {
    viewModalMethods.show({id: userId});
  };

  const showRemoveModal = (user: UserProfile) => {
    removeModalMethods.show({user});
  };

  return {
    state: {
      loading,
      users,
      siderVisible,
      filterState,
      filterCount,
      tableState,
      viewModalState,
      removeModalState,
    },
    methods: {
      showSider,
      hideSider,
      onFilterChange,
      onTableChange,
      onUserView,
      showRemoveModal,
      onItemRemove,
      viewModalMethods,
      removeModalMethods,
    }
  };
};