import {useCallback, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {getProgramTemplatesAction} from "features/templates/redux/actions";
import {
  getDataIndexedColumns,
  getDefaultTablePagination,
  useTableSearchParams
} from "shared/UI/UIKit/Table";
import {useFilteredColumns} from "common/ColumnsFilter/use-filtered-columns";
import {getSubjectsList} from "services/api";
import {useUserProfileRole} from "common/use-user-profile-role";
import type {TableFilterField} from "common/TableFilter";
import type {AppSelectOption} from "shared/UI/UIKit/Select";
import type {AppTableState, AppTableColumn} from "shared/UI/UIKit/Table";
import type {ProgramTemplate} from "services/api";
import type {TemplatesProps} from "./types";
import {clearState, setSelectedTemplate} from "../../redux/slice";
import {getTemplatesTableColumns, getWorkProgramsTableColumns} from "./columns";
import {templatesFilterFields, workProgramsFilterFields} from "./filter-fields";

const getInitialStateWithSorters = (
  columns: AppTableColumn<ProgramTemplate>[]
): AppTableState => {
  return {
    sorters: getDataIndexedColumns(columns)
              .filter(({key}) => key !== "controls")
              .map(({key, dataIndex}) => {
                return key === "id" ? {
                  key,
                  dataIndex,
                  order: "descend",
                } : {
                  key,
                  dataIndex,
                };
              }),
    filters: [],
    pagination: getDefaultTablePagination(),
  };
};

export const useTemplates = (props: TemplatesProps) => {
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [createWorkProgramModalVisible, setCreateWorkProgramModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [removeModalVisible, setRemoveModalVisible] = useState(false);
  const {templates, total, loading} = useAppSelector(state => state.templatesState);
  const [siderVisible, setSiderVisible] = useState(false);
  const [filterFields, setFilterFields] = useState<TableFilterField[]>(props.isPrograms ? workProgramsFilterFields : templatesFilterFields);
  const {isUser} = useUserProfileRole();
  const [searchParams] = useSearchParams();
  const subjectIdFromMainProgram = searchParams.get("subjectId");

  useEffect(() => {
    if (subjectIdFromMainProgram) {
      setCreateWorkProgramModalVisible(true);
    }
  }, []);

  const editTemplate = (id: number) => {
    const selectedTemplate = templates.find(template => template.id === id);

    if (selectedTemplate) {
      dispatch(setSelectedTemplate(selectedTemplate));
      setEditModalVisible(true);
    }
  };

  const removeTemplate = (id: number) => {
    const selectedTemplate = templates.find(template => template.id === id);

    if (selectedTemplate) {
      dispatch(setSelectedTemplate(selectedTemplate));
      setRemoveModalVisible(true);
    }
  };

  const columns = props.isPrograms ?
    getWorkProgramsTableColumns(editTemplate, removeTemplate) :
    getTemplatesTableColumns(editTemplate, removeTemplate);
  const {filteredColumns, hiddenColumns, visibleKeys, setVisibleKeys} = useFilteredColumns(columns);

  const dispatch = useAppDispatch();

  const showSider = () => {
    setSiderVisible(true);
  };
  const hideSider = useCallback(() => {
    setSiderVisible(false);
  }, []);

  const getTableItems = (nextTableState: AppTableState, nextFilterState: Record<string, any>) => {
    const {pagination: {current, pageSize}, sorters} = nextTableState;
    const sort = sorters.filter((col) => !!col.order).map((col) => `${col.order === "ascend" ? "+" : "-"}${col.dataIndex.join(".")}`);

    dispatch(getProgramTemplatesAction({
      isProgram: !!props.isPrograms,
      page: current! - 1,
      limit: pageSize,
      sort,
      ...nextFilterState
    }));
  };

  const {tableState, onTableChange, filterState, filterCount, onFilterChange, onItemRemove} = useTableSearchParams(
    () => getInitialStateWithSorters(columns),
    getTableItems,
    templates.length,
    total,
    hiddenColumns
  );

  const onSubmit = () => {
    console.log("siderSubmit");
  };
  const onCancel = () => {
    console.log("siderCancel");
  };

  useEffect(() => {
    return () => {
      if (!isUser) {
        dispatch(clearState());
      }
    };
  }, []);

  const createTemplate = () => {
    setCreateModalVisible(true);
  };

  const createProgram = () => {
    setCreateWorkProgramModalVisible(true);
  };

  const copyTemplate = () => {
    setCreateWorkProgramModalVisible(true);
  };

  const getSubjectOptions = () => {
    return getSubjectsList().then(subjects => {
      return subjects.map((subject) => ({label: subject.name, value: subject.id}));
    });
  };

  const onLoadFilterOptions = async (fieldName: string) => {
    const filterFieldsCopy = [...filterFields];
    const loadingIndex = filterFieldsCopy.findIndex((field) => field.name === fieldName);
    if (loadingIndex < 0) {
      return;
    }
    filterFieldsCopy[loadingIndex].loading = true;
    setFilterFields([...filterFieldsCopy]);

    let options: AppSelectOption[] = [];
    switch (fieldName) {
      case "subjectId":
        options = await getSubjectOptions();
        break;

      default:
        return;
    }
    filterFieldsCopy[loadingIndex].loading = false;
    filterFieldsCopy[loadingIndex].options = options;
    setFilterFields([...filterFieldsCopy]);
  };

  return {
    state: {
      createModalVisible,
      removeModalVisible,
      createWorkProgramModalVisible,
      editModalVisible,
      templates,
      loading,
      siderVisible,
      filterState,
      filterCount,
      tableState,
      filterFields,
      columns,
      filteredColumns,
      hiddenColumns,
      visibleKeys,
    },
    methods: {
      createTemplate,
      createProgram,
      setCreateModalVisible,
      setRemoveModalVisible,
      setCreateWorkProgramModalVisible,
      setEditModalVisible,
      showSider,
      hideSider,
      onFilterChange,
      onLoadFilterOptions,
      onTableChange,
      onItemRemove,
      setVisibleKeys,
      onSubmit,
      onCancel,
      editTemplate,
      removeTemplate,
      copyTemplate
    }
  };
};