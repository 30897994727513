import {AppTableControls} from "common/TableControls/TableControls";
import type {UserProfile} from "services/api";
import type {AppTableColumn} from "shared/UI/UIKit/Table";

export const getUsersTableColumns = (
  view: (userId: number) => void,
  remove: (user: UserProfile) => void,
): AppTableColumn<UserProfile>[] => {
  return [
    {
      key: "id",
      title: "ID",
      dataIndex: ["id"],
      maxWidth: "10rem"
    },
    {
      key: "lastName",
      title: "Фамилия",
      dataIndex: ["lastName"],
    },
    {
      key: "firstName",
      title: "Имя",
      dataIndex: ["firstName"],
    },
    {
      key: "middleName",
      title: "Отчество",
      dataIndex: ["middleName"],
    },
    {
      key: "email",
      title: "Адрес электронной почты",
      dataIndex: ["email"],
    },
    // {
    //   key: "region",
    //   title: "Регион",
    //   dataIndex: ["region", "name"],
    // },
    // {
    //   key: "municipality",
    //   title: "Район",
    //   dataIndex: ["municipality", "name"],
    // },
    // {
    //   key: "organization",
    //   title: "Организация",
    //   dataIndex: ["organization", "name"],
    // },
    // {
    //   key: "emailVerificationToken",
    //   title: "Токен подтверждения электронной почты",
    //   maxWidth: "14rem",
    //   minWidth: "14rem",
    //   dataIndex: ["emailVerificationToken"],
    // },
    // {
    //   key: "passwordRecoveryToken",
    //   title: "Токен восстановления пароля",
    //   maxWidth: "12rem",
    //   minWidth: "12rem",
    //   dataIndex: ["passwordRecoveryToken"],
    // },
    {
      key: "emailConfirmed",
      title: "Подтверждение электронной почты",
      maxWidth: "12rem",
      minWidth: "12rem",
      dataIndex: ["emailConfirmed"],
      render: (value: any, record: UserProfile) => {
        return (
          <span>{record.emailConfirmed ? "Есть" : "Нет"}</span>
        );
      }
    },
    {
      key: "controls",
      title: "",
      dataIndex: [],
      maxWidth: "10rem",
      render: (value: any, record: UserProfile) => {
        const viewUserCard = () => view(record.id);
        const removeUser = () => remove(record);

        return (
          <AppTableControls edit={viewUserCard} remove={removeUser}/>
        );
      }
    }
  ];
};