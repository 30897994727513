import {Form} from "antd";
import {useLayoutEffect} from "react";
import cn from "classnames";
import {AppForm} from "shared/UI/UIKit/Form";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppButton} from "shared/UI/UIKit/Button";
import {AppModalHeader, AppModalTitle, AppModalContent, AppModalFooter} from "shared/UI/UIKit/Modal";
import {AppText} from "shared/UI/UIKit/Text";
import {AppDescription} from "shared/UI/UIKit/Description";
import {useForceRender} from "shared/helpers/hooks/use-force-render";
import {Avatar} from "common/Avatar/Avatar";
import type {ViewUserFormValues, ViewUserFormProps} from "./types";
import {formNames} from "./constants";
import {getInitialFormValues} from "./utils";
import styles from "./styles.module.scss";

export const ViewUserForm = (props: ViewUserFormProps) => {
  const [form] = Form.useForm<ViewUserFormValues>();
  const [, forceRerender] = useForceRender();

  useLayoutEffect(() => {
    if (props.user) {
      form.setFieldsValue(getInitialFormValues(props.user));
      forceRerender();
    }
  }, [props.user]);

  return (
    <>
      <AppModalHeader>
        <AppModalTitle>
          {`Информация о пользователе (ID: ${form.getFieldValue(formNames.id)})`}
        </AppModalTitle>
      </AppModalHeader>
      <AppModalContent>
        <AppForm form={form}>
          <AppFlex direction="row" className={styles["main-part"]} unit="0">
            <Avatar avatarUrl={form.getFieldValue(formNames.avatar)}
                    imgClassName={styles["main-part__avatar"]} emptyClassName={styles["main-part__avatar"]}/>
            <div className={styles["main-part__info"]}>
              <AppDescription title="Фамилия" unit="2" className={styles.description}>
                <AppText>{form.getFieldValue(formNames.lastName) || "-"}</AppText>
              </AppDescription>
              <AppDescription title="Имя" unit="2" className={styles.description}>
                <AppText>{form.getFieldValue(formNames.firstName) || "-"}</AppText>
              </AppDescription>
              <AppDescription title="Отчество" unit="2" className={styles.description}>
                <AppText>{form.getFieldValue(formNames.middleName) || "-"}</AppText>
              </AppDescription>
              <AppDescription title="Адрес электронной почты" unit="2" className={styles.description}>
                <AppText>{form.getFieldValue(formNames.email) || "-"}</AppText>
              </AppDescription>
            </div>
          </AppFlex>
          <AppDescription title="Регион" unit="2" className={styles.description}>
            <AppText>{form.getFieldValue(formNames.region) || "-"}</AppText>
          </AppDescription>
          <AppDescription title="Район" unit="2" className={styles.description}>
            <AppText>{form.getFieldValue(formNames.municipality) || "-"}</AppText>
          </AppDescription>
          <AppDescription title="Организация" unit="2" className={styles.description}>
            <AppText>{form.getFieldValue(formNames.organization) || "-"}</AppText>
          </AppDescription>
          <AppDescription title="Токен подтверждения электронной почты" unit="2" className={styles.description}>
            <AppText>{form.getFieldValue(formNames.emailVerificationToken) || "-"}</AppText>
          </AppDescription>
          <AppDescription title="Токен восстановления пароля" unit="2" className={styles.description}>
            <AppText>{form.getFieldValue(formNames.passwordRecoveryToken) || "-"}</AppText>
          </AppDescription>
          <AppDescription title="Подтверждение электронной почты" unit="2" className={styles.description}>
            <AppText>{form.getFieldValue(formNames.emailConfirmed) ? "Есть" : "Нет"}</AppText>
          </AppDescription>
        </AppForm>
      </AppModalContent>
      <AppModalFooter>
        <AppFlex direction="row">
          <AppButton type="primary" onClick={props.onEmailVerify}
                     loading={props.emailVerificationBtnLoading}
                     className={cn(props.emailVerificationBtnLoading && styles["btn_loading"])}>
            Отправить письмо для активации
          </AppButton>
          <AppButton type="primary" onClick={props.onPasswordRecover}
                     loading={props.passwordRecoveryBtnLoading}
                     className={cn(props.passwordRecoveryBtnLoading && styles["btn_loading"])}>
            Отправить письмо для восстановления пароля
          </AppButton>
          <AppButton type="outline" onClick={props.onCancel}>
            Закрыть
          </AppButton>
        </AppFlex>
      </AppModalFooter>
    </>
  );
};