import {AppForm, AppFormItem, formRules} from "shared/UI/UIKit/Form";
import {AppInput} from "shared/UI/UIKit/Input";
import {AppSelect} from "shared/UI/UIKit/Select";
import {toOptions} from "shared/helpers/utils/to-options";
import {AppCheckbox} from "shared/UI/UIKit/Checkbox";
import type {TemplateFormProps} from "./types";

export const TemplateForm = (props: TemplateFormProps) => {
  const subjectOptions = toOptions(props.subjects, "id", "name");

  return (
    <AppForm form={props.form}>
      <AppFormItem rules={formRules.required} label="Название"
                   name={props.isPrograms ? "programName" : "name"}>
        <AppInput placeholder="Введите название"/>
      </AppFormItem>
      {
        !props.isPrograms ? (
          <>
            <AppFormItem rules={formRules.required} label="Уровень образования" name="educationLevel">
              <AppSelect options={props.educationLevels} placeholder="Выберите уровень образования"/>
            </AppFormItem>
            <AppFormItem rules={formRules.required} label="Учебный предмет" name="subjectId">
              <AppSelect options={subjectOptions} placeholder="Выберите учебный предмет"/>
            </AppFormItem>
          </>
        ) : (
          <AppFormItem valuePropName="checked"
                       name="showInMainPrograms">
            <AppCheckbox>Доступно для основной образовательной программы</AppCheckbox>
          </AppFormItem>
        )
      }
    </AppForm>
  );
};
