import type {AppIconProps} from "../Icons.types";
import {getClassNames} from "../Icons.utils";
import styles from "./Dictionary.module.scss";

export const AppDictionaryIcon = (props: AppIconProps) => {
  const {className, ...rest} = props;
  const classNames = getClassNames(styles.icon, className);

  return (
    <svg {...rest} className={classNames} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 20" fill="none">
      <mask id="path-1-inside-1_255_275" fill="white">
        <path d="M0 4C0 1.79086 1.79086 0 4 0H15C15.5523 0 16 0.447715 16 1V20H4C1.79086 20 0 18.2091 0 16V4Z"/>
      </mask>
      <path d="M0 4C0 1.79086 1.79086 0 4 0H15C15.5523 0 16 0.447715 16 1V20H4C1.79086 20 0 18.2091 0 16V4Z" stroke="#737781" strokeWidth="4" mask="url(#path-1-inside-1_255_275)"/>
      <path d="M3 18C3 16.8954 3.89543 16 5 16H16V20H5C3.89543 20 3 19.1046 3 18Z" fill="#737781"/>
    </svg>
  );
};