import cn from "classnames";
import type {GridColumnProps} from "./Column.props";
import styles from "./Column.module.scss";

export const getClassNames = ({xs = 12, sm = 12, md = 12, lg = 12}: GridColumnProps) => {
  return cn(
    styles.col,
    styles[`lg-${lg}`],
    styles[`md-${md}`],
    styles[`sm-${sm}`],
    styles[`xs-${xs}`],
  );
};