import {useState} from "react";
import type {CheckboxValueType} from "antd/lib/checkbox/Group";
import type {AppTableColumns, AppTableSingleColumn} from "shared/UI/UIKit/Table";
import type {ProgramTemplate} from "services/api";

export const useFilteredColumns = (columns: AppTableColumns<any>) => {
  const [visibleKeys, setVisibleKeys] = useState<CheckboxValueType[]>(
    () => columns.map(column => (column as AppTableSingleColumn<any>).key)
  );

  const filteredColumns = columns.filter(column => visibleKeys.includes((column as AppTableSingleColumn<ProgramTemplate>).key));
  const hiddenColumns = columns.filter(column => !visibleKeys.includes((column as AppTableSingleColumn<ProgramTemplate>).key));

  return {
    visibleKeys,
    setVisibleKeys,
    filteredColumns,
    hiddenColumns
  };
};