import {useEffect, useState} from "react";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {useModal, usePayloadedModal} from "shared/UI/UIKit/Modal";
import {getRegions} from "services/api";
import type {UserProfileRegion} from "services/api";
import {RegionsTable} from "./Table/RegionsTable";
import {RemoveRegionModal} from "./Modals/Remove";
import {EditRegionModal} from "./Modals/Edit";
import {CreateRegionModal} from "./Modals/Create";

type ModalPayload = {
  id: number;
}

export const Regions = () => {
  const createModal = useModal();
  const editModal = usePayloadedModal<ModalPayload>();
  const removeModal = usePayloadedModal<ModalPayload>();
  const [loading, setLoading] = useState(false);
  const [regions, setRegions] = useState<UserProfileRegion[]>([]);

  useEffect(() => {
    setLoading(true);
    getRegions()
      .then(res => {
        setRegions(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const addItem = (newItem: UserProfileRegion) => {
    setRegions([newItem, ...regions]);
  };
  const removeItem = (id: number) => {
    setRegions(regions.filter(item => item.id !== id));
  };
  const setItem = (newItem: UserProfileRegion) => {
    setRegions(regions.map(item => item.id === newItem.id ? newItem : item));
  };

  const showEditModal = (id: number) => editModal.methods.show({id});
  const showCreateModal = () => createModal.methods.show();
  const showRemoveModal = (id: number) => removeModal.methods.show({id});

  return (
    <AppFlex>
      <RegionsTable dataSource={regions}
                    edit={showEditModal}
                    remove={showRemoveModal}
                    create={showCreateModal}
                    loading={loading}/>
      <CreateRegionModal visible={createModal.state.visible}
                          hide={createModal.methods.hide}
                          addItem={addItem}/>
      <RemoveRegionModal visible={removeModal.state.visible}
                          hide={removeModal.methods.hide}
                          id={removeModal.state.payload?.id}
                          onItemRemove={removeItem}
                          regions={regions}/>
      <EditRegionModal visible={editModal.state.visible}
                        hide={editModal.methods.hide}
                        regions={regions}
                        id={editModal.state.payload?.id}
                        setItem={setItem}/>

    </AppFlex>
  );
};