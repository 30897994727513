import type {ProgramTemplateBlock, Subject} from "services/api";
import type {ListResponse, PaginationAndSort} from "../types";

export type ProgramTemplate = {
  id: number;
  name: string;
  isProgram: boolean;
  programName: string;
  showInMainPrograms?: boolean;
  educationLevel: ProgramTemplateEducationLevel;
  generationInProgress: boolean;
  status: ProgramTemplateStatus;
  blocks: ProgramTemplateBlock[];
  subject: Subject | null;
  createdBy?: string;
  shareToken?: string;
}

export type ProgramTemplateBody = {
  name: string;
  programName: string;
  isProgram: boolean;
  educationLevel: ProgramTemplateEducationLevel;
  status: ProgramTemplateStatus;
  blocks: ProgramTemplateBlock[]; // needs for backend
  subject: Subject | null;
  createdBy?: string;
  shareToken?: string;
  showInMainPrograms?: boolean;
}

export enum ProgramTemplateStatus {
  DONE = "DONE",
  IN_PROGRESS = "IN_PROGRESS",
}

export enum ProgramTemplateEducationLevel {
  НОО = "НОО",
  ООО = "ООО",
  СОО = "СОО",
}

export type ProgramTemplatesRequest = PaginationAndSort & {
  isProgram: boolean;
  sendBlocks?: boolean;
  name?: string;
  programName?: string;
  status?: ProgramTemplateStatus;
  educationLevel?: ProgramTemplateEducationLevel;
  subjectId?: string;
}

export type ProgramTemplatesResponse = ListResponse<ProgramTemplate>;