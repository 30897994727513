import ImgCrop from "antd-img-crop";
import {AppButton} from "shared/UI/UIKit/Button";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {fileToBase64} from "shared/helpers/utils/files/file-to-base64";
import {Avatar} from "common/Avatar/Avatar";
import {AppFileUploader} from "shared/UI/UIKit/Uploader";
import type {RcFile} from "antd/lib/upload";
import type {PersonalDataAvatarProps} from "./types";
import styles from "./PersonalDataAvatar.module.scss";

export const PersonalDataAvatar = (props: PersonalDataAvatarProps) => {
  const beforeUpload = async (file: RcFile) => {
    const base64 = await fileToBase64(file);
    if (typeof base64 === "string") {
      props.onChange?.(base64);
    }
    return false;
  };

  return (
    <AppFlex direction="column" unit="30">
      <Avatar avatarUrl={props.value} imgClassName={styles["avatar-img"]} emptyClassName={styles["avatar-empty"]}/>
      <ImgCrop rotate modalTitle="Редактировать фото" modalCancel="Отмена">
        <AppFileUploader beforeUpload={beforeUpload} showUploadList={false} className={styles.upload}>
          <AppButton type="outline" className={styles.button}>Изменить фото</AppButton>
        </AppFileUploader>
      </ImgCrop>
    </AppFlex>
  );
};
