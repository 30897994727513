import {generateUUID} from "shared/helpers/utils/generate-uuid";
import {TableFilterFieldType} from "common/TableFilter";
import type {TableFilterField} from "common/TableFilter";

export const usersFilterFields: TableFilterField[] = [
  {
    key: generateUUID(),
    name: "email",
    label: "Адрес электронной почты",
    type: TableFilterFieldType.INPUT,
  },
];