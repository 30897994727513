import {AppButton} from "shared/UI/UIKit/Button";
import {AppFilterButtonIcon} from "shared/UI/UIKit/icons/FilterButton";
import type {FilterButtonProps} from "./types";
import styles from "./FilterButton.module.scss";

export const FilterButton = (props: FilterButtonProps) => {
  return (
    <AppButton type="outline" className={styles.button} onClick={props.click}>
      <AppFilterButtonIcon className={styles.icon}/>
      Фильтры
      {!!props.count && <div className={styles.count}>{props.count}</div>}
    </AppButton>
  );
};