import {useEffect, useState} from "react";
import {AppModal} from "shared/UI/UIKit/Modal";
import {useAppForm} from "shared/UI/UIKit/Form";
import {updateMunicipality} from "services/api";
import type {EditMunicipalityModalProps} from "./types";
import {MunicipalityModalFooter} from "../ModalFooter";
import {MunicipalityForm} from "../../Form/Municipality";

export const EditMunicipalityModal = (props: EditMunicipalityModalProps) => {
  const [form] = useAppForm();
  const [loading, setLoading] = useState(false);
  const selectedMunicipality = props.municipalities.find(item => item.id === props.id)!;

  useEffect(() => {
    if (props.visible) {
      form.setFieldsValue({...selectedMunicipality});
    }
  }, [props.visible]);

  const hideModal = () => {
    form.resetFields();
    props.hide();
  };

  const onSubmit = () => {
    form.validateFields().then(values => {
      setLoading(true);

      updateMunicipality({
        ...selectedMunicipality,
        ...values,
      }).then((res) => {
        props.setItem(res);
        hideModal();
      }).finally(() => {
        setLoading(false);
      });
    });
  };

  const footer = <MunicipalityModalFooter hideModal={hideModal}
                                          onSubmit={onSubmit}
                                          title="Сохранить"/>;
  const content = <MunicipalityForm form={form}/>;

  return (
    <AppModal visible={props.visible} onCancel={hideModal}
              content={content} destroyOnClose footer={footer}
              loading={loading} title="Редактировать район"/>
  );
};