import {personalDataLocationFormNames} from "common/use-personal-data-location/constants";

export const personalDataFormNames = {
  firstName: "firstName",
  lastName: "lastName",
  middleName: "middleName",
  email: "email",
  avatar: "avatar",
  processingOfPersonalData: "processingOfPersonalData",
  ...personalDataLocationFormNames,
};