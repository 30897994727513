import {AppFlex} from "shared/UI/UIKit/Flex";
import type {GridRowProps} from "./Row.props";
import {getClassNames} from "./Row.utils";


export const AppGridRow = (props: GridRowProps) => {
  const {children, className, ...flexProps} = props;
  const classNames = getClassNames(className);

  return (
    <AppFlex {...flexProps} direction="row" unit="0" className={classNames}>
      {children}
    </AppFlex>
  );
};