import {useForm} from "antd/lib/form/Form";
import {useEffect, useState} from "react";
import {AppModal} from "shared/UI/UIKit/Modal";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {clearSelectedTemplate} from "features/templates/redux/slice";
import {editProgramTemplateAction} from "features/templates/redux/actions";
import {getSubjectsList} from "services/api";
import type {Subject} from "services/api";
import type {TemplateFormValues, TemplateModalProps} from "../types";
import {TemplateForm} from "../../forms/Template/TemplateForm";
import {TemplateModalFooter} from "../ModalFooter/ModalFooter";
import {educationLevels} from "../../constants";

export const EditTemplateModal = (props: TemplateModalProps) => {
  const [form] = useForm<TemplateFormValues>();
  const {loading, selectedTemplate} = useAppSelector(state => state.templatesState);
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [subjectsLoading, setSubjectsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!props.isPrograms && props.visible) {
      setSubjectsLoading(true);
      getSubjectsList().then(res => {
        setSubjects(res);
        setSubjectsLoading(false);
      });
    }
  }, [props.visible]);

  useEffect(() => {
    if (selectedTemplate) {
      form.setFieldsValue({
        ...selectedTemplate,
        subjectId: selectedTemplate.subject?.id.toString(),
        showInMainPrograms: !!selectedTemplate.showInMainPrograms,
      });
    }
  }, [selectedTemplate]);

  const onSubmit = () => {
    form.validateFields().then(values => {
      dispatch(editProgramTemplateAction({
        templateId: selectedTemplate!.id,
        template: {
          ...selectedTemplate!,
          ...values,
          showInMainPrograms: props.isPrograms ? values.showInMainPrograms : false,
          isProgram: !!props.isPrograms,
          blocks: [], // needs for backend
          subject: props.isPrograms ?
            selectedTemplate!.subject :
            subjects.find(subject => subject.id.toString() === values.subjectId)!
        }
      }))
        .unwrap()
        .then(hideModal);
    });
  };

  const hideModal = () => {
    props.hide();
    dispatch(clearSelectedTemplate());
    form.resetFields();
  };

  const footer = <TemplateModalFooter onSubmit={onSubmit}
                                      hideModal={hideModal}
                                      title="Редактировать"/>;
  const content = <TemplateForm form={form} isPrograms={props.isPrograms}
                                educationLevels={educationLevels} subjects={subjects}/>;

  return (
    <AppModal visible={props.visible} onCancel={hideModal}
              content={content} destroyOnClose footer={footer}
              loading={loading || subjectsLoading}
              title={props.isPrograms ? selectedTemplate?.programName : selectedTemplate?.name}/>
  );
};
