import {AppSider} from "shared/UI/UIKit/Sider";
import {TableFilter} from "common/TableFilter";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {FilterButton} from "common/FilterButton/FilterButton";
import {UsersTable} from "../Table/UsersTable";
import {ViewUserModal} from "../Modals/View";
import {RemoveUserModal} from "../Modals/Remove";
import {useUsers} from "./use-users";
import {usersFilterFields} from "./filter-fields";

export const Users = () => {
  const {state, methods} = useUsers();

  return (
    <>
      <AppFlex direction="row" justifyContent="flex-end">
        <FilterButton count={state.filterCount} click={methods.showSider}/>
      </AppFlex>
      <UsersTable dataSource={state.users}
                  loading={state.loading}
                  tableState={state.tableState}
                  onTableChange={methods.onTableChange}
                  onUserView={methods.onUserView}
                  onUserRemove={methods.showRemoveModal}/>
      <ViewUserModal visible={state.viewModalState.visible}
                     onHide={methods.viewModalMethods.hide}
                     userId={state.viewModalState.payload?.id}/>
      <RemoveUserModal visible={state.removeModalState.visible}
                       hide={methods.removeModalMethods.hide}
                       user={state.removeModalState.payload?.user}
                       onItemRemove={methods.onItemRemove}/>
      <AppSider title="Фильтры"
                isSiderVisible={state.siderVisible}
                submitBtnText="Применить"
                cancelBtnText="Сбросить всё"
                hideSider={methods.hideSider}>
        <TableFilter fields={usersFilterFields}
                     submitBtnText="Применить"
                     resetBtnText="Сбросить всё"
                     onSubmit={methods.onFilterChange}
                     onClose={methods.hideSider}
                     fieldsValue={state.filterState}/>
      </AppSider>
    </>
  );
};