import {
  AppTable,
} from "shared/UI/UIKit/Table";
import type {DictionariesTableProps} from "./types";
import {useDictionariesTable} from "./use-table";

export const DictionariesTable = (props: DictionariesTableProps) => {
  const {onRow} = useDictionariesTable();

  return (
    <AppTable dataSource={props.dataSource} onRow={onRow}
              columns={props.columns} loading={props.loading}
              rowKey={(record) => record.id}
              pagination={props.tableState.pagination}
              sorters={props.tableState.sorters}
              className={props.className} onChange={props.onChange}/>
  );
};
