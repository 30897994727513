import {useEffect, useState} from "react";
import {AppModal} from "shared/UI/UIKit/Modal";
import {useAppForm} from "shared/UI/UIKit/Form";
import {updateOrganization} from "services/api";
import type {EditOrganizationModalProps} from "./types";
import type {OrganizationFormValues} from "../../Form/Organization/types";
import {OrganizationModalFooter} from "../ModalFooter";
import {OrganizationForm} from "../../Form/Organization";

export const EditOrganizationModal = (props: EditOrganizationModalProps) => {
  const [form] = useAppForm<OrganizationFormValues>();
  const [loading, setLoading] = useState(false);
  const selectedOrganization = props.organizations.find(item => item.id === props.id)!;

  useEffect(() => {
    if (props.visible) {
      form.setFieldsValue({
        name: selectedOrganization.name,
        fullName: selectedOrganization.fullName,
        municipality: {
          id: selectedOrganization.municipality.id.toString(),
        },
      });
    }
  }, [props.visible]);

  const hideModal = () => {
    form.resetFields();
    props.hide();
  };

  const onSubmit = () => {
    form.validateFields().then(values => {
      setLoading(true);

      updateOrganization({
        id: selectedOrganization.id,
        name: values.name,
        fullName: values.fullName,
        municipality: props.municipalities.find(
          municipality => municipality.id.toString() === values.municipality!.id
        )!,
      }).then((res) => {
        if (res.municipality.id === selectedOrganization.municipality.id) {
          props.setItem(res);
        } else {
          props.removeItem(Number(res.id));
        }

        hideModal();
      }).finally(() => {
        setLoading(false);
      });
    });
  };

  const footer = <OrganizationModalFooter hideModal={hideModal}
                                          onSubmit={onSubmit}
                                          title="Сохранить"/>;
  const content = <OrganizationForm form={form} municipalityVisible
                                    municipalityOptions={props.municipalitiesOptions}/>;

  return (
    <AppModal visible={props.visible} onCancel={hideModal}
              content={content} destroyOnClose footer={footer}
              loading={loading} title="Редактировать организацию"/>
  );
};