import {appRouterPaths} from "services/navigation";
import {DictionariesPage} from "pages/Dictionaries";
import {DictionaryPage} from "pages/Dictionary";
import type {AppRouteProps} from "../types";

export const dictionariesPageRoute: AppRouteProps = {
  path: appRouterPaths.dictionaries,
  element: <DictionariesPage/>,
};

export const dictionaryPageRoute: AppRouteProps = {
  path: appRouterPaths.dictionary,
  element: <DictionaryPage/>,
};
