import {useNavigate} from "react-router-dom";
import {getDictionaryUrl} from "services/navigation";
import type {Dictionary} from "services/api";
import type {AppTableProps} from "shared/UI/UIKit/Table";

export const useDictionariesTable = () => {
  const navigate = useNavigate();

  const onRow: AppTableProps<Dictionary>["onRow"] = (data) => {
    return {
      onClick: () => {
        navigate(getDictionaryUrl({
          dictionaryId: data.id!.toString(),
        }));
      }
    };
  };

  return {
    onRow,
  };
};