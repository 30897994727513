import Countdown from "antd/lib/statistic/Countdown";
import cn from "classnames";
import {useState} from "react";
import {Navigate} from "react-router-dom";
import {sendVerificationEmail} from "services/api";
import {getAuthorizationUrl} from "services/navigation";
import {AppButton} from "shared/UI/UIKit/Button";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppText} from "shared/UI/UIKit/Text";
import {LocaleStorageService} from "shared/helpers/local-storage";
import {LSKeys} from "shared/constants/local-storage-keys";
import styles from "./EmailConfirm.module.scss";

const deadlineDelay = 60_000;
const AUTHORIZATION_URL = getAuthorizationUrl();

export const EmailConfirm = () => {
  const [isDisabled, setDisabled] = useState<boolean>(true);
  const [deadline, setDeadline] = useState<number>(() => Date.now() + deadlineDelay);
  const emailVerificationToken = LocaleStorageService.getStringItem(LSKeys.emailVerificationToken);

  const onSubmit = () => {
    try {
      sendVerificationEmail(emailVerificationToken!, false);
      setDeadline(Date.now() + deadlineDelay);
      setDisabled(true);
    } catch {
      // do nothing
    }
  };

  const onCountdownFinish = () => {
    setDisabled(false);
  };

  if (!emailVerificationToken) {
    return (
      <Navigate to={AUTHORIZATION_URL} replace/>
    );
  }

  return (
    <AppFlex className={styles.container} unit="10" alignItems="center" justifyContent="center">
      <AppText className={styles.text} type="secondary" size={16}>
        Вам на почту отправлено письмо для подтверждения е-мэйла. Если вы его не получили отправьте запрос повторно
      </AppText>
      <AppButton onClick={onSubmit} disabled={isDisabled}>
        Отправить
      </AppButton>
      <Countdown className={cn(!isDisabled && styles["countdown_hidden"])}
                 value={deadline} format="mm:ss" onFinish={onCountdownFinish}/>
    </AppFlex>
  );
};
