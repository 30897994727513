import cn from "classnames";
import type {AppFormItemLabelProps} from "./FormItemLabel.types";
import styles from "./FormItemLabel.module.scss";

export const getClassName = (props: AppFormItemLabelProps) => {
  return cn(
    props.className,
  );
};

export const getTextClassName = (props: AppFormItemLabelProps) => {
  return cn(
    styles.label,
    props.required && styles.required,
    props.textClassName,
  );
};