import {useModal} from "shared/UI/UIKit/Modal";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {getProfileAction, updateProfileAction} from "services/redux/reducers/profile";
import type {UserProfileBody} from "services/api";
import type {PersonalDataFormValues} from "../PersonalDataForm";
import {PersonalDataForm} from "../PersonalDataForm";
import {ChangePasswordModal} from "../../modals/ChangePasswordModal";

export const PersonalData = () => {
  const {state: changePasswordModalState, methods: changePasswordModalMethods} = useModal();
  const {profile, isProfileUpdating} = useAppSelector(state => state.profileState);
  const dispatch = useAppDispatch();

  const onSubmit = async (values: PersonalDataFormValues) => {
    try {
      const nextProfileBody: UserProfileBody = {
        ...profile!,
        ...values,
      };
      await dispatch(updateProfileAction(nextProfileBody)).unwrap();
      await dispatch(getProfileAction());
    }
    catch {
      // do nothing
    }
  };

  return (
    <>
      <PersonalDataForm initialValues={profile!} loading={isProfileUpdating}
                        onSubmit={onSubmit}
                        onChangePasswordClick={changePasswordModalMethods.show}/>
      <ChangePasswordModal visible={changePasswordModalState.visible}
                           onHide={changePasswordModalMethods.hide}/>
    </>
  );
};
