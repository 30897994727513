import {createAsyncThunk} from "@reduxjs/toolkit";
import {
  createDictionary,
  updateDictionary,
  deleteDictionary,
  getDictionaryList
} from "services/api";
import type {RootState} from "services/redux/types";
import type {DictionaryBody, DictionaryListRequest} from "services/api";

export const createDictionaryAction = createAsyncThunk("dictionaries/create", async(body: DictionaryBody) => {
  try {
    return await createDictionary(body);
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const editDictionaryAction = createAsyncThunk("dictionaries/edit", async(body: DictionaryBody, thunkApi) => {
  const {dictionariesState: {selectedDictionary}} = thunkApi.getState() as RootState;
  try {
    return await updateDictionary(selectedDictionary!.id.toString(), body);
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const removeDictionaryAction = createAsyncThunk("dictionaries/remove", async(dictionaryId: string) => {
  try {
    return await deleteDictionary(dictionaryId);
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const getDictionaryListAction = createAsyncThunk("dictionaries/getList", async(payload: DictionaryListRequest) => {
  try {
    return await getDictionaryList(payload);
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});
