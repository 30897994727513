import {AppTableControls} from "common/TableControls/TableControls";
import {ProgramTemplateStatus} from "services/api";
import {AppText} from "shared/UI/UIKit/Text";
import type {AppTableColumn} from "shared/UI/UIKit/Table";
import type {ProgramTemplate} from "services/api";

export const getWorkProgramsTableColumns = (
  edit: (id: number) => void,
  remove: (id: number) => void,
): AppTableColumn<ProgramTemplate>[] => {
  return [
    {
      key: "id",
      title: "ID",
      dataIndex: ["id"],
      maxWidth: "10rem"
    },
    {
      key: "programName",
      title: "Наименование",
      dataIndex: ["programName"],
    },
    {
      key: "name",
      title: "Наименование шаблона",
      dataIndex: ["name"],
    },
    {
      key: "createdBy",
      title: "Автор",
      dataIndex: ["createdBy"],
    },
    {
      key: "subject",
      title: "Учебный предмет",
      dataIndex: ["subject", "name"],
    },
    {
      key: "status",
      title: "Статус",
      dataIndex: ["status"],
      maxWidth: "13rem",
      render: (value: any, record: ProgramTemplate) => {
        if (record.status === ProgramTemplateStatus.DONE) {
          return <AppText type="success">Завершена</AppText>;
        } else {
          return <AppText type="warning">В работе</AppText>;
        }
      }
    },
    {
      key: "educationLevel",
      title: "Уровень образования",
      dataIndex: ["educationLevel"],
      maxWidth: "19rem",
    },
    {
      key: "controls",
      title: "",
      dataIndex: [],
      maxWidth: "10rem",
      render: (value: any, record: ProgramTemplate) => {
        const editTemplate = () => edit(record.id);
        const removeTemplate = () => remove(record.id);

        return (
          <AppTableControls edit={editTemplate} remove={removeTemplate}/>
        );
      }
    }
  ];
};

export const getTemplatesTableColumns = (
  edit: (id: number) => void,
  remove: (id: number) => void,
): AppTableColumn<ProgramTemplate>[] => {
  return [
    {
      key: "id",
      title: "ID",
      dataIndex: ["id"],
      maxWidth: "10rem"
    },
    {
      key: "name",
      title: "Наименование",
      dataIndex: ["name"],
    },
    {
      key: "subject",
      title: "Учебный предмет",
      dataIndex: ["subject", "name"],
    },
    {
      key: "status",
      title: "Статус",
      dataIndex: ["status"],
      maxWidth: "13rem",
      render: (value: any, record: ProgramTemplate) => {
        if (record.status === ProgramTemplateStatus.DONE) {
          return <AppText type="success">Опубликован</AppText>;
        } else {
          return <AppText type="warning">Черновик</AppText>;
        }
      }
    },
    {
      key: "educationLevel",
      title: "Уровень образования",
      dataIndex: ["educationLevel"],
      maxWidth: "19rem",
    },
    {
      key: "controls",
      title: "",
      dataIndex: [],
      maxWidth: "10rem",
      render: (value: any, record: ProgramTemplate) => {
        const editTemplate = () => edit(record.id);
        const removeTemplate = () => remove(record.id);

        return (
          <AppTableControls edit={editTemplate} remove={removeTemplate}/>
        );
      }
    }
  ];
};
