import {AppPage} from "common/Layout";
import {EmailApproval} from "features/email-approval";

export const EmailApprovalPage = () => {
  return (
    <AppPage documentTitle="Подтверждение электронной почты">
      <EmailApproval/>
    </AppPage>
  );
};
