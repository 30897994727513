import {useCallback, useRef} from "react";
import type {UploadFile} from "antd";
import type {UploadChangeParam} from "antd/lib/upload/interface";

export const useUploaderOnChange = (onChange?: (fileList: UploadFile[]) => void) => {
  const ref = useRef<UploadFile[]>([]);

  const isSameIds = (list1: UploadFile[], list2: UploadFile[]) => {
    return list1.every((item1, index) => item1.uid === list2[index]?.uid);
  };

  return useCallback((info: UploadChangeParam) => {
    // Note: antd Upload component calls onChange method for every selected file with the same passed data
    // Skip repeated calls
    if (!isSameIds(info.fileList, ref.current)) {
      onChange?.(info.fileList);
    }
    ref.current = info.fileList;
  }, []);
};