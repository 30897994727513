import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppText} from "shared/UI/UIKit/Text";
import {ProgramTemplateStatus} from "services/api";
import type {TemplateLabelProps} from "./types";
import styles from "./styles.module.scss";

export const TemplateLabel = ({label, status}: TemplateLabelProps) => {
  return (
    <AppFlex direction="row" justifyContent="space-between" alignItems="flex-start">
      <AppText className={styles.label}>
        {label}
      </AppText>
      {status && (
        <AppText type={status === ProgramTemplateStatus.DONE ? "success" : "warning"}>
          {status === ProgramTemplateStatus.DONE ? "Опубликован" : "Черновик"}
        </AppText>
      )}
    </AppFlex>
  );
};