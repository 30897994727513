import {generateUUID} from "shared/helpers/utils/generate-uuid";
import {TableFilterFieldType} from "common/TableFilter";
import type {TableFilterField} from "common/TableFilter";
import {educationLevels, getProgramTemplateStatuses} from "../constants";

const commonFilterFields: TableFilterField[] = [
  {
    key: generateUUID(),
    name: "subjectId",
    label: "Учебный предмет",
    type: TableFilterFieldType.SELECT,
    options: [],
    isLoadableOptions: true
  },
  {
    key: generateUUID(),
    name: "educationLevel",
    label: "Уровень образования",
    type: TableFilterFieldType.SELECT,
    options: educationLevels
  },
];

export const workProgramsFilterFields: TableFilterField[] = [
  {
    key: generateUUID(),
    name: "programName",
    label: "Наименование",
    type: TableFilterFieldType.INPUT,
  },
  {
    key: generateUUID(),
    name: "name",
    label: "Наименование шаблона",
    type: TableFilterFieldType.INPUT,
  },
  {
    key: generateUUID(),
    name: "status",
    label: "Статус",
    type: TableFilterFieldType.SELECT,
    options: getProgramTemplateStatuses(true)
  },
  ...commonFilterFields,
];

export const templatesFilterFields: TableFilterField[] = [
  {
    key: generateUUID(),
    name: "name",
    label: "Наименование",
    type: TableFilterFieldType.INPUT,
  },
  {
    key: generateUUID(),
    name: "status",
    label: "Статус",
    type: TableFilterFieldType.SELECT,
    options: getProgramTemplateStatuses()
  },
  ...commonFilterFields,
];