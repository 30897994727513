import cn from "classnames";
import {useEffect} from "react";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppCheckIcon} from "shared/UI/UIKit/icons/Check";
import {AppText} from "shared/UI/UIKit/Text";
import {AppButton} from "shared/UI/UIKit/Button";
import {useAuth} from "services/auth";
import {LocaleStorageService} from "shared/helpers/local-storage";
import {LSKeys} from "shared/constants/local-storage-keys";
import styles from "./EmailApproval.module.scss";

export const EmailApproval = () => {
  const {login} = useAuth();

  useEffect(() => {
    LocaleStorageService.removeItem(LSKeys.emailVerificationToken);
  }, []);

  return (
    <AppFlex className={styles.container} unit="10" alignItems="center" justifyContent="center">
      <AppText className={styles.text} type="secondary" size={20}>
        Электронная почта успешно подтверждена. Теперь вы можете авторизоваться
      </AppText>
      <AppCheckIcon className={cn(styles.icon, styles["icon_success"])}/>
      <AppButton onClick={login}>Войти</AppButton>
    </AppFlex>
  );
};
