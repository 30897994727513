import {generateUUID} from "shared/helpers/utils/generate-uuid";
import {TableFilterFieldType} from "common/TableFilter";
import type {TableFilterField} from "common/TableFilter";

export const dictionaryFilterFields: TableFilterField[] = [
  {
    key: generateUUID(),
    name: "name",
    label: "Наименование",
    type: TableFilterFieldType.INPUT,
  },
];