import {
  getDictionariesUrl,
  getHomeUrl,
  getTemplatesUrl,
  getWorkProgramsUrl,
  getPersonalDataUrl,
  getLogoutUrl,
  getOrganizationsUrl,
  getMunicipalitiesUrl,
  getRegionsUrl,
  getUsersUrl
} from "services/navigation";
import {AppDictionaryIcon} from "shared/UI/UIKit/icons/Dictionary";
import {AppEducationIcon} from "shared/UI/UIKit/icons/Education";
import {AppUserIcon} from "shared/UI/UIKit/icons/User";
import {makeKeyGenerator} from "shared/helpers/utils/key-generator";
import {AppHomeIcon} from "shared/UI/UIKit/icons/Home";
import {AppTemplateIcon} from "shared/UI/UIKit/icons/Template";
import {useAuth} from "services/auth";
import {useUserProfileRole} from "common/use-user-profile-role";
import {SidebarMenuHelpers} from "../Sidebar";

const HOME_URL = getHomeUrl();
const TEMPLATES_URL = getTemplatesUrl();
const ORGANIZATIONS_URL = getOrganizationsUrl();
const MUNICIPALITIES_URL = getMunicipalitiesUrl();
const REGIONS_URL = getRegionsUrl();
const USERS_URL = getUsersUrl();
const WORK_PROGRAMS_URL = getWorkProgramsUrl();
const DICTIONARIES_URL = getDictionariesUrl();
const PERSONAL_DATA_URL = getPersonalDataUrl();
const LOGOUT_URL = getLogoutUrl();

export const useSidebar = (visible: boolean) => {
  const {getNextKey} = makeKeyGenerator();
  const {logout} = useAuth();
  const {isAdmin, isSupport} = useUserProfileRole();

  const getSidebarMenuItems = () => {
    if (!visible) {
      return [];
    }

    const items = [
      SidebarMenuHelpers.getLinkItem({
        key: getNextKey(),
        label: "Главная",
        icon: <AppHomeIcon/>,
        linkProps: {
          to: HOME_URL,
        },
      }),
      SidebarMenuHelpers.getLinkItem({
        key: getNextKey(),
        label: "Рабочие программы",
        icon: <AppEducationIcon/>,
        linkProps: {
          to: WORK_PROGRAMS_URL,
        },
      }),
      SidebarMenuHelpers.getLinkItem({
        key: getNextKey(),
        label: "Справочники",
        icon: <AppDictionaryIcon/>,
        linkProps: {
          to: DICTIONARIES_URL,
        },
      }),
      SidebarMenuHelpers.getGroupItem({
        key: getNextKey(),
        label: "Личный кабинет",
        children: [
          SidebarMenuHelpers.getLinkItem({
            key: getNextKey(),
            label: "Персональные данные",
            icon: <AppUserIcon/>,
            linkProps: {
              to: PERSONAL_DATA_URL,
            },
          }),
        ],
      }),
    ];

    if (isAdmin && !isSupport) {
      items.splice(1, 0,
        SidebarMenuHelpers.getLinkItem({
          key: getNextKey(),
          label: "Шаблоны программ",
          icon: <AppTemplateIcon/>,
          linkProps: {
            to: TEMPLATES_URL,
          },
        }),
      );
    }

    if (isAdmin) {
      items.push(
        SidebarMenuHelpers.getLinkItem({
          key: getNextKey(),
          label: "Пользователи",
          linkProps: {
            to: USERS_URL,
          },
        }),
        SidebarMenuHelpers.getLinkItem({
          key: getNextKey(),
          label: "Регионы",
          linkProps: {
            to: REGIONS_URL,
          },
        }),
        SidebarMenuHelpers.getLinkItem({
          key: getNextKey(),
          label: "Районы",
          linkProps: {
            to: MUNICIPALITIES_URL,
          },
        }),
        SidebarMenuHelpers.getLinkItem({
          key: getNextKey(),
          label: "Организации",
          linkProps: {
            to: ORGANIZATIONS_URL,
          },
        })
      );
    }

    items.push(
      SidebarMenuHelpers.getLinkItem({
        key: getNextKey(),
        label: "Выход",
        linkProps: {
          to: LOGOUT_URL,
        },
        onClick: logout
      }),
    );

    return items;
  };

  return {
    sidebarMenuItems: getSidebarMenuItems(),
  };
};
