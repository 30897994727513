import {AppButton} from "shared/UI/UIKit/Button";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {TableColumnsFilter} from "common/ColumnsFilter/ColumnsFilter";
import {FilterButton} from "common/FilterButton/FilterButton";
import {useUserProfileRole} from "common/use-user-profile-role";
import type {DictionaryTableControlsProps} from "./types";
import styles from "./Controls.module.scss";

export const DictionaryTableControls = (props: DictionaryTableControlsProps) => {
  const {isAdmin} = useUserProfileRole();

  return (
    <AppFlex direction="row" justifyContent="flex-end" className={styles.controls}>
      {isAdmin && (
        <AppButton onClick={props.createDictionaryItem}>
          Создать
        </AppButton>
      )}
      <FilterButton count={props.filterCount} click={props.showSider}/>
      <TableColumnsFilter visibleKeys={props.visibleKeys} columns={props.columns}
                          setVisibleKeys={props.setVisibleKeys}/>
    </AppFlex>
  );
};