import type {ProgramTemplateBlockTable} from "services/api";

const isIdFake = (id: string | number | null) => typeof id === "string" && id.startsWith("fake_");

const getNormalizedId = (id: string | number | null) => isIdFake(id) ? null : id;

export const getNormalizedTables = (tables: ProgramTemplateBlockTable[]): ProgramTemplateBlockTable[] => {
  return tables.map(table => ({
    ...table,
    id: getNormalizedId(table.id),
    name: table.name || "",
    sections: table.sections.map(section => ({
      ...section,
      id: getNormalizedId(section.id),
      table: section.table ? {...section.table, id: getNormalizedId(section.table.id)} : null,
      rows: section.rows.map(row => ({
        ...row,
        id: getNormalizedId(row.id),
        section: row.section ? {...row.section, id: getNormalizedId(row.section.id)} : null,
        fields: row.fields.map(field => ({
          ...field,
          id: getNormalizedId(field.id),
          row: field.row ? {...field.row, id: getNormalizedId(field.row.id)} : null,
        })),
      })),
    })),
  }));
};