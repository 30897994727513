import type {AppSelectOption} from "shared/UI/UIKit/Select";

export enum TableFilterFieldType {
  INPUT = "input",
  SELECT = "select",
}

export type TableFilterField = {
  key: string;
  name: string;
  label: string;
  type: TableFilterFieldType;
  options?: AppSelectOption[];
  isLoadableOptions?: boolean;
  loading?: boolean;
}

export type TableFilterProps = {
  fields: TableFilterField[];
  submitBtnText: string;
  resetBtnText: string;
  fieldsValue?: Record<string, any>;
  onClose(): void;
  onSubmit: (values: any) => void;
  onLoadOptions?: (fieldName: string) => void;
}