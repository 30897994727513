import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppButton} from "shared/UI/UIKit/Button";
import type {DictionaryModalFooterProps} from "./types";

export const DictionaryModalFooter = (props: DictionaryModalFooterProps) => {
  return (
    <AppFlex direction="row">
      <AppButton onClick={props.onSubmit}>
        {props.title}
      </AppButton>
      <AppButton type="outline" onClick={props.hideModal}>
        Отмена
      </AppButton>
    </AppFlex>
  );
};