// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {LoadCanvasTemplate} from "react-simple-captcha";
import {AppInput} from "shared/UI/UIKit/Input";
import {AppText} from "shared/UI/UIKit/Text";
import type {ChangeEventHandler} from "react";
import type {CaptchaProps} from "./types";
import styles from "./styles.module.scss";

const RELOAD_COLOR = "#0B60D4"; // blue-6

export const Captcha = (props: CaptchaProps) => {
  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    props.onChange?.(e.target.value);
  };

  return (
    <div>
      <LoadCanvasTemplate reloadText="Обновить" reloadColor={RELOAD_COLOR}/>
      <AppInput value={props.value} onChange={handleInputChange} className={styles.input}/>
      <AppText type="danger" className={styles["error-msg"]}>
        {props.errorMsg}
      </AppText>
    </div>
  );
};