import {notification} from "antd";
import {appApi} from "services/api/api-instance";
import type {DictionaryBody, Dictionary, DictionaryItem, DictionaryItemBody, DictionaryItemRequest, DictionaryItemResponse, DictionaryListResponse, DictionaryListRequest} from "./types";

export const deleteDictionary = (dictionaryId: string): Promise<number> => {
  return appApi
    .delete(`/work_program_reference/${dictionaryId}`)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};

export const getDictionaryById = (dictionaryId: string): Promise<Dictionary> => {
  return appApi
    .get(`/work_program_reference/${dictionaryId}`)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};

export const getDictionaryList = (payload: DictionaryListRequest): Promise<DictionaryListResponse> => {
  return appApi
    .get("/work_program_reference/", {
      params: payload,
      paramsSerializer: {indexes: null}
    })
    .then(({data, headers}) => ({
      data,
      total: Number(headers["totalelements"]) || 0
    }))
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};

export const getDictionaryByCode = (code: string): Promise<Dictionary | null> => {
  return appApi
    .get("/work_program_reference/", {
      params: {
        code,
      },
    })
    .then(res => {
      return res.data[0] ? res.data[0] : null;
    })
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};

export const createDictionary = (body: DictionaryBody): Promise<Dictionary> => {
  return appApi
    .post("/work_program_reference/", body)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};

export const updateDictionary = (dictionaryId: string, body: DictionaryBody): Promise<Dictionary> => {
  return appApi
    .put(`/work_program_reference/${dictionaryId}`, body)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};

// dictionary items

export const deleteDictionaryItem = (dictionaryItemId: string): Promise<number> => {
  return appApi
    .delete(`/work_program_reference_item/${dictionaryItemId}`)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};

export const getDictionaryItemById = (dictionaryItemId: string): Promise<DictionaryItem> => {
  return appApi
    .get(`/work_program_reference_item/${dictionaryItemId}`)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};

export const getDictionaryItemList = (payload: DictionaryItemRequest): Promise<DictionaryItemResponse> => {
  return appApi
    .get("/work_program_reference_item/", {
      params: payload,
      paramsSerializer: {indexes: null}
    })
    .then(({data, headers}) => ({
      data,
      total: Number(headers["totalelements"]) || 0
    }))
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};

export const createDictionaryItem = (body: DictionaryItemBody): Promise<DictionaryItem> => {
  return appApi
    .post("/work_program_reference_item/", body)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};

export const updateDictionaryItem = (dictionaryItemId: string, body: DictionaryItemBody): Promise<DictionaryItem> => {
  return appApi
    .put(`/work_program_reference_item/${dictionaryItemId}`, body)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};