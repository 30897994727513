import {
  AppTable,
} from "shared/UI/UIKit/Table";
import type {DictionaryTableProps} from "./types";

export const DictionaryTable = (props: DictionaryTableProps) => {
  return (
    <AppTable dataSource={props.dataSource} columns={props.columns} onChange={props.onChange}
              loading={props.loading} rowKey={(record) => record.id!}
              className={props.className} pagination={props.tableState.pagination}
              sorters={props.tableState.sorters}/>
  );
};
