import type {AppIconProps} from "../Icons.types";
import {getClassNames} from "../Icons.utils";
import styles from "./Template.module.scss";

export const AppTemplateIcon = (props: AppIconProps) => {
  const {className, ...rest} = props;
  const classNames = getClassNames(styles.icon, className);

  return (
    <svg {...rest} className={classNames} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 20" fill="none">
      <path d="M1 1H15V19H1V1Z" stroke="#737781" strokeWidth="2"/>
      <mask id="path-2-inside-1_3_20" fill="white">
        <rect x="4" y="4" width="8" height="5" rx="1"/>
      </mask>
      <rect x="4" y="4" width="8" height="5" rx="1" fill="#737781" stroke="#737781" strokeWidth="4" mask="url(#path-2-inside-1_3_20)"/>
      <rect x="4" y="11" width="8" height="2" rx="1" fill="#737781"/>
      <rect x="4" y="14" width="8" height="2" rx="1" fill="#737781"/>
    </svg>
  );
};
