import {AppForm, AppFormItem, formRules} from "shared/UI/UIKit/Form";
import {AppInput} from "shared/UI/UIKit/Input";
import type {DictionaryFormProps} from "./types";
import {dictionaryFormRules} from "./utils";

export const DictionaryForm = (props: DictionaryFormProps) => {
  return (
    <AppForm form={props.form}>
      <AppFormItem rules={formRules.required} label="Название" name="name">
        <AppInput placeholder="Введите название"/>
      </AppFormItem>
      <AppFormItem rules={[dictionaryFormRules.codeValid]} label="Мнемокод" name="code">
        <AppInput disabled={props.isEdit} placeholder="Введите значение"/>
      </AppFormItem>
    </AppForm>
  );
};