import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {homeBreadcrumbItem, useBreadcrumbsService} from "shared/UI/UIKit/Breadcrumbs";
import {getDictionariesUrl} from "services/navigation";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {getDictionaryAction} from "features/dictionary/redux/actions";
import type {DictionaryPageParams} from "services/navigation";

const getDictionaryBreadcrumbs = (dictionaryName: string) => {
  return [
    homeBreadcrumbItem,
    {
      title: "Справочники",
      url: getDictionariesUrl(),
    },
    {
      title: dictionaryName,
      url: ""
    },
  ];
};

export const useDictionaryPage = () => {
  const {dictionary} = useAppSelector(state => state.dictionaryState);
  const {dictionaryId} = useParams<DictionaryPageParams>();
  const breadcrumbsService = useBreadcrumbsService();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (dictionaryId) {
      breadcrumbsService.setBreadcrumbsLoading(true);
      dispatch(getDictionaryAction(dictionaryId))
        .unwrap()
        .then(res => {
          breadcrumbsService.setBreadcrumbs(getDictionaryBreadcrumbs(res.name));
          breadcrumbsService.setBreadcrumbsLoading(false);
        });
    }
  }, [dictionaryId]);

  return {
    breadcrumbs: breadcrumbsService.breadcrumbs,
    breadcrumbsLoading: breadcrumbsService.breadcrumbsLoading,
    dictionary,
  };
};