import {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {clearState, setSelectedDictionaryItem} from "features/dictionary/redux/slice";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {getDictionaryItemsAction} from "features/dictionary/redux/actions";
import {getDataIndexedColumns, getDefaultTablePagination, useTableSearchParams} from "shared/UI/UIKit/Table";
import {useFilteredColumns} from "common/ColumnsFilter/use-filtered-columns";
import type {DictionaryItem} from "services/api";
import type {AppTableColumn,AppTableState} from "shared/UI/UIKit/Table";
import type {DictionaryPageParams} from "services/navigation";
import {getDictionaryTableColumns} from "./columns";

const getInitialStateWithSorters = (
  columns: AppTableColumn<DictionaryItem>[]
): AppTableState => {
  return {
    sorters: getDataIndexedColumns(columns)
              .filter(({key}) => key !== "controls")
              .map(({key, dataIndex}) => {
                return key === "name" ? {
                  key,
                  dataIndex,
                  order: "ascend",
                } : {
                  key,
                  dataIndex,
                };
              }),
    filters: [],
    pagination: getDefaultTablePagination(),
  };
};

export const useDictionary = () => {
  const {items, total, loading} = useAppSelector(state => state.dictionaryState);
  const dispatch = useAppDispatch();
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [removeModalVisible, setRemoveModalVisible] = useState(false);
  const {dictionaryId} = useParams<DictionaryPageParams>();
  const [siderVisible, setSiderVisible] = useState(false);

  const editDictionaryItem = (id: number) => {
    const selectedDictionaryItem = items.find(item => item.id === id);

    if (selectedDictionaryItem) {
      dispatch(setSelectedDictionaryItem(selectedDictionaryItem));
      setEditModalVisible(true);
    }
  };

  const removeDictionaryItem = (id: number) => {
    const selectedDictionaryItem = items.find(item => item.id === id);

    if (selectedDictionaryItem) {
      dispatch(setSelectedDictionaryItem(selectedDictionaryItem));
      setRemoveModalVisible(true);
    }
  };

  const columns = getDictionaryTableColumns(editDictionaryItem, removeDictionaryItem);
  const {filteredColumns, hiddenColumns, visibleKeys, setVisibleKeys} = useFilteredColumns(columns);

  const getTableItems = (nextTableState: AppTableState, nextFilterState: Record<string, any>) => {
    const {pagination: {current, pageSize}, sorters} = nextTableState;
    const sort = sorters.filter((col) => !!col.order).map((col) => `${col.order === "ascend" ? "+" : "-"}${col.dataIndex.join(".")}`);

    if (dictionaryId) {
      dispatch(getDictionaryItemsAction({
        referenceId: dictionaryId,
        page: current! - 1,
        limit: pageSize,
        sort,
        ...nextFilterState
      }));
    }
  };

  const {tableState, onTableChange, filterState, filterCount, onFilterChange, onItemRemove, refreshPage} = useTableSearchParams(
    () => getInitialStateWithSorters(columns),
    getTableItems,
    items.length,
    total,
    hiddenColumns
  );

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  const createDictionaryItem = () => {
    setCreateModalVisible(true);
  };

  const showSider = () => {
    setSiderVisible(true);
  };
  const hideSider = useCallback(() => {
    setSiderVisible(false);
  }, []);

  return {
    state: {
      items,
      loading,
      createModalVisible,
      editModalVisible,
      removeModalVisible,
      tableState,
      siderVisible,
      columns,
      filteredColumns,
      hiddenColumns,
      visibleKeys,
      filterState,
      filterCount,
    },
    methods: {
      setCreateModalVisible,
      setEditModalVisible,
      createDictionaryItem,
      editDictionaryItem,
      removeDictionaryItem,
      setRemoveModalVisible,
      showSider,
      hideSider,
      onTableChange,
      setVisibleKeys,
      onFilterChange,
      onItemRemove,
      refreshPage,
    }
  };
};