import {AppFlex} from "shared/UI/UIKit/Flex";
import {useAppForm} from "shared/UI/UIKit/Form";
import {useModal, usePayloadedModal} from "shared/UI/UIKit/Modal";
import type {UserProfileOrganization} from "services/api";
import {OrganizationsTable} from "./Table/OrganizationsTable";
import {RemoveOrganizationModal} from "./Modals/Remove/Remove";
import {EditOrganizationModal} from "./Modals/Edit";
import {LocationForm} from "./Form/LocationForm";
import {CreateOrganizationModal} from "./Modals/Create";
import {useOrganizations} from "./use-organizations";

type ModalPayload = {
  id: number;
}

export const Organizations = () => {
  const [form] = useAppForm();
  const createModal = useModal();
  const editModal = usePayloadedModal<ModalPayload>();
  const removeModal = usePayloadedModal<ModalPayload>();

  const {
    regionsOptions,
    onRegionChange,
    municipalities,
    municipalitiesOptions,
    regionId,
    municipalityId,
    organizations,
    locationLoading,
    onMunicipalityChange,
    setOrganizations,
    name,
    onNameChange,
    tableState,
    onTableChange,
  } = useOrganizations(form);

  const addItem = (newItem: UserProfileOrganization) => {
    setOrganizations([newItem, ...organizations]);
  };
  const removeItem = (id: number) => {
    setOrganizations(organizations.filter(item => item.id !== id));
  };
  const setItem = (newItem: UserProfileOrganization) => {
    setOrganizations(organizations.map(item => item.id === newItem.id ? newItem : item));
  };

  const showEditModal = (id: number) => editModal.methods.show({id});
  const showCreateModal = () => createModal.methods.show();
  const showRemoveModal = (id: number) => removeModal.methods.show({id});

  return (
    <AppFlex>
      <LocationForm form={form}
                    regionsOptions={regionsOptions}
                    onRegionChange={onRegionChange}
                    municipalitiesOptions={municipalitiesOptions}
                    regionId={regionId}
                    onMunicipalityChange={onMunicipalityChange}
                    name={name}
                    onNameChange={onNameChange}/>
      <OrganizationsTable dataSource={organizations}
                          edit={showEditModal}
                          remove={showRemoveModal}
                          create={showCreateModal}
                          loading={locationLoading}
                          tableState={tableState}
                          onTableChange={onTableChange}/>
      <CreateOrganizationModal visible={createModal.state.visible}
                               hide={createModal.methods.hide}
                               municipalityId={municipalityId}
                               regionId={regionId}
                               addItem={addItem}/>
      <RemoveOrganizationModal visible={removeModal.state.visible}
                               hide={removeModal.methods.hide}
                               id={removeModal.state.payload?.id}
                               onItemRemove={removeItem}
                               organizations={organizations}/>
      <EditOrganizationModal visible={editModal.state.visible}
                             hide={editModal.methods.hide}
                             municipalities={municipalities}
                             municipalitiesOptions={municipalitiesOptions}
                             organizations={organizations}
                             id={editModal.state.payload?.id}
                             setItem={setItem} removeItem={removeItem}/>
    </AppFlex>
  );
};