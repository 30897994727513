import {notification} from "antd";
import type {ProgramTemplateBlockBody, ProgramTemplateBlock} from "./types";
import {appApi} from "../api-instance";

export const deleteProgramTemplateBlock = (blockId: string): Promise<number> => {
  return appApi
    .delete(`/work_program_block/${blockId}`)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};

export const getProgramTemplateBlockList = (templateId: string): Promise<ProgramTemplateBlock[]> => {
  return appApi
    .get(`/work_program_block/?templateId=${templateId}`)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};

export const getProgramTemplateBlockById = (blockId: string): Promise<ProgramTemplateBlock> => {
  return appApi
    .get(`/work_program_block/${blockId}`)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};

export const createProgramTemplateBlock = (body: ProgramTemplateBlockBody): Promise<ProgramTemplateBlock> => {
  return appApi
    .post("/work_program_block/", body)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};

export const editProgramTemplateBlock = (blockId: string, body: ProgramTemplateBlockBody): Promise<ProgramTemplateBlock> => {
  return appApi
    .put(`/work_program_block/${blockId}`, body)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};