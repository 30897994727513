import {useNavigate} from "react-router-dom";
import {getSignUpUrl} from "services/navigation";
import {AppButton} from "shared/UI/UIKit/Button";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {useAuth} from "services/auth";
import {PasswordRecovery} from "common/PasswordRecovery";
import styles from "./Authorization.module.scss";

const SIGN_UP_URL = getSignUpUrl();

export const Authorization = () => {
  const {login} = useAuth();
  const navigate = useNavigate();

  const toSignUp = () => {
    navigate(SIGN_UP_URL);
  };

  return (
    <AppFlex className={styles.container} direction="row" unit="40" alignItems="center" justifyContent="center">
      <AppFlex direction="row">
        <AppFlex unit="10">
          <AppButton onClick={login}>
            Войти
          </AppButton>
          <PasswordRecovery/>
        </AppFlex>
        <AppFlex>
          <AppButton onClick={toSignUp}>
            Зарегистрироваться
          </AppButton>
        </AppFlex>
      </AppFlex>
    </AppFlex>
  );
};
