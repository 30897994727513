import type {ActionReducerMapBuilder} from "@reduxjs/toolkit";
import type {TemplatesState} from "./types";
import {
  copyTemplate,
  createProgramFromTemplateAction,
  createProgramTemplateAction,
  editProgramTemplateAction,
  getProgramTemplatesAction,
  removeProgramTemplateAction
} from "./actions";

export function getTemplatesReducerBuilder(builder: ActionReducerMapBuilder<TemplatesState>) {
  builder.addCase(getProgramTemplatesAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getProgramTemplatesAction.fulfilled, (state, action) => {
    state.loading = false;
    state.templates = action.payload.data;
    state.total = action.payload.total;
  });
  builder.addCase(getProgramTemplatesAction.rejected, (state) => {
    state.loading = false;
  });
}

export function createProgramTemplateReducerBuilder(builder: ActionReducerMapBuilder<TemplatesState>) {
  builder.addCase(createProgramTemplateAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(createProgramTemplateAction.fulfilled, (state) => {
    state.loading = false;
    state.total = state.total + 1;
  });
  builder.addCase(createProgramTemplateAction.rejected, (state) => {
    state.loading = false;
  });
}

export function createProgramFromTemplateReducerBuilder(builder: ActionReducerMapBuilder<TemplatesState>) {
  builder.addCase(createProgramFromTemplateAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(createProgramFromTemplateAction.fulfilled, (state, action) => {
    state.loading = false;
    state.templates.push(action.payload);
  });
  builder.addCase(createProgramFromTemplateAction.rejected, (state) => {
    state.loading = false;
  });
}

export function copyTemplateReducerBuilder(builder: ActionReducerMapBuilder<TemplatesState>) {
  builder.addCase(copyTemplate.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(copyTemplate.fulfilled, (state, action) => {
    state.loading = false;
    state.templates.push(action.payload);
  });
  builder.addCase(copyTemplate.rejected, (state) => {
    state.loading = false;
  });
}

export function removeTemplateReducerBuilder(builder: ActionReducerMapBuilder<TemplatesState>) {
  builder.addCase(removeProgramTemplateAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(removeProgramTemplateAction.fulfilled, (state, action) => {
    state.loading = false;
    state.templates = state.templates.filter(template => template.id !== action.payload);
    state.total = state.total - 1;
  });
  builder.addCase(removeProgramTemplateAction.rejected, (state) => {
    state.loading = false;
  });
}

export function editTemplateReducerBuilder(builder: ActionReducerMapBuilder<TemplatesState>) {
  builder.addCase(editProgramTemplateAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(editProgramTemplateAction.fulfilled, (state, action) => {
    state.loading = false;
    state.templates = state.templates.map(template => {
      return template.id === action.payload.id ? action.payload : template;
    });
  });
  builder.addCase(editProgramTemplateAction.rejected, (state) => {
    state.loading = false;
  });
}