import {useCallback} from "react";
import {Upload} from "antd";
import type {AppFileDraggerProps} from "./FileDragger.types";
import {useUploaderOnChange} from "../../hooks/use-uploader-on-change";

export const AppFileDragger = (props: AppFileDraggerProps) => {
  const beforeUpload = useCallback(props.beforeUpload || (() => false), []);
  const onChange = useUploaderOnChange(props.onChange);

  return (
    <Upload.Dragger fileList={[]}
                    beforeUpload={beforeUpload}
                    {...props}
                    onChange={onChange}
    />
  );
};