import {useEffect, useState} from "react";
import {notification} from "antd";
import {AppModal} from "shared/UI/UIKit/Modal";
import {getUserProfile, passwordRecovery, sendVerificationEmail} from "services/api";
import type {ViewUserModalProps} from "./types";
import type {UserProfile} from "services/api";
import {ViewUserForm} from "./form";

export const ViewUserModal = (props: ViewUserModalProps) => {
  const [user, setUser] = useState<UserProfile | null>(null);
  const [isUserFetching, setUserFetching] = useState<boolean>(false);
  const [isVerificationEmailSending, setVerificationEmailSending] = useState<boolean>(false);
  const [isPasswordRecoveryEmailSending, setPasswordRecoveryEmailSending] = useState<boolean>(false);

  const handleEmailVerification = async () => {
    if (!user) {
      return;
    }

    if (!user.email) {
      notification.error({message: "Отсутствует адрес электронной почты"});
      return;
    }

    if (!user.emailVerificationToken) {
      notification.error({message: "Отсутствует токен подтверждения электронной почты"});
      return;
    }

    if (user.emailConfirmed) {
      notification.error({message: "Адрес электронной почты уже подтвержден"});
      return;
    }

    try {
      setVerificationEmailSending(true);
      await sendVerificationEmail(user.emailVerificationToken, false);
      notification.success({message: "Письмо для активации отправлено"});
    }
    catch {
      notification.error({message: "Не удалось отправить письмо для активации"});
    }
    finally {
      setVerificationEmailSending(false);
    }
  };

  const handlePasswordRecovery = async () => {
    if (!user) {
      return;
    }

    if (!user.email) {
      notification.error({message: "Отсутствует адрес электронной почты"});
      return;
    }

    if (!user.emailConfirmed) {
      notification.error({message: "Адрес электронной почты не подтвержден"});
      return;
    }

    try {
      setPasswordRecoveryEmailSending(true);
      await passwordRecovery(user.email, false);
      notification.success({message: "Письмо для восстановления пароля отправлено"});
    }
    catch {
      notification.error({message: "Не удалось отправить письмо для восстановления пароля"});
    }
    finally {
      setPasswordRecoveryEmailSending(false);
    }
  };

  const handleCancel = () => {
    props.onHide();
  };

  const fetchUser = async (id: number) => {
    try {
      setUserFetching(true);
      const userProfile = await getUserProfile(id);
      setUser(userProfile);
    }
    catch {
      handleCancel();
    }
    finally {
      setUserFetching(false);
    }
  };

  useEffect(() => {
    if (props.visible && props.userId) {
      fetchUser(props.userId);
    }
  }, [props.visible, props.userId]);

  return (
    <AppModal visible={props.visible} onCancel={handleCancel}
              loading={isUserFetching} loadingOpacity="max">
      <ViewUserForm user={user}
                    emailVerificationBtnLoading={isVerificationEmailSending}
                    passwordRecoveryBtnLoading={isPasswordRecoveryEmailSending}
                    onEmailVerify={handleEmailVerification}
                    onPasswordRecover={handlePasswordRecovery}
                    onCancel={handleCancel}/>
    </AppModal>
  );
};