import {useForm} from "antd/lib/form/Form";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {AppModal} from "shared/UI/UIKit/Modal";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {createProgramTemplateAction} from "features/templates/redux/actions";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {getTemplateBlockUrl} from "services/navigation";
import {getSubjectsList, ProgramTemplateStatus} from "services/api";
import type {Subject} from "services/api";
import type {TemplateFormValues, TemplateModalProps} from "../types";
import {TemplateModalFooter} from "../ModalFooter/ModalFooter";
import {TemplateForm} from "../../forms/Template/TemplateForm";
import {educationLevels} from "../../constants";

export const CreateTemplateModal = (props: TemplateModalProps) => {
  const [form] = useForm<TemplateFormValues>();
  const {loading} = useAppSelector(state => state.templatesState);
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [subjectsLoading, setSubjectsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.visible) {
      setSubjectsLoading(true);
      getSubjectsList().then(res => {
        setSubjects(res);
        setSubjectsLoading(false);
      });
    }
  }, [props.visible]);

  const onSubmit = async() => {
    const values = await form.validateFields();
    const template = await dispatch(createProgramTemplateAction({
      ...values,
      isProgram: false,
      programName: "",
      status: ProgramTemplateStatus.IN_PROGRESS,
      blocks: [],
      subject: subjects.find(subject => subject.id.toString() === values.subjectId)!
    })).unwrap();

    hideModal();
    navigate(getTemplateBlockUrl({templateId: template.id.toString()}));
  };

  const hideModal = () => {
    props.hide();
    form.resetFields();
  };

  const footer = <TemplateModalFooter onSubmit={onSubmit}
                                      hideModal={hideModal}
                                      title="Создать"/>;
  const content = <TemplateForm form={form} educationLevels={educationLevels}
                                subjects={subjects}/>;

  return (
    <AppModal visible={props.visible} onCancel={hideModal}
              content={content} destroyOnClose footer={footer}
              loading={loading || subjectsLoading} title="Создать шаблон"/>
  );
};
