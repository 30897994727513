import {AppFlex} from "shared/UI/UIKit/Flex";

export const PasswordCreationRequirements = () => {
  return (
    <AppFlex unit="4">
      <div>Пароль должен содержать минимум:</div>
      <div>- 8 символов</div>
      <div>- одну цифру</div>
      <div>- одну заглавную латинскую букву</div>
      <div>- одну строчную латинскую букву</div>
      <div>- один спецсимвол</div>
    </AppFlex>
  );
};