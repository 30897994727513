import {AppForm, AppFormItem, formRules} from "shared/UI/UIKit/Form";
import {AppInput} from "shared/UI/UIKit/Input";
import {AppSelect} from "shared/UI/UIKit/Select";
import type {OrganizationFormProps} from "./types";

export const OrganizationForm = ({form, municipalityVisible, municipalityOptions}: OrganizationFormProps) => {
  return (
    <AppForm form={form}>
      <AppFormItem label="Название организации" labelColor="gray" size="max"
                   name="name" rules={formRules.required}>
        <AppInput placeholder="Название организации"/>
      </AppFormItem>
      <AppFormItem label="Полное название организации" labelColor="gray" size="max"
                   name="fullName" rules={formRules.required}>
        <AppInput placeholder="Полное название организации"/>
      </AppFormItem>
      {municipalityVisible && (
        <AppFormItem label="Район" labelColor="gray" size="max"
                     name={["municipality", "id"]} rules={formRules.required}>
          <AppSelect options={municipalityOptions || []} placeholder="Район"/>
        </AppFormItem>
      )}
    </AppForm>
  );
};