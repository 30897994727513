import {useLayoutEffect, useState} from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {loadCaptchaEnginge, validateCaptcha as validate} from "react-simple-captcha";

const NUMBER_OF_CHARACTERS = 8;
const BACKGROUND_COLOR = "#E1E2E6"; // gray-3

export const useCaptcha = (
  numberOfCharacters = NUMBER_OF_CHARACTERS,
  backgroundColor = BACKGROUND_COLOR,
) => {
  const [captchaValue, setCaptchaValue] = useState<string | undefined>();
  const [captchaErrorMsg, setCaptchaErrorMsg] = useState<string | undefined>();

  const handleCaptchaValueChange = (value: string | undefined) => {
    setCaptchaValue(value);
    setCaptchaErrorMsg("");
  };

  const handleCaptchaValueValidate = (reload = false): boolean => {
    if (!captchaValue) {
      setCaptchaErrorMsg("Введите значение, указанное на изображении");
      return false;
    }

    if (!validate(captchaValue, reload)) {
      setCaptchaErrorMsg("Введенное значение не совпадает со значением на изображении");
      return false;
    }

    return true;
  };

  useLayoutEffect(() => {
    loadCaptchaEnginge(numberOfCharacters, backgroundColor);
  }, []);

  return [
    {captchaValue, captchaErrorMsg},
    {
      onCaptchaValueChange: handleCaptchaValueChange,
      validateCaptcha: handleCaptchaValueValidate,
    },
  ] as const;
};