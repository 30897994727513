import {useState} from "react";
import {AppModal} from "shared/UI/UIKit/Modal";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppText} from "shared/UI/UIKit/Text";
import {removeMunicipality} from "services/api";
import type {RemoveMunicipalityModalProps} from "./types";
import {MunicipalityModalFooter} from "../ModalFooter";
import styles from "./Remove.module.scss";

export const RemoveMunicipalityModal = (props: RemoveMunicipalityModalProps) => {
  const [loading, setLoading] = useState(false);

  const confirm = () => {
    setLoading(true);
    removeMunicipality(props.id!)
      .then(() => {
        props.onItemRemove(props.id!);
        props.hide();
      }).finally(() => setLoading(false));
  };

  const footer = <MunicipalityModalFooter onSubmit={confirm}
                                          hideModal={props.hide}
                                          title="Удалить"/>;

  const content = (
    <AppFlex unit="8" className={styles.content}>
      <AppText size={16} type="secondary">
        Подтвердите удаление района
      </AppText>
      <AppText size={18}>
        {props.municipalities.find(item => Number(item.id) === Number(props.id))?.name || ""}
      </AppText>
    </AppFlex>
  );

  return (
    <AppModal visible={props.visible} onCancel={props.hide} size="auto"
              content={content} destroyOnClose footer={footer} loading={loading}
              title="Удаление района"/>
  );
};