import {AppTableControls} from "common/TableControls/TableControls";
import {useUserProfileRole} from "common/use-user-profile-role";
import type {AppTableColumn} from "shared/UI/UIKit/Table";
import type {Dictionary} from "services/api";

export const getDictionariesTableColumns = (
  edit: (id: number) => void,
  remove: (id: number) => void
): AppTableColumn<Dictionary>[] => {
  const {isAdmin} = useUserProfileRole();

  const columns: AppTableColumn<Dictionary>[] = [
    {
      key: "id",
      title: "ID",
      dataIndex: ["id"],
      maxWidth: "10rem"
    },
    {
      key: "name",
      title: "Наименование",
      dataIndex: ["name"],
    },
    {
      key: "code",
      title: "Мнемокод",
      dataIndex: ["code"],
    },
    {
      key: "count",
      title: "Количество элементов",
      dataIndex: [""],
      maxWidth: "10rem",
      render: (_: any, dictionary: Dictionary) => {
        return (
          <span>{dictionary.items.length}</span>
        );
      }
    }
  ];

  if (isAdmin) {
    columns.push({
      key: "controls",
      title: "",
      dataIndex: [],
      maxWidth: "10rem",
      render: (value: any, record: Dictionary) => {
        const editDictionary = () => edit(record.id);
        const removeDictionary = () => remove(record.id);

        return (
          <AppTableControls edit={editDictionary} remove={removeDictionary}/>
        );
      }
    });
  }

  return columns;
};