import {useCallback} from "react";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {blockingLoadingActions} from "../redux";

type ReturnValue = {
  isBlockingLoading: boolean,
  setBlockingLoading: (value: boolean) => void,
  setBlockingLoadingAsync: (value: boolean) => Promise<boolean>,
};

export const useBlockingLoading = (): ReturnValue => {
  const {isBlockingLoading} = useAppSelector(state => state.blockingLoadingState);
  const dispatch = useAppDispatch();

  const setBlockingLoading = useCallback((value: boolean) => {
    dispatch(blockingLoadingActions.setBlockingLoading(value));
  }, []);

  const setBlockingLoadingAsync = useCallback((value: boolean) => {
    return dispatch(blockingLoadingActions.setBlockingLoadingAsync(value)).unwrap();
  }, []);

  return {isBlockingLoading, setBlockingLoading, setBlockingLoadingAsync};
};