import type {ProgramTemplateBlock, ProgramTemplateBlockField} from "services/api";

const removedStyles: string[] = [
  "box-sizing: border-box;",
  "font-family: Arial;",
  "-webkit-user-drag: none;",
  "overflow: visible;",
  "font-style: normal;",
  "vertical-align: baseline;",
  "font-variant-ligatures: normal;",
  "font-variant-caps: normal;",
  "letter-spacing: normal;",
  "orphans: 2;",
  "text-indent: 0px;",
  "text-transform: none;",
  "white-space: normal;",
  "widows: 2;",
  "word-spacing: 0px;",
  "-webkit-text-stroke-width: 0px;",
  "text-decoration-thickness: initial;",
  "text-decoration-style: initial;",
  "text-decoration-color: initial;"
];

export const getFilteredContentStyles = (content: string) => {
  removedStyles.forEach(item => {
    content = content!.replaceAll(item, "");
  });

  return content;
};

export const getFilteredFields = (block: ProgramTemplateBlock): ProgramTemplateBlockField[] => {
  const node = new DOMParser().parseFromString(block.content, "text/html");
  const placeholders = node.querySelectorAll("[data-widget=\"true\"]");

  const filteredPlaceholders: ProgramTemplateBlockField[] = [];
  placeholders.forEach(placeholder => {
    const placeholderId = placeholder.getAttribute("id");
    const existingPlaceholder = block.fields.find(field => field.placeholderId === placeholderId);
    if (existingPlaceholder) {
      filteredPlaceholders.push({
        ...existingPlaceholder,
        id: getPlaceholderId(existingPlaceholder),
      });
    }
  });

  return filteredPlaceholders;
};

export const getContentWithCleanKatexNotation = (content: string): string => {
  const node = new DOMParser().parseFromString(content, "text/html");
  const katexNodes = node.getElementsByClassName("__se__katex katex");
  if (!katexNodes.length) {
    return content;
  } else {
    for (const katexNode of Array.from(katexNodes)) {
      const dataExpAttrCleanedValue = katexNode.getAttribute("data-exp")?.replaceAll("\n", " ") || "";
      katexNode.setAttribute("data-exp", dataExpAttrCleanedValue);
    }
    return node.body.innerHTML;
  }
};

const getPlaceholderId = (existingPlaceholder: ProgramTemplateBlockField) => {
  if (!existingPlaceholder.id || existingPlaceholder.id.toString().includes("field_")) {
    return null;
  } else {
    return existingPlaceholder.id;
  }
};