import {AppFlex} from "shared/UI/UIKit/Flex";
import type {GridColumnProps} from "./Column.props";
import {getClassNames} from "./Column.utils";

export const AppGridColumn = (props: GridColumnProps) => {
  const classNames = getClassNames(props);
  return (
    <AppFlex className={classNames} unit={props.unit}>
      {props.children}
    </AppFlex>
  );
};