import {reducer, actions} from "./slice";
import * as thunks from "./actions";

const blockingLoadingActions = {
  ...actions,
  ...thunks,
};

export {
  reducer as blockingLoadingReducer,
  blockingLoadingActions,
};