import type {AppSelectOption} from "shared/UI/UIKit/Select";

export function toOptions<T extends Record<string, any>>(
  items: T[],
  valueKey: keyof T,
  labelKey: keyof T,
): AppSelectOption[] {
  return items.map(item => {
    return {
      label: <unknown>item[labelKey] as string,
      value: <unknown>item[valueKey].toString() as string,
    };
  });
}