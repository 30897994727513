import {useState} from "react";
import {AppModal} from "shared/UI/UIKit/Modal";
import {AppForm, AppFormItem, formRules, useAppForm, valueEqualityValidator} from "shared/UI/UIKit/Form";
import {AppInputPassword} from "shared/UI/UIKit/Input";
import {AppButton} from "shared/UI/UIKit/Button";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {changePassword} from "services/api";
import {passwordCreationRequirementsTooltipConfig} from "common/tooltips/PasswordCreationRequirements";
import type {ChangePasswordModalProps, ChangePasswordFormValues} from "./types";
import {changePasswordFormNames} from "./constants";

const newPasswordConfirmationRules = [
  ...formRules.required,
  valueEqualityValidator(changePasswordFormNames.newPassword, "Пароли не совпадают"),
];

export const ChangePasswordModal = (props: ChangePasswordModalProps) => {
  const [form] = useAppForm<ChangePasswordFormValues>();
  const [loading, setLoading] = useState(false);

  const onHide = () => {
    form.resetFields();
    props.onHide();
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      await changePassword(values.newPassword.trim());
      setLoading(false);
      onHide();
    } catch {
      setLoading(false);
    }
  };

  const content = (
    <AppForm form={form}>
      <AppFormItem label="Новый пароль" labelColor="gray"
                   tooltip={passwordCreationRequirementsTooltipConfig}
                   rules={formRules.requiredPassword}
                   name={changePasswordFormNames.newPassword}>
        <AppInputPassword placeholder="Введите новый пароль"/>
      </AppFormItem>
      <AppFormItem label="Подтверждение нового пароля" labelColor="gray"
                   rules={newPasswordConfirmationRules}
                   name={changePasswordFormNames.newPasswordConfirmation}>
        <AppInputPassword placeholder="Введите новый пароль"/>
      </AppFormItem>
    </AppForm>
  );

  const footer = (
    <AppFlex direction="row">
      <AppButton onClick={onSubmit}>
        Сохранить
      </AppButton>
      <AppButton type="outline" onClick={onHide}>
        Отмена
      </AppButton>
    </AppFlex>
  );

  return (
    <AppModal content={content} footer={footer}
              visible={props.visible} onCancel={onHide}
              destroyOnClose loading={loading}
              title="Изменение пароля"/>
  );
};