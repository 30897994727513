import type {AppIconProps} from "../Icons.types";
import {getClassNames} from "../Icons.utils";
import styles from "./Education.module.scss";

export const AppEducationIcon = (props: AppIconProps) => {
  const {className, ...rest} = props;
  const classNames = getClassNames(styles.icon, className);

  return (
    <svg {...rest} className={classNames} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 19" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.75391 8.17859L12.1616 12L19.4559 8.23759L19.4564 15.0853C17.0061 16.6417 14.5558 17.4198 12.1055 17.4198C9.65525 17.4198 7.20496 16.6417 4.75468 15.0853L4.75391 8.17859Z" stroke="#737781" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.1615 1L22.8231 6.5L12.1615 12L1.5 6.5L12.1615 1Z" stroke="#737781" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M22.8398 6.90479V12.9548" stroke="#737781" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};