import {AppPage} from "common/Layout";
import {Organizations} from "features/organizations";
import {homeBreadcrumbItem} from "shared/UI/UIKit/Breadcrumbs";

const breadcrumbs = [
  homeBreadcrumbItem,
  {
    title: "Организации",
    url: "",
  },
];

export const OrganizationsPage = () => {
  return (
    <AppPage breadcrumbs={{
      items: breadcrumbs
    }} documentTitle="Организации">
      <Organizations/>
    </AppPage>
  );
};