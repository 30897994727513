import {useNavigate} from "react-router-dom";
import {getTemplateBlockUrl, getWorkProgramBlockUrl} from "services/navigation";
import type {ProgramTemplate} from "services/api";
import type {TemplatesTableProps} from "./types";
import type {AppTableProps} from "shared/UI/UIKit/Table";

export const useTemplatesTable = (props: TemplatesTableProps) => {
  const navigate = useNavigate();

  const onRow: AppTableProps<ProgramTemplate>["onRow"] = (data) => {
    return {
      onClick: () => {
        if (props.isPrograms) {
          navigate(getWorkProgramBlockUrl({templateId: data.id.toString()}));
        } else {
          navigate(getTemplateBlockUrl({
            templateId: data.id.toString(),
          }));
        }
      },
    };
  };

  return {
    onRow,
  };
};