import {AppPage} from "common/Layout";
import {homeBreadcrumbItem} from "shared/UI/UIKit/Breadcrumbs";
import {Municipalities} from "features/municipality";

const breadcrumbs = [
  homeBreadcrumbItem,
  {
    title: "Районы",
    url: "",
  },
];

export const MunicipalitiesPage = () => {
  return (
    <AppPage breadcrumbs={{
      items: breadcrumbs
    }} documentTitle="Районы">
      <Municipalities/>
    </AppPage>
  );
};