export const appPagePaths = {
  root: "/",
  home: "/",
  logout: "/logout",
  signUp: "/sign-up",
  authorization: "/authorization",
  emailConfirm: "/email-confirm",
  emailApproval: "/email-approval",
  templates: "/templates",
  templateBlock: "/templates/:templateId",
  workPrograms: "/work-programs",
  workProgramBlock: "/work-programs/:templateId",
  dictionaries: "/dictionaries",
  dictionary: "/dictionaries/:dictionaryId",
  personalData: "/personal-data",
  organizations: "/organizations",
  municipalities: "/municipalities",
  regions: "/regions",
  users: "/users"
};

export const appRouterPaths = {
  root: "/",
  home: "/",
  logout: "/logout",
  signUp: "/sign-up",
  authorization: "/authorization",
  emailConfirm: "/email-confirm",
  emailApproval: "/email-approval",
  templates: "/templates",
  templateBlock: "/templates/:templateId",
  workPrograms: "/work-programs",
  workProgramBlock: "/work-programs/:templateId",
  dictionaries: "/dictionaries",
  dictionary: "/dictionaries/:dictionaryId",
  personalData: "/personal-data",
  organizations: "/organizations",
  municipalities: "/municipalities",
  regions: "/regions",
  users: "/users"
};


