import type {AppMenuItem} from "../../../ui/Menu.types";
import type {LinkMenuItemProps} from "../../components/LinkMenuItem";
import {LinkMenuItem} from "../../components/LinkMenuItem";

export type LinkMenuItemOptions = {
  key: LinkMenuItemProps["id"];
  label: LinkMenuItemProps["label"];
  className?: string;
  wrapperClassName?: string;
  icon?: LinkMenuItemProps["icon"];
  disabled?: LinkMenuItemProps["disabled"];
  shouldCloseMenu?: LinkMenuItemProps["shouldCloseMenu"];
  onClick?: LinkMenuItemProps["onClick"];
  ellipsis?: LinkMenuItemProps["ellipsis"];
  linkProps: LinkMenuItemProps["linkProps"];
}

export const getLinkMenuItem = ({
                                  key,
                                  label,
                                  className,
                                  icon,
                                  disabled,
                                  shouldCloseMenu = false,
                                  onClick,
                                  ellipsis = {tooltip: label},
                                  wrapperClassName,
                                  linkProps,
                                }: LinkMenuItemOptions): AppMenuItem => {

  const getLabel = () => {
    return (
      <LinkMenuItem id={key}
                    className={className}
                    label={label}
                    icon={icon}
                    disabled={disabled}
                    onClick={onClick}
                    shouldCloseMenu={shouldCloseMenu}
                    ellipsis={ellipsis}
                    linkProps={linkProps}/>
    );
  };

  return {
    key,
    label: getLabel(),
    className: wrapperClassName,
    disabled,
  };
};