import {combineReducers} from "@reduxjs/toolkit";
import {templatesReducer} from "features/templates";
import {templateBlockReducer} from "features/template-block/redux";
import {dictionariesReducer} from "features/dictionaries";
import {dictionaryReducer} from "features/dictionary";
import {blockingLoadingReducer} from "common/blocking-loading";
import {profileReducer} from "./profile";

export const reducer = combineReducers({
  templatesState: templatesReducer,
  templateBlockState: templateBlockReducer,
  profileState: profileReducer,
  dictionariesState: dictionariesReducer,
  dictionaryState: dictionaryReducer,
  blockingLoadingState: blockingLoadingReducer,
});
