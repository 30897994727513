import {Form} from "antd";
import {useEffect} from "react";
import {AppButton} from "shared/UI/UIKit/Button";
import {AppCheckbox} from "shared/UI/UIKit/Checkbox";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppForm, AppFormItem, formRules} from "shared/UI/UIKit/Form";
import {AppBlockingLoader} from "shared/UI/UIKit/Loader";
import {AppInput} from "shared/UI/UIKit/Input";
import {AppSelect} from "shared/UI/UIKit/Select";
import {usePersonalDataLocation} from "common/use-personal-data-location";
import type {PersonalDataFormProps, PersonalDataFormValues} from "./types";
import {PersonalDataAvatar} from "../PersonalDataAvatar";
import {personalDataFormNames} from "./constants";
import styles from "./PersonalDataForm.module.scss";

export const PersonalDataForm = ({
  loading,
  initialValues,
  onSubmit,
  onChangePasswordClick,
}: PersonalDataFormProps) => {
  const [form] = Form.useForm<PersonalDataFormValues>();
  const {
    locationLoading,
    regionsOptions,
    onRegionChange,
    onMunicipalityChange,
    municipalitiesOptions,
    organizationsOptions,
    regionId,
    municipalityId,
  } = usePersonalDataLocation(form);

  useEffect(() => {
    onRegionChange(initialValues.region?.id.toString());
    onMunicipalityChange(initialValues.municipality?.id.toString());
    form.setFieldsValue({
      ...initialValues!,
      region: {
        id: initialValues.region?.id.toString(),
      },
      municipality: {
        id: initialValues.municipality?.id.toString(),
      },
      organization: {
        id: initialValues.organization?.id.toString(),
      },
    });
  }, []);

  const handleFormSubmit = () => {
    form.validateFields()
      .then(onSubmit)
      .catch(() => null);
  };

  return (
    <AppBlockingLoader loading={!!loading || locationLoading} className={styles.blocker}>
      <AppForm className={styles.form} form={form}>
        <AppFlex direction="row" unit="50">
          <AppFlex direction="column">
            <AppFormItem noStyle name={personalDataFormNames.avatar}>
              <PersonalDataAvatar/>
            </AppFormItem>
            <AppButton type="outline" onClick={onChangePasswordClick}>
              Изменить пароль
            </AppButton>
          </AppFlex>

          <AppFlex direction="column">
            <AppFormItem rules={formRules.required} label="Фамилия" labelColor="gray" size="xxl" name={personalDataFormNames.lastName}>
              <AppInput placeholder="Введите Фамилия"/>
            </AppFormItem>

            <AppFormItem rules={formRules.required} label="Имя" labelColor="gray" size="xxl" name={personalDataFormNames.firstName}>
              <AppInput placeholder="Введите Имя"/>
            </AppFormItem>

            <AppFormItem rules={formRules.required} label="Отчество" labelColor="gray" size="xxl" name={personalDataFormNames.middleName}>
              <AppInput placeholder="Введите Отчество"/>
            </AppFormItem>

            <AppFormItem rules={formRules.required} label="Электронная почта" labelColor="gray" size="xxl" name={personalDataFormNames.email}>
              <AppInput placeholder="Введите электронную почту" disabled/>
            </AppFormItem>

            <AppFormItem rules={formRules.required} label="Регион" labelColor="gray" size="xxl" name={personalDataFormNames.region}>
              <AppSelect options={regionsOptions} placeholder="Выберите регион"
                         onChange={onRegionChange}/>
            </AppFormItem>

            <AppFormItem rules={formRules.required} label="Район" labelColor="gray" size="xxl" name={personalDataFormNames.municipality}>
              <AppSelect options={municipalitiesOptions} placeholder="Выберите Район"
                         disabled={!regionId} onChange={onMunicipalityChange}/>
            </AppFormItem>

            <AppFormItem rules={formRules.required} label="Организация" labelColor="gray" size="xxl" name={personalDataFormNames.organization}>
              <AppSelect options={organizationsOptions} placeholder="Выберите Организация"
                         disabled={!municipalityId}/>
            </AppFormItem>

            <AppFormItem rules={formRules.requiredBoolean} valuePropName="checked" name={personalDataFormNames.processingOfPersonalData}>
              <AppCheckbox disabled>Согласие на обработку персональных данных</AppCheckbox>
            </AppFormItem>

            <AppFlex direction="row">
              <AppButton onClick={handleFormSubmit}>
                Сохранить
              </AppButton>
            </AppFlex>
          </AppFlex>
        </AppFlex>
      </AppForm>
    </AppBlockingLoader>
  );
};
