import type {RuleObject} from "rc-field-form/lib/interface";

const codeValid: RuleObject = {
  validator: (rule: RuleObject, value: string | undefined) => {
    if (!value) {
      return Promise.reject("Обязательное поле");
    }

    if (/^[a-zA-Z0-9!?@#$%^&*()_+\-=[\]{};:'",.<>\\|/]*$/.test(value)) {
      return Promise.resolve();
    }

    return Promise.reject("Введен недопустимый символ");
  },
  required: true,
};

export const dictionaryFormRules = {
  codeValid,
};