import {AppFlex} from "shared/UI/UIKit/Flex";
import {usePersonalDataLocation} from "common/use-personal-data-location";
import {useAppForm} from "shared/UI/UIKit/Form";
import {useModal, usePayloadedModal} from "shared/UI/UIKit/Modal";
import type {UserProfileMunicipality} from "services/api";
import {MunicipalitiesTable} from "./Table/MunicipalitiesTable";
import {RemoveMunicipalityModal} from "./Modals/Remove/Remove";
import {EditMunicipalityModal} from "./Modals/Edit";
import {LocationForm} from "./Form/LocationForm";
import {CreateMunicipalityModal} from "./Modals/Create";

type ModalPayload = {
  id: number;
}

export const Municipalities = () => {
  const [form] = useAppForm();
  const createModal = useModal();
  const editModal = usePayloadedModal<ModalPayload>();
  const removeModal = usePayloadedModal<ModalPayload>();

  const {
    regionsOptions,
    onRegionChange,
    regionId,
    locationLoading,
    setMunicipalities,
    municipalities,
  } = usePersonalDataLocation(form);

  const addItem = (newItem: UserProfileMunicipality) => {
    setMunicipalities([newItem, ...municipalities]);
  };
  const removeItem = (id: number) => {
    setMunicipalities(municipalities.filter(item => item.id !== id));
  };
  const setItem = (newItem: UserProfileMunicipality) => {
    setMunicipalities(municipalities.map(item => item.id === newItem.id ? newItem : item));
  };

  const showEditModal = (id: number) => editModal.methods.show({id});
  const showCreateModal = () => createModal.methods.show();
  const showRemoveModal = (id: number) => removeModal.methods.show({id});

  return (
    <AppFlex>
      <LocationForm form={form}
                    regionsOptions={regionsOptions}
                    onRegionChange={onRegionChange}
                    regionId={regionId}/>
      <MunicipalitiesTable dataSource={municipalities}
                           edit={showEditModal}
                           remove={showRemoveModal}
                           create={showCreateModal}
                           loading={locationLoading}
                           visible={!!regionId}/>
      <CreateMunicipalityModal visible={createModal.state.visible}
                               hide={createModal.methods.hide}
                               regionId={regionId}
                               addItem={addItem}/>
      <RemoveMunicipalityModal visible={removeModal.state.visible}
                               hide={removeModal.methods.hide}
                               id={removeModal.state.payload?.id}
                               onItemRemove={removeItem}
                               municipalities={municipalities}/>
      <EditMunicipalityModal visible={editModal.state.visible}
                             hide={editModal.methods.hide}
                             municipalities={municipalities}
                             id={editModal.state.payload?.id}
                             setItem={setItem}/>

    </AppFlex>
  );
};