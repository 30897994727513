import {AppFlex} from "shared/UI/UIKit/Flex";
import {RemoveTemplateModal} from "features/templates/components/Modals/Remove/Remove";
import {AppSider} from "shared/UI/UIKit/Sider";
import {TableFilter} from "common/TableFilter";
import type {TemplatesProps} from "./types";
import {CreateTemplateModal} from "../Modals/Create/Create";
import {EditTemplateModal} from "../Modals/Edit/Edit";
import {TemplatesTable} from "../Table/TemplatesTable";
import {TemplatesTableControls} from "../Controls/Controls";
import {CreateProgramModal} from "../Modals/CreateProgram/CreateProgram";
import {useTemplates} from "./use-templates";

export const Templates = (props: TemplatesProps) => {
  const {state, methods} = useTemplates(props);

  return (
    <AppFlex>
      <TemplatesTableControls createTemplate={methods.createTemplate}
                              createProgram={methods.createProgram}
                              isPrograms={props.isPrograms}
                              showSider={methods.showSider}
                              columns={state.columns}
                              copyTemplate={methods.copyTemplate}
                              visibleKeys={state.visibleKeys}
                              filterCount={state.filterCount}
                              setVisibleKeys={methods.setVisibleKeys}/>
      <TemplatesTable isPrograms={props.isPrograms}
                      dataSource={state.templates}
                      loading={state.loading}
                      columns={state.filteredColumns}
                      tableState={state.tableState}
                      onChange={methods.onTableChange}/>
      {
        !props.isPrograms && (
          <CreateTemplateModal visible={state.createModalVisible}
                               hide={() => methods.setCreateModalVisible(false)}/>
        )
      }
      <CreateProgramModal visible={state.createWorkProgramModalVisible} isPrograms={props.isPrograms}
                          hide={() => methods.setCreateWorkProgramModalVisible(false)}/>
      <EditTemplateModal visible={state.editModalVisible} isPrograms={props.isPrograms}
                         hide={() => methods.setEditModalVisible(false)}/>
      <RemoveTemplateModal visible={state.removeModalVisible} isPrograms={props.isPrograms}
                           hide={() => methods.setRemoveModalVisible(false)}
                           onItemRemove={methods.onItemRemove}/>
      <AppSider title="Фильтры"
                isSiderVisible={state.siderVisible}
                submitBtnText="Применить"
                submitBtnCallback={methods.onSubmit}
                cancelBtnText="Сбросить всё"
                cancelBtnCallback={methods.onCancel}
                hideSider={methods.hideSider}>
        <TableFilter fields={state.filterFields}
                      submitBtnText="Применить"
                      resetBtnText="Сбросить всё"
                      onSubmit={methods.onFilterChange}
                      onClose={methods.hideSider}
                      onLoadOptions={methods.onLoadFilterOptions}
                      fieldsValue={state.filterState}/>
      </AppSider>
    </AppFlex>
  );
};