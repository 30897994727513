import {buildUrl} from "shared/helpers/utils/url-builder";
import type {
  TemplateBlockPageParams,
  WorkProgramBlockPageParams,
  DictionaryPageParams
} from "./types";
import {appPagePaths} from "./urls";

export const getHomeUrl = () => appPagePaths.home;
export const getLogoutUrl = () => appPagePaths.logout;
export const getSignUpUrl = () => appPagePaths.signUp;
export const getAuthorizationUrl = () => appPagePaths.authorization;
export const getEmailConfirmUrl = () => appPagePaths.emailConfirm;
export const getEmailApprovalUrl = () => appPagePaths.emailApproval;
export const getTemplatesUrl = () => appPagePaths.templates;
export const getTemplateBlockUrl = (params: TemplateBlockPageParams) => buildUrl(appPagePaths.templateBlock, params);
export const getWorkProgramsUrl = () => appPagePaths.workPrograms;
export const getWorkProgramBlockUrl = (params: WorkProgramBlockPageParams) => buildUrl(appPagePaths.workProgramBlock, params);
export const getDictionariesUrl = () => appPagePaths.dictionaries;
export const getDictionaryUrl = (params: DictionaryPageParams) => buildUrl(appPagePaths.dictionary, params);
export const getPersonalDataUrl = () => appPagePaths.personalData;
export const getOrganizationsUrl = () => appPagePaths.organizations;
export const getMunicipalitiesUrl = () => appPagePaths.municipalities;
export const getRegionsUrl = () => appPagePaths.regions;
export const getUsersUrl = () => appPagePaths.users;


