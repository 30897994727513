import {AppPage} from "common/Layout";
import {Dictionary} from "features/dictionary";
import {useDictionaryPage} from "./use-dictionary-page";

export const DictionaryPage = () => {
  const {breadcrumbsLoading, breadcrumbs, dictionary} = useDictionaryPage();

  return (
    <AppPage breadcrumbs={{
      items: breadcrumbs,
      loading: breadcrumbsLoading,
    }} documentTitle={dictionary?.name}>
      <Dictionary/>
    </AppPage>
  );
};

