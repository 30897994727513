import {Form} from "antd";
import {useEffect} from "react";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppForm, AppFormItem} from "shared/UI/UIKit/Form";
import {AppInput} from "shared/UI/UIKit/Input";
import {AppSelect} from "shared/UI/UIKit/Select";
import {AppButton} from "shared/UI/UIKit/Button";
import {filterObject} from "shared/helpers/utils/objects";
import type {TableFilterField, TableFilterProps} from "./types";
import {TableFilterFieldType} from "./types";
import styles from "./TableFilter.module.scss";

const filterValues = (key: string, value: any) => ![undefined, ""].includes(value);

export const TableFilter = (props: TableFilterProps) => {
  const {
    submitBtnText,
    resetBtnText,
    fields,
    fieldsValue,
    onSubmit,
    onLoadOptions,
    onClose
  } = props;

  const [form] = Form.useForm();

  const onFormSubmit = () => {
    form.validateFields()
      .then(values => {
        const filteredValues = filterObject(values, filterValues);
        onSubmit(filteredValues);
        onClose();
      });
  };

  const onReset = () => {
    form.resetFields();
    onFormSubmit();
  };

  const onSelectDropdownVisible = (field: TableFilterField, open: boolean) => {
    if (!open || !field.isLoadableOptions || !onLoadOptions || field.options?.length) {
      return;
    }

    onLoadOptions(field.name);
  };

  const getJSXField = (field: TableFilterField) => {
    switch (field.type) {
      case TableFilterFieldType.INPUT: {
        return <AppInput placeholder={`Введите ${field.label}`}/>;
      }

      case TableFilterFieldType.SELECT: {
        return (
          <AppSelect allowClear options={field.options || []} placeholder={`Выберите ${field.label}`} loading={field.loading}
                     onDropdownVisibleChange={(open) => {onSelectDropdownVisible(field, open);}}
          />
        );
      }
    }
  };

  useEffect(() => {
    if (fieldsValue) {
      form.setFieldsValue(fieldsValue);
    }
  }, [fieldsValue]);

  return (
    <AppFlex className={styles["table-filter"]}>
      <AppForm form={form} className={styles.form}>
        {fields.map((field) => {
          return (
            <AppFormItem key={field.key} label={field.label} labelColor="gray" size="xxl" name={field.name}>
              {getJSXField(field)}
            </AppFormItem>
          );
        })}
      </AppForm>

      <AppFlex direction="row" alignItems="center" justifyContent="space-between" className={styles.actions}>
        <AppButton onClick={onFormSubmit}>
          {submitBtnText}
        </AppButton>
        <AppButton onClick={onReset} type="outline">
          {resetBtnText}
        </AppButton>
      </AppFlex>
    </AppFlex>
  );
};