import {createSlice} from "@reduxjs/toolkit";
import type {ProfileState} from "./types";
import {getProfileBuilder, updateProfileBuilder} from "./reducers";

const initialState: ProfileState = {
  isProfileFetching: false,
  isProfileUpdating: false,
  profile: null,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getProfileBuilder(builder);
    updateProfileBuilder(builder);
  }
});

export const profileReducer = profileSlice.reducer;
export const profileActions = profileSlice.actions;