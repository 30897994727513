import {createAsyncThunk} from "@reduxjs/toolkit";
import {
  createDictionaryItem,
  deleteDictionaryItem, getDictionaryById,
  getDictionaryItemList,
  updateDictionaryItem
} from "services/api";
import type {DictionaryItemBody, DictionaryItemRequest} from "services/api";
import type {RootState} from "services/redux/types";

export const getDictionaryItemsAction = createAsyncThunk("dictionaryItems/getList", async(payload: DictionaryItemRequest) => {
  try {
    return await getDictionaryItemList(payload);
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const createDictionaryItemAction = createAsyncThunk("dictionaryItems/create", async(body: DictionaryItemBody) => {
  try {
    return await createDictionaryItem(body);
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const editDictionaryItemAction = createAsyncThunk("dictionaryItems/edit", async(body: DictionaryItemBody, thunkApi) => {
  const {dictionaryState: {selectedDictionaryItem}} = thunkApi.getState() as RootState;
  try {
    return await updateDictionaryItem(selectedDictionaryItem!.id.toString(), body);
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const removeDictionaryItemAction = createAsyncThunk("dictionaryItems/remove", async(dictionaryItemId: string) => {
  try {
    return await deleteDictionaryItem(dictionaryItemId);
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const getDictionaryAction = createAsyncThunk("dictionaryItems/getDictionary", async(dictionaryId: string) => {
  try {
    return await getDictionaryById(dictionaryId);
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});
