import {AppForm, AppFormItem} from "shared/UI/UIKit/Form";
import {AppSelect} from "shared/UI/UIKit/Select";
import {personalDataLocationFormNames} from "common/use-personal-data-location/constants";
import type {LocationFormProps} from "./types";

export const LocationForm = (props: LocationFormProps) => {
  return (
    <AppForm form={props.form}>
      <AppFormItem label="Регион" labelColor="gray" size="max"
                   name={personalDataLocationFormNames.region}>
        <AppSelect options={props.regionsOptions}
                   placeholder="Выберите регион" onChange={props.onRegionChange}/>
      </AppFormItem>
    </AppForm>
  );
};