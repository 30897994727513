import {useRef} from "react";
import cn from "classnames";
import {AppText} from "shared/UI/UIKit/Text";
import {AppCloseIcon} from "shared/UI/UIKit/icons/Close";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppButton} from "shared/UI/UIKit/Button";
import type {AppSiderProps} from "./Sider.types";
import styles from "./Sider.module.scss";

export const AppSider = (props: AppSiderProps) => {
  const {
    title,
    isSiderVisible,
    isFooterVisible,
    hideSider,
    submitBtnText,
    submitBtnCallback,
    cancelBtnText,
    cancelBtnCallback,
    children
  } = props;

  const siderRef = useRef<HTMLDivElement | null>(null);

  const onSubmit = () => {
    if (submitBtnCallback) {
      submitBtnCallback();
    }
    hideSider();
  };
  const onCancel = () => {
    if (cancelBtnCallback) {
      cancelBtnCallback();
    }
    hideSider();
  };

  return (
    <AppFlex className={styles.sider}>
      {isSiderVisible && (
        <div className={styles.mask} onClick={hideSider}></div>
      )}

      <AppFlex className={cn(styles["body-wrap"], !isSiderVisible && styles.hidden)}
             unit="0" ref={siderRef}>
        <AppFlex direction="row" alignItems="center" justifyContent="space-between"
                className={styles.controls}>
          <AppText size={20} weight={400}>
            {title}
          </AppText>
          <AppCloseIcon onClick={hideSider}
                        className={styles["close-icon"]}/>
        </AppFlex>
        <AppFlex className={styles.content}>
          {children}
        </AppFlex>
        {isFooterVisible && (
          <AppFlex direction="row" alignItems="center" justifyContent="center" className={styles.actions}>
            <AppButton onClick={onSubmit}>
              {submitBtnText}
            </AppButton>
            <AppButton onClick={onCancel} type="outline">
              {cancelBtnText}
            </AppButton>
          </AppFlex>
        )}
      </AppFlex>
    </AppFlex>
  );
};