import {AppPage} from "common/Layout";
import {EmailConfirm} from "features/email-confirm";

export const EmailConfirmPage = () => {
  return (
    <AppPage documentTitle="Подтверждение электронной почты">
      <EmailConfirm/>
    </AppPage>
  );
};
