import {DictionaryTableControls} from "features/dictionary/components/Controls/Controls";
import {AppSider} from "shared/UI/UIKit/Sider";
import {TableFilter} from "common/TableFilter";
import {DictionaryTable} from "../Table/DictionaryTable";
import {CreateDictionaryItemModal} from "../Modals/Create/Create";
import {EditDictionaryItemModal} from "../Modals/Edit/Edit";
import {RemoveDictionaryItemModal} from "../Modals/Remove/Remove";
import {useDictionary} from "./use-dictionary";
import {dictionaryFilterFields} from "./filter-fields";

export const Dictionary = () => {
  const {state, methods} = useDictionary();

  return (
    <>
      <DictionaryTableControls visibleKeys={state.visibleKeys} columns={state.columns}
                               setVisibleKeys={methods.setVisibleKeys}
                               showSider={methods.showSider} filterCount={state.filterCount}
                               createDictionaryItem={methods.createDictionaryItem}/>
      <DictionaryTable columns={state.filteredColumns} loading={state.loading}
                       dataSource={state.items} tableState={state.tableState}
                       onChange={methods.onTableChange}/>
      <CreateDictionaryItemModal visible={state.createModalVisible}
                                 hide={() => methods.setCreateModalVisible(false)}
                                 onItemCreate={methods.refreshPage}/>
      <EditDictionaryItemModal visible={state.editModalVisible}
                               hide={() => methods.setEditModalVisible(false)}/>
      <RemoveDictionaryItemModal visible={state.removeModalVisible}
                                 hide={() => methods.setRemoveModalVisible(false)}
                                 onItemRemove={methods.onItemRemove}/>
      <AppSider title="Фильтры"
                isSiderVisible={state.siderVisible}
                submitBtnText="Применить"
                cancelBtnText="Сбросить всё"
                hideSider={methods.hideSider}>
        <TableFilter fields={dictionaryFilterFields}
                      submitBtnText="Применить"
                      resetBtnText="Сбросить всё"
                      onSubmit={methods.onFilterChange}
                      onClose={methods.hideSider}
                      fieldsValue={state.filterState}/>
      </AppSider>
    </>
  );
};