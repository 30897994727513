import {personalDataLocationFormNames} from "common/use-personal-data-location/constants";

export const signUpFormNames = {
  email: "email",
  password: "password",
  repeatedPassword: "repeatedPassword",
  firstName: "firstName",
  lastName: "lastName",
  middleName: "middleName",
  processingOfPersonalData: "processingOfPersonalData",
  ...personalDataLocationFormNames,
};