import {AppPage} from "common/Layout";
import {Dictionaries} from "features/dictionaries";
import {homeBreadcrumbItem} from "shared/UI/UIKit/Breadcrumbs";

const breadcrumbs = [
  homeBreadcrumbItem,
  {
    title: "Справочники",
    url: "",
  },
];

export const DictionariesPage = () => {
  return (
    <AppPage breadcrumbs={{
      items: breadcrumbs
    }} documentTitle="Справочники">
      <Dictionaries/>
    </AppPage>
  );
};

