import {UserProfileRole} from "services/api";
import {useAppSelector} from "services/redux/hooks/use-selector";

const METHODIST_PROFILE_ID = 55218;
const SUPPORT_PROFILE_ID = 10000000;

export const useUserProfileRole = () => {
  const {profile} = useAppSelector(state => state.profileState);

  return {
    role: profile && profile.role,
    isAdmin: profile?.role === UserProfileRole.ADMIN,
    isMethodist: profile?.id === METHODIST_PROFILE_ID,
    isUser: profile?.role === UserProfileRole.USER,
    isSupport: profile?.id === SUPPORT_PROFILE_ID,
  };
};