import {AppTableControls} from "common/TableControls/TableControls";
import type {AppTableColumn} from "shared/UI/UIKit/Table";
import type {UserProfileRegion} from "services/api";

export const getRegionsTableColumns = (
  edit: (id: number) => void,
  remove: (id: number) => void
): AppTableColumn<UserProfileRegion>[] => {
  return [
    {
      key: "id",
      title: "ID",
      dataIndex: ["id"],
      maxWidth: "10rem"
    },
    {
      key: "name",
      title: "Название",
      dataIndex: ["name"],
    },
    {
      key: "controls",
      title: "",
      dataIndex: [],
      maxWidth: "10rem",
      render: (value: any, record: UserProfileRegion) => {
        const editRegion = () => edit(Number(record.id));
        const removeRegion = () => remove(Number(record.id));

        return (
          <AppTableControls edit={editRegion} remove={removeRegion}/>
        );
      }
    }
  ];
};