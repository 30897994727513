import cn from "classnames";
import {InlineMenuHelpers} from "shared/UI/UIKit/Menu";
import type {GroupMenuItemOptions} from "shared/UI/UIKit/Menu";
import styles from "./styles.module.scss";

type GetGroupItemProps = GroupMenuItemOptions;

export const getGroupItem = ({
  wrapperClassName,
  ...rest
}: GetGroupItemProps) => {
  return InlineMenuHelpers.getGroupMenuItem({
    wrapperClassName: cn(styles["group-item__wrapper"], wrapperClassName),
    ...rest,
  });
};