import {AppModal} from "shared/UI/UIKit/Modal";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppText} from "shared/UI/UIKit/Text";
import {removeDictionaryItemAction} from "features/dictionary/redux/actions";
import {clearSelectedDictionaryItem} from "features/dictionary/redux/slice";
import type {RemoveDictionaryItemModalProps} from "../types";
import {DictionaryItemModalFooter} from "../ModalFooter/ModalFooter";
import styles from "./Remove.module.scss";

export const RemoveDictionaryItemModal = (props: RemoveDictionaryItemModalProps) => {
  const {loading, selectedDictionaryItem} = useAppSelector(state => state.dictionaryState);
  const dispatch = useAppDispatch();

  const confirm = () => {
    dispatch(removeDictionaryItemAction(selectedDictionaryItem!.id.toString()))
    .unwrap()
    .then(() => {
      hideModal();
      props.onItemRemove();
    });
  };

  const hideModal = () => {
    props.hide();
    dispatch(clearSelectedDictionaryItem());
  };

  const footer = <DictionaryItemModalFooter onSubmit={confirm}
                                            hideModal={hideModal}
                                            title="Удалить"/>;
  const content = (
    <AppFlex unit="8" className={styles.content}>
      <AppText size={16} type="secondary">
        Подтвердите удаление элемента справочника
      </AppText>
      <AppText size={18}>
        {selectedDictionaryItem?.name}
      </AppText>
    </AppFlex>
  );

  return (
    <AppModal visible={props.visible} onCancel={hideModal} size="auto"
              content={content} destroyOnClose footer={footer} loading={loading}
              title="Удаление элемента справочника"/>
  );
};