import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useDidMountEffect} from "shared/helpers/hooks/use-did-mount-effect";
import {useUserProfileRole} from "common/use-user-profile-role";
import type {SortOrder} from "shared/types";
import type {
  AppTableColumn,
  AppTableItem,
  AppTableProps,
  AppTableSingleColumn,
  AppTableSorter,
  AppTableState
} from "../../ui";
import {useTableStateManager} from "../state-manager";

const sortPropsSeparator = ",";
const sortPropSeparator = ":";

export const useTableSearchParams = (
  initialState: AppTableState | (() => AppTableState),
  onSearchParamsUpdate: (tableState: AppTableState, filterState: Record<string, any>) => void,
  tableItemsCount: number,
  total?: number,
  hiddenColumns?: AppTableColumn<any>[]
) => {
  const {tableState, tableMethods} = useTableStateManager(initialState);
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortCleared, setSortCleared] = useState<boolean>(false);
  const [refetch, setRefetch] = useState(false);
  const {isUser} = useUserProfileRole();

  const limitParam = searchParams.get("limit");
  const pageParam = searchParams.get("page");
  const sortParam = searchParams.get("sort");
  const filterParam = searchParams.get("filter");
  const actionParam = searchParams.get("action");

  const [filterState, setFilterState] = useState<Record<string, any>>({});

  const filterCount = Object.keys(filterState).length;

  const getSortersByParams = (oldSorters: AppTableSorter[], sortParams: string | null) => {
    if (sortParams === null) {
      setSortCleared(false);
      return oldSorters.map((oldSorter) => ({...oldSorter, order: undefined}));
    }

    const newSorters: AppTableSorter[] = sortParams.split(sortPropsSeparator).map((param) => {
      const sorter = param.split(sortPropSeparator);
      const dataIndex = sorter[0].split(".");

      return {
        key: dataIndex[0],
        dataIndex,
        order: sorter[1] as SortOrder
      };
    });

    const filteredOldSorters = oldSorters
      .filter((oldSorter) => !newSorters.find((newSorter) => newSorter.key === oldSorter.key))
      // clear order of old sorters that were previously sorted
      .map((oldSorter) => ({...oldSorter, order: undefined}));

    return [...filteredOldSorters, ...newSorters];
  };

  const getTableStateByParams = () => {
    const state = {...tableState};

    if (limitParam && pageParam && !isNaN(+limitParam) && !isNaN(+pageParam)) {
      state.pagination = {
        ...state.pagination,
        pageSize: +limitParam,
        current: +pageParam + 1,
      };
    }

    if (sortParam !== null || sortCleared) {
      state.sorters = getSortersByParams(state.sorters, sortParam);
    }

    return state;
  };

  useEffect(() => {
    if (!refetch) {
      return;
    }
    const nextTableState = getTableStateByParams();
    const nextFilterState = filterParam ? JSON.parse(filterParam) : {};
    tableMethods.setTableState(nextTableState);
    setFilterState(nextFilterState);
    onSearchParamsUpdate(nextTableState, nextFilterState);
  }, [limitParam, pageParam, sortParam, filterParam, actionParam, refetch]);

  useDidMountEffect(() => {
    // remove checking total when pagination for dictionaries is ready
    if (total || total === 0) {
      tableMethods.setTableState({
        ...tableState,
        pagination: {
          ...tableState.pagination,
          total
        }
      });
    }
  }, [total]);

  useEffect(() => {
    const sortUrlParams = searchParams.get("sort");
    if (hiddenColumns && hiddenColumns.length > 0 && sortUrlParams !== null) {
      const hiddenKeys = (hiddenColumns as AppTableSingleColumn<any>[]).map((i) => i.key);
      const sortParams = sortUrlParams.split(sortPropsSeparator).filter((param) =>!hiddenKeys.find((key) => key === param.split(sortPropSeparator)[0]));
      if (sortParams.length > 0) {
        searchParams.set("sort", sortParams.join(sortPropsSeparator));
      } else {
        searchParams.delete("sort");
        setSortCleared(true);
      }
      setSearchParams(searchParams);
    }
  }, [hiddenColumns]);

  const onTableChange: AppTableProps<AppTableItem>["onChange"] = (state, event, action) => {
    if (action === "init" && isUser && tableItemsCount) {
      setRefetch(false);
    } else {
      setRefetch(true);
    }
    switch (action) {
      case "paginate":
        searchParams.set("limit", `${event.limit}`);
        searchParams.set("page", `${event.page}`);
        setSearchParams(searchParams);
        break;

      case "sort": {
        if (event.sort!.length > 0) {
          const sortParams = event.sort!.map((i) => `${i.property}${sortPropSeparator}${i.order}`).join(sortPropsSeparator);
          searchParams.set("sort", sortParams);
        } else {
          searchParams.delete("sort");
          setSortCleared(true);
        }
        setSearchParams(searchParams);
        break;
      }
    }
  };

  const onFilterChange = (values: Record<string, any>) => {
    if (Object.keys(values).length) {
      searchParams.set("filter", JSON.stringify(values));
    } else {
      searchParams.delete("filter");
    }
    searchParams.set("page", "0");
    setSearchParams(searchParams);
  };

  const onItemRemove = () => {
    if (pageParam && !isNaN(+pageParam) && +pageParam > 0 && tableItemsCount === 1) {
      searchParams.set("page", `${+pageParam - 1}`);
      setSearchParams(searchParams);
    } else {
      onSearchParamsUpdate(tableState, filterState);
    }
  };

  const refreshPage = () => {
    onSearchParamsUpdate(tableState, filterState);
  };

  return {
    tableState,
    tableMethods,
    onTableChange,
    filterState,
    filterCount,
    onFilterChange,
    onItemRemove,
    refreshPage,
  };
};
