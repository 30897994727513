import {createSlice} from "@reduxjs/toolkit";
import type {ProgramTemplateBlock} from "services/api";
import type {PayloadAction} from "@reduxjs/toolkit";
import type {TemplateBlockState} from "./types";
import {
  setBlockAsyncReducerBuilder,
  setActiveBlockAsyncReducerBuilder,
  editTemplateBlockContentReducerBuilder,
  createProgramTemplateBlockReducerBuilder,
  editTemplateBlockReducerBuilder,
  getProgramTemplateReducerBuilder,
  removeTemplateBlockReducerBuilder,
  toggleStatusOfProgramTemplateReducerBuilder,
  toggleVisibleOfTemplateBlockAction,
  editFewTemplateBlocksReducerBuilder
} from "./reducers";

const initialState: TemplateBlockState = {
  block: null,
  loading: true,
  notSaved: false,
  fullAccessToProgram: false,
  template: null,
};

const templateBlockSlice = createSlice({
  name: "templateBlock",
  initialState,
  reducers: {
    setBlock: (state, action: PayloadAction<ProgramTemplateBlock>) => {
      state.block = action.payload;
      state.notSaved = true;
    },
    setGenerationDocxInProgress: (state, action: PayloadAction<boolean>) => {
      if (state.template) {
        state.template.generationInProgress = action.payload;
      }
    },
    setActiveBlock: (state, action: PayloadAction<ProgramTemplateBlock | null>) => {
      state.block = action.payload;
      state.notSaved = false;
    },
    toggleFullAccessToProgram: (state) => {
      state.fullAccessToProgram = !state.fullAccessToProgram;
    },
    clearState: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    setBlockAsyncReducerBuilder(builder);
    setActiveBlockAsyncReducerBuilder(builder);
    editTemplateBlockContentReducerBuilder(builder);
    createProgramTemplateBlockReducerBuilder(builder);
    getProgramTemplateReducerBuilder(builder);
    editTemplateBlockReducerBuilder(builder);
    editFewTemplateBlocksReducerBuilder(builder);
    removeTemplateBlockReducerBuilder(builder);
    toggleStatusOfProgramTemplateReducerBuilder(builder);
    toggleVisibleOfTemplateBlockAction(builder);
  }
});

export const templateBlockReducer = templateBlockSlice.reducer;
export const {setBlock, setActiveBlock, clearState, toggleFullAccessToProgram, setGenerationDocxInProgress} = templateBlockSlice.actions;
