import {AppPage} from "common/Layout";
import {homeBreadcrumbItem} from "shared/UI/UIKit/Breadcrumbs";
import {Users} from "features/users";

const breadcrumbs = [
  homeBreadcrumbItem,
  {
    title: "Пользователи",
    url: "",
  },
];

export const UsersPage = () => {
  return (
    <AppPage breadcrumbs={{items: breadcrumbs}}
             documentTitle="Пользователи">
      <Users/>
    </AppPage>
  );
};