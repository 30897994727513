import {createSlice} from "@reduxjs/toolkit";
import type {ProgramTemplate} from "services/api";
import type {PayloadAction} from "@reduxjs/toolkit";
import type {TemplatesState} from "./types";
import {
  copyTemplateReducerBuilder,
  createProgramFromTemplateReducerBuilder,
  createProgramTemplateReducerBuilder,
  editTemplateReducerBuilder,
  getTemplatesReducerBuilder,
  removeTemplateReducerBuilder
} from "./reducers";

const initialState: TemplatesState = {
  templates: [],
  total: 0,
  loading: false,
  selectedTemplate: null,
};

const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    setSelectedTemplate: (state, action: PayloadAction<ProgramTemplate>) => {
      state.selectedTemplate = action.payload;
    },
    clearSelectedTemplate: (state) => {
      state.selectedTemplate = null;
    },
    clearState: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    getTemplatesReducerBuilder(builder);
    createProgramTemplateReducerBuilder(builder);
    removeTemplateReducerBuilder(builder);
    editTemplateReducerBuilder(builder);
    createProgramFromTemplateReducerBuilder(builder);
    copyTemplateReducerBuilder(builder);
  }
});

export const templatesReducer = templatesSlice.reducer;
export const {setSelectedTemplate, clearSelectedTemplate, clearState} = templatesSlice.actions;
