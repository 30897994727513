import {createAsyncThunk} from "@reduxjs/toolkit";

export const setBlockingLoadingAsync = createAsyncThunk(
  "blockingLoading/setAsync",
  async (nextBlockingLoading: boolean) => {
    const promise = new Promise<boolean>((resolve) => {
      setTimeout(() => resolve(nextBlockingLoading));
    });
    return await promise;
  }
);