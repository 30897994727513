import {AppPage} from "common/Layout";
import {PersonalData} from "features/personal-data";

export const PersonalDataPage = () => {
  return (
    <AppPage pageTitle="Персональные данные" documentTitle="Персональные данные">
      <PersonalData/>
    </AppPage>
  );
};
