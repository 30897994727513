import {AppText} from "shared/UI/UIKit/Text";
import {AppFlex} from "shared/UI/UIKit/Flex";
import type {AppFormItemLabelProps} from "./FormItemLabel.types";
import {getClassName, getTextClassName} from "./FormItemLabel.utils";

export const AppFormItemLabel = (props: AppFormItemLabelProps) => {
  const className = getClassName(props);
  const textClassTName = getTextClassName(props);

  return (
    <AppFlex unit="0" className={className}>
      <AppText className={textClassTName}>
        {props.text}
      </AppText>
      {props.children}
    </AppFlex>
  );
};