import {notification} from "antd";
import {appApi} from "services/api/api-instance";
import type {Subject} from "./types";

export const getSubjectsList = (): Promise<Subject[]> => {
  return appApi
    .get("/subject/")
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Не удалось загрузить предметы"});
      throw error;
    });
};