import {AppFlex} from "shared/UI/UIKit/Flex";
import type {GridProps} from "./Grid.props";
import {AppGridRow} from "./Row/Row";
import {AppGridColumn} from "./Column/Column";
import {getClassNames} from "./Grid.utils";

export const AppGrid = (props: GridProps) => {
  const classNames = getClassNames(props.className);
  return (
    <AppFlex unit="0" className={classNames}>
      {props.children}
    </AppFlex>
  );
};

AppGrid.Row = AppGridRow;
AppGrid.Column = AppGridColumn;