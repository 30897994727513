import {useState} from "react";
import {AppModal} from "shared/UI/UIKit/Modal";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppText} from "shared/UI/UIKit/Text";
import {AppButton} from "shared/UI/UIKit/Button";
import {removeUserProfile} from "services/api";
import type {RemoveUserModalProps} from "./types";
import {getUserFullName} from "../../../utils";
import styles from "./Remove.module.scss";

export const RemoveUserModal = (props: RemoveUserModalProps) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    removeUserProfile(props.user!.id)
      .then(() => {
        props.onItemRemove();
      })
      .finally(() => {
        props.hide();
        setLoading(false);
      });
  };

  const footer = (
    <AppFlex direction="row" justifyContent="flex-start">
      <AppButton color="danger" onClick={onSubmit}>
        Удалить
      </AppButton>
      <AppButton type="outline" onClick={props.hide}>
        Отмена
      </AppButton>
    </AppFlex>
  );

  const content = (
    <AppFlex unit="8" className={styles.content}>
      <AppText size={16} type="secondary">
        Подтвердите удаление пользователя
      </AppText>
      <AppText size={18}>
        {getUserFullName(props.user)}
      </AppText>
    </AppFlex>
  );

  return (
    <AppModal visible={props.visible} onCancel={props.hide} size="auto"
              content={content} destroyOnClose footer={footer} loading={loading}
              title="Удаление пользователя"/>
  );
};