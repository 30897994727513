import type {AppIconProps} from "../Icons.types";
import {getClassNames} from "../Icons.utils";
import styles from "./FilterButton.module.scss";

export const AppFilterButtonIcon = (props: AppIconProps) => {
  const {className, ...rest} = props;
  const classNames = getClassNames(styles.icon, className);

  return (
    <svg {...rest} className={classNames} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 20" fill="none">
      <path d="M1.42183 1.76449C1.27824 1.54271 1.43743 1.25 1.70164 1.25H16.2984C16.5626 1.25 16.7218 1.54271 16.5782 1.76449L11.8122 9.12584C11.5676 9.50357 11.4375 9.94396 11.4375 10.3939V18.1634C11.4375 18.4215 11.1568 18.5817 10.9346 18.4504L6.72626 15.9636C6.62477 15.9037 6.5625 15.7945 6.5625 15.6767V10.3939C6.5625 9.94396 6.43238 9.50357 6.18783 9.12584L1.42183 1.76449Z" stroke="#737781" strokeWidth="2"/>
    </svg>
  );
};