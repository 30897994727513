import {useForm} from "antd/lib/form/Form";
import {useNavigate} from "react-router-dom";
import {AppModal} from "shared/UI/UIKit/Modal";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {createDictionaryAction} from "features/dictionaries/redux/actions";
import {getDictionaryUrl} from "services/navigation";
import type {Dictionary} from "services/api";
import type {DictionaryFormValues, DictionaryModalProps} from "../types";
import {DictionaryModalFooter} from "../ModalFooter/ModalFooter";
import {DictionaryForm} from "../../Form/DictionaryForm";

export const CreateDictionaryModal = (props: DictionaryModalProps) => {
  const [form] = useForm<DictionaryFormValues>();
  const {loading} = useAppSelector(state => state.dictionariesState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = () => {
    form.validateFields().then(values => {
      dispatch(createDictionaryAction({
        ...values,
        items: [],
      }))
        .unwrap()
        .then((res: Dictionary) => {
          hideModal();
          navigate(getDictionaryUrl({dictionaryId: res.id.toString()}));
        });
    });
  };

  const hideModal = () => {
    props.hide();
    form.resetFields();
  };

  const footer = <DictionaryModalFooter onSubmit={onSubmit}
                                        hideModal={hideModal}
                                        title="Создать"/>;
  const content = <DictionaryForm form={form}/>;

  return (
    <AppModal visible={props.visible} onCancel={hideModal}
              content={content} destroyOnClose footer={footer}
              loading={loading} title="Создать справочник"/>
  );
};