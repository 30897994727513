import {AppBlockingLoader} from "shared/UI/UIKit/Loader";
import type {BlockingLoadingWrapperProps} from "./types";
import {useBlockingLoading} from "../../lib/hooks";
import styles from "./styles.module.scss";

export const BlockingLoadingWrapper = ({children}: BlockingLoadingWrapperProps) => {
  const {isBlockingLoading} = useBlockingLoading();

  return (
    <AppBlockingLoader loading={isBlockingLoading}
                       className={styles.blocker} spinnerClassName={styles.spinner}>
      {children}
    </AppBlockingLoader>
  );
};