import {AppForm, AppFormItem, formRules} from "shared/UI/UIKit/Form";
import {AppInput} from "shared/UI/UIKit/Input";
import type {MunicipalityFormProps} from "./types";

export const MunicipalityForm = (props: MunicipalityFormProps) => {
  return (
    <AppForm form={props.form}>
      <AppFormItem label="Название района" labelColor="gray" size="max"
                   name="name" rules={formRules.required}>
        <AppInput placeholder="Название района"/>
      </AppFormItem>
    </AppForm>
  );
};