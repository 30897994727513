import {AppForm, AppFormItem} from "shared/UI/UIKit/Form";
import {AppSelect} from "shared/UI/UIKit/Select";
import {personalDataLocationFormNames} from "common/use-personal-data-location/constants";
import {AppInput} from "shared/UI/UIKit/Input";
import type {LocationFormProps} from "./types";

export const LocationForm = (props: LocationFormProps) => {
  return (
    <AppForm form={props.form}>
      <AppFormItem label="Регион" labelColor="gray" size="max"
                   name={personalDataLocationFormNames.region}>
        <AppSelect options={props.regionsOptions} allowClear
                   placeholder="Выберите регион" onChange={props.onRegionChange}/>
      </AppFormItem>
      <AppFormItem label="Район" labelColor="gray" size="max"
                   name={personalDataLocationFormNames.municipality}>
        <AppSelect options={props.municipalitiesOptions} allowClear
                   placeholder="Выберите Район" disabled={!props.regionId}
                   onChange={props.onMunicipalityChange}/>
      </AppFormItem>
      <AppFormItem label="Название организации" labelColor="gray" size="max"
                   name="name">
        <AppInput onChange={e => props.onNameChange(e.target.value)}
                  value={props.name}
                  placeholder="Введите название организации"/>
      </AppFormItem>
    </AppForm>
  );
};