import cn from "classnames";
import {AppMenu} from "shared/UI/UIKit/Menu";
import type {SidebarMenuProps} from "./SidebarMenu.types";
import styles from "./SidebarMenu.module.scss";

export const SidebarMenu = ({menuItems, children, isStickyItems}: SidebarMenuProps) => {
  return (
    <div className={cn(styles["menu-container"], isStickyItems && styles["sticky-container"])}>
      <AppMenu mode="inline"
               items={menuItems}
               className={cn(styles.menu, isStickyItems && styles["sticky-menu"])}/>
      {children}
    </div>
  );
};