import {useForm} from "antd/lib/form/Form";
import {AppModal} from "shared/UI/UIKit/Modal";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {createDictionaryItemAction} from "features/dictionary/redux/actions";
import type {DictionaryItemFormValues, CreateDictionaryItemModalProps} from "../types";
import {DictionaryItemModalFooter} from "../ModalFooter/ModalFooter";
import {DictionaryItemForm} from "../../Form/DictionaryItemForm";

export const CreateDictionaryItemModal = (props: CreateDictionaryItemModalProps) => {
  const [form] = useForm<DictionaryItemFormValues>();
  const {loading, dictionary} = useAppSelector(state => state.dictionaryState);
  const dispatch = useAppDispatch();

  const onSubmit = () => {
    form.validateFields().then(values => {
      dispatch(createDictionaryItemAction({
        name: values.name,
        reference: dictionary!,
      }))
        .unwrap()
        .then(() => {
          hideModal();
          props.onItemCreate();
        });
    });
  };

  const hideModal = () => {
    props.hide();
    form.resetFields();
  };

  const footer = <DictionaryItemModalFooter onSubmit={onSubmit}
                                            hideModal={hideModal}
                                            title="Создать"/>;
  const content = <DictionaryItemForm form={form}/>;

  return (
    <AppModal visible={props.visible} onCancel={hideModal}
              content={content} destroyOnClose footer={footer}
              loading={loading} title="Создать элемент справочника"/>
  );
};