import {createSlice} from "@reduxjs/toolkit";
import type {Dictionary} from "services/api";
import type {PayloadAction} from "@reduxjs/toolkit";
import type {DictionariesState} from "./types";
import {
  createDictionaryReducerBuilder,
  editDictionaryReducerBuilder,
  getDictionaryListReducerBuilder,
  removeDictionaryReducerBuilder,
} from "./reducers";

const initialState: DictionariesState = {
  dictionaries: [],
  total: 0,
  selectedDictionary: null,
  loading: true,
};

const dictionariesSlice = createSlice({
  name: "dictionaries",
  initialState,
  reducers: {
    setSelectedDictionary: (state, action: PayloadAction<Dictionary>) => {
      state.selectedDictionary = action.payload;
    },
    clearSelectedDictionary: (state) => {
      state.selectedDictionary = null;
    },
    clearState: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    createDictionaryReducerBuilder(builder);
    editDictionaryReducerBuilder(builder);
    getDictionaryListReducerBuilder(builder);
    removeDictionaryReducerBuilder(builder);
  }
});

export const dictionariesReducer = dictionariesSlice.reducer;
export const {setSelectedDictionary, clearSelectedDictionary, clearState} = dictionariesSlice.actions;
