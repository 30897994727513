import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppText} from "shared/UI/UIKit/Text";
import type {AppDescriptionProps} from "./Description.types";
import {getClassNames} from "./Description.utils";

export const AppDescription = (props: AppDescriptionProps) => {
  const {title, children, titleTextSize = 14, titleTextWeight = 700, unit = "8", ...rest} = props;
  const classNames = getClassNames(props);

  return (
    <AppFlex unit={unit} {...rest}>
      <AppText className={classNames}
               size={titleTextSize}
               weight={titleTextWeight}>
        {title}
      </AppText>
      <AppText>
        {children}
      </AppText>
    </AppFlex>
  );
};