import {AppTableControls} from "common/TableControls/TableControls";
import type {AppTableColumn} from "shared/UI/UIKit/Table";
import type {UserProfileOrganization} from "services/api";

export const getOrganizationsTableColumns = (
  edit: (id: number) => void,
  remove: (id: number) => void
): AppTableColumn<UserProfileOrganization>[] => {
  return [
    {
      key: "id",
      title: "ID",
      dataIndex: ["id"],
      maxWidth: "10rem"
    },
    {
      key: "name",
      title: "Название",
      dataIndex: ["name"],
    },
    {
      key: "fullName",
      title: "Полное название",
      dataIndex: ["fullName"],
    },
    {
      key: "controls",
      title: "",
      dataIndex: [],
      maxWidth: "10rem",
      render: (value: any, record: UserProfileOrganization) => {
        const editOrganization = () => edit(Number(record.id));
        const removeOrganization = () => remove(Number(record.id));

        return (
          <AppTableControls edit={editOrganization} remove={removeOrganization}/>
        );
      }
    }
  ];
};