import {ProgramTemplateEducationLevel, ProgramTemplateStatus} from "services/api";
import type {AppSelectOption} from "shared/UI/UIKit/Select";

export const educationLevels: AppSelectOption[] = Object.values(ProgramTemplateEducationLevel)
  .map((value) => {
    let label: React.ReactNode = value;
    switch (value) {
      case ProgramTemplateEducationLevel.НОО:
        label = "Начальное Общее Образование (1 - 4 класс)";
        break;

      case ProgramTemplateEducationLevel.ООО:
        label = "Основное Общее Образование (5 - 9 класс)";
        break;

      case ProgramTemplateEducationLevel.СОО:
        label = "Среднее Общее Образование (10-11 класс)";
        break;
    }

    return {label, value};
  });

export const getProgramTemplateStatuses = (isPrograms = false) => {
  return Object.values(ProgramTemplateStatus).map((value) => {
    let label: React.ReactNode = value;
    switch (value) {
      case ProgramTemplateStatus.DONE:
        label = isPrograms ? "Завершена" : "Опубликован";
        break;

      case ProgramTemplateStatus.IN_PROGRESS:
        label = isPrograms ? "В работе" : "Черновик";
        break;
    }

    return {label, value};
  });
};