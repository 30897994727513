import {notification} from "antd";
import type {ProgramTemplateBlockTable} from "../blocks";
import {appApi} from "../api-instance";

export const uploadTableFromXLSX = (formData: FormData): Promise<ProgramTemplateBlockTable> => {
  return appApi
    .post("/work_program_table/upload/xlsx/", formData)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка импорта файла"});
      throw error;
    });
};

export const exportTableToXLSX = (
  tableId: string | number,
  format: "dnevnik" | "eljur" | "digital_ed" | "bars" | "epos" | "net_school"
): Promise<Blob> => {
  const url = `/work_program_table/export/xlsx/${format}/${tableId}`;

  return appApi
    .get(url, {responseType: "blob"})
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка экспорта файла"});
      throw error;
    });
};