import {AppButton} from "shared/UI/UIKit/Button";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {TableColumnsFilter} from "common/ColumnsFilter/ColumnsFilter";
import {FilterButton} from "common/FilterButton/FilterButton";
import type {TemplatesTableControlsProps} from "./types";
import styles from "./Controls.module.scss";

export const TemplatesTableControls = (props: TemplatesTableControlsProps) => {
  return (
    <AppFlex direction="row" justifyContent="flex-end" className={styles.controls}>
      <AppButton onClick={props.isPrograms ? props.createProgram : props.createTemplate}>
        Создать
      </AppButton>
      {
        !props.isPrograms && (
          <AppButton onClick={props.copyTemplate}>
            Скопировать шаблон
          </AppButton>
        )
      }
      <FilterButton count={props.filterCount} click={props.showSider}/>
      <TableColumnsFilter visibleKeys={props.visibleKeys} columns={props.columns}
                          setVisibleKeys={props.setVisibleKeys}/>
    </AppFlex>
  );
};