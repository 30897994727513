import {useForm} from "antd/lib/form/Form";
import {useEffect} from "react";
import {AppModal} from "shared/UI/UIKit/Modal";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {editDictionaryAction} from "features/dictionaries/redux/actions";
import {clearSelectedDictionary} from "features/dictionaries/redux/slice";
import type {DictionaryFormValues, DictionaryModalProps} from "../types";
import {DictionaryModalFooter} from "../ModalFooter/ModalFooter";
import {DictionaryForm} from "../../Form/DictionaryForm";

export const EditDictionaryModal = (props: DictionaryModalProps) => {
  const [form] = useForm<DictionaryFormValues>();
  const {loading, selectedDictionary} = useAppSelector(state => state.dictionariesState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedDictionary) {
      form.setFieldsValue({...selectedDictionary});
    }
  }, [selectedDictionary]);

  const onSubmit = () => {
    form.validateFields().then(values => {
      dispatch(editDictionaryAction({
        ...selectedDictionary!,
        ...values,
      })).unwrap().then(hideModal);
    });
  };

  const hideModal = () => {
    props.hide();
    dispatch(clearSelectedDictionary());
    form.resetFields();
  };

  const footer = <DictionaryModalFooter onSubmit={onSubmit}
                                        hideModal={hideModal}
                                        title="Редактировать"/>;
  const content = <DictionaryForm form={form} isEdit/>;

  return (
    <AppModal visible={props.visible} onCancel={hideModal}
              content={content} destroyOnClose footer={footer}
              loading={loading} title={selectedDictionary?.name}/>
  );
};