import {useState} from "react";
import {AppModal} from "shared/UI/UIKit/Modal";
import {useAppForm} from "shared/UI/UIKit/Form";
import {createOrganization} from "services/api";
import type {CreateOrganizationModalProps} from "./types";
import {OrganizationModalFooter} from "../ModalFooter";
import {OrganizationForm} from "../../Form/Organization";

export const CreateOrganizationModal = (props: CreateOrganizationModalProps) => {
  const [form] = useAppForm();
  const [loading, setLoading] = useState(false);

  const hideModal = () => {
    form.resetFields();
    props.hide();
  };

  const onSubmit = () => {
    form.validateFields().then(values => {
      setLoading(true);

      createOrganization({
        name: values.name,
        fullName: values.fullName,
        municipality: {
          id: Number(props.municipalityId),
          region: {
            id: Number(props.regionId),
          }
        }
      }).then((res) => {
        props.addItem(res);
        hideModal();
      }).finally(() => {
        setLoading(false);
      });
    });
  };

  const footer = <OrganizationModalFooter hideModal={hideModal}
                                          onSubmit={onSubmit}
                                          title="Сохранить"/>;
  const content = <OrganizationForm form={form}/>;

  return (
    <AppModal visible={props.visible} onCancel={hideModal}
              content={content} destroyOnClose footer={footer}
              loading={loading} title="Создать организацию"/>
  );
};