import {createAsyncThunk} from "@reduxjs/toolkit";
import {
  changeProgramTemplateStatus,
  createProgramTemplateBlock,
  deleteProgramTemplateBlock,
  editProgramTemplateBlock,
  getProgramTemplateBlockById,
  getProgramTemplateById,
  getProgramTemplateBySharedToken,
  ProgramTemplateStatus
} from "services/api";
import type {ProgramTemplateBlock, ProgramTemplate,
  ProgramTemplateBlockBody} from "services/api";
import type {RootState} from "services/redux/types";
import * as documentTypeUtils from "./utils/document-type-utils";
import * as tableTypeUtils from "./utils/table-type-utils";

export const setBlockAsync = createAsyncThunk(
  "templateBlock/setBlockAsync",
  async (block: ProgramTemplateBlock) => {
    const promise = new Promise<ProgramTemplateBlock>((resolve) => {
      setTimeout(() => resolve(block));
    });
    return await promise;
  }
);

export const setActiveBlockAsync = createAsyncThunk(
  "templateBlock/setActiveBlockAsync",
  async (block: ProgramTemplateBlock | null) => {
    const promise = new Promise<ProgramTemplateBlock | null>((resolve) => {
      setTimeout(() => resolve(block));
    });
    return await promise;
  }
);

export const editProgramTemplateBlockContentAction = createAsyncThunk("templateBlock/edit", async(_, thunkApi) => {
  const {block, template} = (thunkApi.getState() as RootState).templateBlockState;

  const getFilteredContent = () => {
    switch (block!.type) {
      case "DOCUMENT": {
        const contentWithoutUnnecessaryStyles = documentTypeUtils.getFilteredContentStyles(block!.content);
        // delete \n from math formulas
        return documentTypeUtils.getContentWithCleanKatexNotation(contentWithoutUnnecessaryStyles);
      }
      default: {
        return block!.content;
      }
    }
  };

  const getFilteredFields = () => {
    switch (block!.type) {
      case "DOCUMENT": {
        return documentTypeUtils.getFilteredFields(block!);
      }
      default: {
        return block!.fields;
      }
    }
  };

  const getNormalizedTables = () => {
    switch (block!.type) {
      case "TABLE": {
        return tableTypeUtils.getNormalizedTables(block!.tables);
      }
      default: {
        return block!.tables;
      }
    }
  };

  const body: ProgramTemplateBlock = {
    ...block!,
    template: {
      ...template!,
      blocks: [],
    },
    content: getFilteredContent(),
    fields: getFilteredFields(),
    tables: getNormalizedTables(),
  };

  try {
    return await editProgramTemplateBlock(block!.id.toString(), body);
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const getProgramTemplateBlockAction = createAsyncThunk("templateBlock/getById", async(blockId: string) => {
  try {
    return await getProgramTemplateBlockById(blockId);

  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const createProgramTemplateBlockAction = createAsyncThunk(
  "templateBlocks/create",
  async (body: ProgramTemplateBlockBody) => {
    try {
      return await createProgramTemplateBlock(body);
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

type EditActionPayload = {
  blockId: string;
  body: ProgramTemplateBlockBody;
}

export const editProgramTemplateBlockAction = createAsyncThunk<ProgramTemplateBlock, EditActionPayload>(
  "templateBlocks/edit",
  async ({blockId, body}) => {
    try {
      return await editProgramTemplateBlock(blockId, body);
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const editFewProgramTemplateBlocksAction = createAsyncThunk<ProgramTemplateBlock[], EditActionPayload[]>(
  "templateBlocks/editFew",
  async (payloads) => {
    if (payloads.length === 0) {
      throw new Error("payload array empty");
    }
    try {
      return await Promise.all(
        payloads.map((payload) => editProgramTemplateBlock(
          payload.blockId,
          payload.body
        ))
      );
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const toggleVisibleTemplateBlockAction = createAsyncThunk<ProgramTemplateBlock, ProgramTemplateBlock>(
  "templateBlocks/toggleVisible",
  async (body) => {
    try {
      return await editProgramTemplateBlock(body.id.toString(), body);
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

type GetActionPayload = {templateId: string} | {sharedToken: string};

export const getProgramTemplateAction = createAsyncThunk<ProgramTemplate, GetActionPayload>(
  "templateBlocks/getById",
  async (payload) => {
    try {
      if ("templateId" in payload) {
        return await getProgramTemplateById(payload.templateId);
      } else {
        return await getProgramTemplateBySharedToken(payload.sharedToken);
      }
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const toggleStatusOfProgramTemplateAction = createAsyncThunk("templates/edit", async(template: ProgramTemplate) => {
  const status = template!.status === ProgramTemplateStatus.IN_PROGRESS ? ProgramTemplateStatus.DONE : ProgramTemplateStatus.IN_PROGRESS;

  try {
    return await changeProgramTemplateStatus(template.id, status);
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const removeProgramTemplateBlockAction = createAsyncThunk(
  "templateBlocks/remove",
  async (blockId: string) => {
    try {
      return await deleteProgramTemplateBlock(blockId);
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);
