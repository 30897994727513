import cn from "classnames";
import {InlineMenuHelpers} from "shared/UI/UIKit/Menu";
import type {TextMenuItemOptions} from "shared/UI/UIKit/Menu";
import {getIcon} from "./get-icon";
import styles from "./styles.module.scss";

type GetTextItemProps = TextMenuItemOptions & {
  isInner?: boolean;
};

export const getTextItem = ({
  isInner,
  icon,
  wrapperClassName,
  className,
  ...rest
}: GetTextItemProps) => {
  return InlineMenuHelpers.getTextMenuItem({
    icon: getIcon(icon),
    wrapperClassName: cn(
      styles["item-wrapper"],
      styles["link-item__wrapper"],
      {[styles["link-item__wrapper_inner"]]: isInner},
      wrapperClassName,
    ),
    className: cn(
      styles["link-item__content"],
      {[styles["link-item__content_first-lvl"]]: !isInner},
      {[styles["link-item__content_second-lvl"]]: isInner},
      className,
    ),
    ...rest,
  });
};