import {createAsyncThunk} from "@reduxjs/toolkit";
import {
  createProgramFromTemplate,
  createProgramTemplate,
  deleteProgramTemplate,
  getProgramTemplateList,
  updateProgramTemplate
} from "services/api";
import type {ProgramTemplateBody, ProgramTemplatesRequest} from "services/api";

export const getProgramTemplatesAction = createAsyncThunk("templates/getList", async(payload: ProgramTemplatesRequest) => {
  try {
    return await getProgramTemplateList(payload);
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const createProgramTemplateAction = createAsyncThunk("templates/create", async(template: ProgramTemplateBody) => {
  try {
    return await createProgramTemplate(template);
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const createProgramFromTemplateAction = createAsyncThunk("templates/createAsProgram", async(templateId: string, ) => {
  try {
    return await createProgramFromTemplate(templateId);
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const copyTemplate = createAsyncThunk("templates/copyTemplate", async(templateId: string, ) => {
  try {
    return await createProgramFromTemplate(templateId, false);
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const removeProgramTemplateAction = createAsyncThunk("templates/remove", async(templateId: string) => {
  try {
    return await deleteProgramTemplate(templateId);
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

type EditActionPayload = {
  template: ProgramTemplateBody;
  templateId: number;
}

export const editProgramTemplateAction = createAsyncThunk("templates/edit", async(payload: EditActionPayload) => {
  try {
    return await updateProgramTemplate(payload.templateId, payload.template);
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});