import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {AppForm, AppFormItem, formRules} from "shared/UI/UIKit/Form";
import {AppSelect} from "shared/UI/UIKit/Select";
import {AppInput} from "shared/UI/UIKit/Input";
import {educationLevels} from "features/templates/components/constants";
import {getProgramTemplateList, ProgramTemplateStatus,ProgramTemplateEducationLevel} from "services/api";
import {useUserProfileRole} from "common/use-user-profile-role";
import type {AppSelectOption} from "shared/UI/UIKit/Select";
import type {ProgramTemplate} from "services/api";
import type {WorkProgramFormProps} from "./types";
import {TemplateLabel} from "./TemplateLabel";

export const WorkProgramForm = (props: WorkProgramFormProps) => {
  const {isAdmin, isMethodist} = useUserProfileRole();
  const [subjectId, setSubjectId] = useState<string>();
  const [educationLevel, setEducationLevel] = useState<ProgramTemplateEducationLevel>();
  const [templates, setTemplates] = useState<ProgramTemplate[]>([]);
  const hasAccessToUnpublishedPrograms = isAdmin || isMethodist;
  const [searchParams] = useSearchParams();
  const subjectIdFromMainProgram = searchParams.get("subjectId");
  const educationLevelFromMainProgram = searchParams.get("educationLevel");

  useEffect(() => {
    return () => {
      setSubjectId(undefined);
      setEducationLevel(undefined);
    };
  }, []);

  useEffect(() => {
    if (subjectIdFromMainProgram) {
      setSubjectId(subjectIdFromMainProgram);
    }

    if (educationLevelFromMainProgram) {
      const englishToRussian: Record<string, ProgramTemplateEducationLevel> = {
        "HOO": ProgramTemplateEducationLevel.НОО,
        "COO": ProgramTemplateEducationLevel.СОО,
        "OOO": ProgramTemplateEducationLevel.ООО
      };
      setEducationLevel(englishToRussian[educationLevelFromMainProgram]);
    }
  }, []);

  const getFilteredTemplates = async () => {
    return await getProgramTemplateList({
      isProgram: false,
      status: hasAccessToUnpublishedPrograms ? undefined : ProgramTemplateStatus.DONE,
      limit: 200,
      educationLevel,
      subjectId
    }).then(res => res.data);
  };

  const sortSubjects = (a: AppSelectOption, b: AppSelectOption) => {
    if (Number(a.value) === 1000 || Number(a.value) === 2000) {
      return 1;
    }

    if (Number(b.value) === 1000 || Number(b.value) === 2000) {
      return 1;
    }

    return (a.label as string).localeCompare(b.label as string);
  };

  useEffect(() => {
    if (subjectId && educationLevel) {
      getFilteredTemplates().then(setTemplates);
    }
  }, [subjectId, educationLevel]);

  return (
    <AppForm form={props.form}>
      <AppFormItem label="Выберите уровень образования">
        <AppSelect options={educationLevels} value={educationLevel}
                   placeholder="Выберите уровень образования"
                   onChange={setEducationLevel}/>
      </AppFormItem>
      <AppFormItem label="Выберите предмет">
        <AppSelect options={props.subjectsOptions} placeholder="Выберите предмет"
                   onChange={setSubjectId} value={subjectId}
                   filterSort={sortSubjects}/>
      </AppFormItem>
      <AppFormItem rules={formRules.required} label="Выберите шаблон" name="templateId">
        <AppSelect disabled={!subjectId || !educationLevel} placeholder="Выберите шаблон"
                   optionLabelProp="selectLabel"
                   options={templates.map(template => {
                     const label = template.name;
                     const value = template.id.toString();

                     return {
                       selectLabel: label,
                       label: (
                         <TemplateLabel label={label}
                                        status={hasAccessToUnpublishedPrograms ? template.status : undefined}/>
                       ),
                       value,
                     };
                   })}/>
      </AppFormItem>
      <AppFormItem rules={formRules.required} label={props.isPrograms ? "Название рабочей программы" : "Название шаблона"} name="programName">
        <AppInput placeholder="Введите название"/>
      </AppFormItem>
    </AppForm>
  );
};