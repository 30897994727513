import {useForm} from "antd/lib/form/Form";
import {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {AppModal} from "shared/UI/UIKit/Modal";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {getSubjectsList, ProgramTemplateStatus} from "services/api";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {
  copyTemplate,
  createProgramFromTemplateAction,
  editProgramTemplateAction
} from "features/templates/redux/actions";
import {getTemplateBlockUrl, getWorkProgramBlockUrl} from "services/navigation";
import {toOptions} from "shared/helpers/utils/to-options";
import type {Subject} from "services/api";
import type {WorkProgramFormValues, WorkProgramModalProps} from "./types";
import {TemplateModalFooter} from "../ModalFooter/ModalFooter";
import {WorkProgramForm} from "../../forms/WorkProgram/WorkProgram";

export const CreateProgramModal = (props: WorkProgramModalProps) => {
  const [form] = useForm<WorkProgramFormValues>();
  const {loading} = useAppSelector(state => state.templatesState);
  const [templatesLoading, setTemplatesLoading] = useState(true);
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (props.visible) {
      getSubjectsList().then((res) => {
        setSubjects(res);
        setTemplatesLoading(false);
      });
    }
  }, [props.visible]);

  const onSubmit = async() => {
    const values = await form.validateFields();
    const program = props.isPrograms ?
      await dispatch(createProgramFromTemplateAction(values.templateId)).unwrap() :
      await dispatch(copyTemplate(values.templateId)).unwrap();
    await dispatch(editProgramTemplateAction(
      {
        templateId: program.id,
        template: {
          ...program,
          showInMainPrograms: false,
          name: props.isPrograms ? program.name : values.programName,
          programName: props.isPrograms ? values.programName : "",
          status: ProgramTemplateStatus.IN_PROGRESS,
        },
      }
    )).unwrap();

    hideModal();
    const redirectUrl = props.isPrograms ?
      getWorkProgramBlockUrl({templateId: program.id.toString()}) :
      getTemplateBlockUrl({templateId: program.id.toString()});
    navigate(redirectUrl);
  };

  const hideModal = () => {
    props.hide();
    form.resetFields();
    searchParams.delete("subjectId");
    searchParams.delete("educationLevel");
    setSearchParams(searchParams);
  };

  const footer = <TemplateModalFooter onSubmit={onSubmit}
                                      hideModal={hideModal}
                                      title="Создать"/>;
  const content = <WorkProgramForm form={form} isPrograms={props.isPrograms}
                                   subjectsOptions={toOptions(subjects, "id", "name")}/>;

  return (
    <AppModal visible={props.visible} onCancel={hideModal}
              content={content} destroyOnClose footer={footer}
              loading={loading || templatesLoading}
              title={props.isPrograms ? "Создать программу из шаблона" : "Скопировать шаблон"}/>
  );
};