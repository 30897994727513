import {Outlet} from "react-router-dom";
import {getHomeUrl} from "services/navigation";
import {useAuth} from "services/auth";
import {PageProtectorBase} from "./Base/PageProtectorBase";

const HOME_PAGE_PATH = getHomeUrl();

export const UnauthorizedPageProtector = () => {
  const {authenticated} = useAuth();

  return (
    <PageProtectorBase to={HOME_PAGE_PATH} condition={!authenticated}>
      <Outlet/>
    </PageProtectorBase>
  );
};