import {Regions} from "features/regions";
import {AppPage} from "common/Layout";
import {homeBreadcrumbItem} from "shared/UI/UIKit/Breadcrumbs";

const breadcrumbs = [
  homeBreadcrumbItem,
  {
    title: "Регионы",
    url: "",
  },
];

export const RegionsPage = () => {
  return (
    <AppPage breadcrumbs={{items: breadcrumbs}}
             documentTitle="Регионы">
      <Regions/>
    </AppPage>
  );
};