import {notification} from "antd";
import type {ProgramTemplateBody,ProgramTemplate, ProgramTemplatesRequest, ProgramTemplatesResponse,ProgramTemplateStatus} from "./types";
import {appApi} from "../api-instance";

export const deleteProgramTemplate = (templateId: string): Promise<number> => {
  return appApi
    .delete(`/work_program_template/${templateId}`)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};

export const getProgramTemplateById = (templateId: string): Promise<ProgramTemplate> => {
  return appApi
    .get(`/work_program_template/${templateId}`)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};

export const getProgramTemplateBySharedToken = (token: string): Promise<ProgramTemplate> => {
  return appApi
    .get(`/work_program_template/shared/${token}`)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};

export const getProgramTemplateList = ({sendBlocks = false, ...payload}: ProgramTemplatesRequest): Promise<ProgramTemplatesResponse> => {
  return appApi
    .get("/work_program_template/", {
      params: {sendBlocks, ...payload},
      paramsSerializer: {indexes: null}
    })
    .then(({data, headers}) => ({
      data,
      total: Number(headers["totalelements"]) || 0
    }))
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};

export const createProgramTemplate = (body: ProgramTemplateBody): Promise<ProgramTemplate> => {
  return appApi
    .post("/work_program_template/", body)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};

export const updateProgramTemplate = (templateId: number, body: ProgramTemplateBody): Promise<ProgramTemplate> => {
  return appApi
    .put(`/work_program_template/${templateId}`, body)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};

export const createProgramFromTemplate = (templateId: string, isProgram = true): Promise<ProgramTemplate> => {
  return appApi
    .post(`/work_program_template/copy/${templateId}?isProgram=${isProgram}`)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};

export const convertHtmlToDocx = (templateId: number, html: string): Promise<string> => {
  const bodyFormData = new FormData();
  const blob = new Blob([html], {type : "plain/text"});
  bodyFormData.append("file", blob);

  return appApi
    .post(`/work_program_template/convert/${templateId}`, bodyFormData, {
      headers: {"Content-Type": "multipart/form-data"}
    })
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};

export const checkConvertedDocx = (templateId: number, showNotification = true): Promise<"DONE" | "NEW"> => {
  return appApi
    .get(`/work_program_template/convert/check/${templateId}`)
    .then(res => res.data)
    .catch(error => {
      if (!showNotification) {
        throw error;
      }

      if (error?.response?.status === 404) {
        notification.error({message: "Docx ещё не сгенерирован"});
      } else {
        notification.error({message: "Ошибка"});
      }
      throw error;
    });
};

export const getConvertedDocx = (templateId: number): Promise<Blob> => {
  return appApi
    .get(`/work_program_template/convert/docx/${templateId}`, {responseType: "blob"})
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};

export const getProgramTemplateSharedToken = (templateId: string): Promise<string> => {
  return appApi
    .post(`/work_program_template/share/${templateId}`)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};

export const changeProgramTemplateStatus = (templateId: number, status: ProgramTemplateStatus) => {
  return appApi
    .put(`/work_program_template/change_status/${templateId}?status=${status}`)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};