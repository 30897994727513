import type {ActionReducerMapBuilder} from "@reduxjs/toolkit";
import type {ProfileState} from "./types";
import {getProfileAction, updateProfileAction} from "./actions";

export function getProfileBuilder(builder: ActionReducerMapBuilder<ProfileState>) {
  builder.addCase(getProfileAction.pending, (state) => {
    state.isProfileFetching = true;
  });
  builder.addCase(getProfileAction.fulfilled, (state, action) => {
    state.isProfileFetching = false;
    state.profile = {...state.profile, ...action.payload};
  });
  builder.addCase(getProfileAction.rejected, (state) => {
    state.isProfileFetching = false;
  });
}

export function updateProfileBuilder(builder: ActionReducerMapBuilder<ProfileState>) {
  builder.addCase(updateProfileAction.pending, (state) => {
    state.isProfileUpdating = true;
  });
  builder.addCase(updateProfileAction.fulfilled, (state, action) => {
    state.isProfileUpdating = false;
    state.profile = {...state.profile, ...action.payload};
  });
  builder.addCase(updateProfileAction.rejected, (state) => {
    state.isProfileUpdating = false;
  });
}
