import {AppPage} from "common/Layout";
import {Authorization} from "features/authorization";

export const AuthorizationPage = () => {
  return (
    <AppPage documentTitle="Авторизация">
      <Authorization/>
    </AppPage>
  );
};
