import {AppSider} from "shared/UI/UIKit/Sider";
import {TableFilter} from "common/TableFilter";
import {DictionariesTable} from "../Table/DictionariesTable";
import {DictionariesTableControls} from "../Controls/Controls";
import {CreateDictionaryModal} from "../Modals/Create/Create";
import {EditDictionaryModal} from "../Modals/Edit/Edit";
import {RemoveDictionaryModal} from "../Modals/Remove/Remove";
import {useDictionaries} from "./use-dictionaries";
import {dictionariesFilterFields} from "./filter-fields";

export const Dictionaries = () => {
  const {state, methods} = useDictionaries();

  return (
    <>
      <DictionariesTableControls createDictionary={methods.createDictionary} columns={state.columns}
                                 visibleKeys={state.visibleKeys} setVisibleKeys={methods.setVisibleKeys}
                                 showSider={methods.showSider} filterCount={state.filterCount}/>
      <DictionariesTable loading={state.loading}
                         dataSource={state.dictionaries}
                         columns={state.filteredColumns}
                         tableState={state.tableState}
                         onChange={methods.onTableChange}/>
      <CreateDictionaryModal visible={state.createModalVisible}
                             hide={() => methods.setCreateModalVisible(false)}/>
      <EditDictionaryModal visible={state.editModalVisible}
                           hide={() => methods.setEditModalVisible(false)}/>
      <RemoveDictionaryModal visible={state.removeModalVisible}
                             hide={() => methods.setRemoveModalVisible(false)}
                             onItemRemove={methods.onItemRemove}/>
      <AppSider title="Фильтры"
                isSiderVisible={state.siderVisible}
                submitBtnText="Применить"
                cancelBtnText="Сбросить всё"
                hideSider={methods.hideSider}>
        <TableFilter fields={dictionariesFilterFields}
                      submitBtnText="Применить"
                      resetBtnText="Сбросить всё"
                      onSubmit={methods.onFilterChange}
                      onClose={methods.hideSider}
                      fieldsValue={state.filterState}/>
      </AppSider>
    </>
  );
};