export const formNames = {
  id: "id",
  avatar: "avatar",
  lastName: "lastName",
  firstName: "firstName",
  middleName: "middleName",
  email: "email",
  region: ["region", "name"],
  municipality: ["municipality", "name"],
  organization: ["organization", "name"],
  emailVerificationToken: "emailVerificationToken",
  passwordRecoveryToken: "passwordRecoveryToken",
  emailConfirmed: "emailConfirmed",
};