import {AppModal} from "shared/UI/UIKit/Modal";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppText} from "shared/UI/UIKit/Text";
import {removeDictionaryAction} from "features/dictionaries/redux/actions";
import {clearSelectedDictionary} from "features/dictionaries/redux/slice";
import type {RemoveDictionaryModalProps} from "../types";
import {DictionaryModalFooter} from "../ModalFooter/ModalFooter";
import styles from "./Remove.module.scss";

export const RemoveDictionaryModal = (props: RemoveDictionaryModalProps) => {
  const {loading, selectedDictionary} = useAppSelector(state => state.dictionariesState);
  const dispatch = useAppDispatch();

  const confirm = () => {
    dispatch(removeDictionaryAction(selectedDictionary!.id.toString()))
      .unwrap()
      .then(() => {
        hideModal();
        props.onItemRemove();
      });
  };

  const hideModal = () => {
    props.hide();
    dispatch(clearSelectedDictionary());
  };

  const footer = <DictionaryModalFooter onSubmit={confirm}
                                        hideModal={hideModal}
                                        title="Удалить"/>;
  const content = (
    <AppFlex unit="8" className={styles.content}>
      <AppText size={16} type="secondary">
        Подтвердите удаление справочника
      </AppText>
      <AppText size={18}>
        {selectedDictionary?.name}
      </AppText>
    </AppFlex>
  );

  return (
    <AppModal visible={props.visible} onCancel={hideModal} size="auto"
              content={content} destroyOnClose footer={footer} loading={loading}
              title="Удаление справочника"/>
  );
};