import {Upload} from "antd";
import {useCallback} from "react";
import type {AppFileSelectorProps} from "./FileUploader.types";
import {useUploaderOnChange} from "../../hooks/use-uploader-on-change";

export function AppFileUploader(props: AppFileSelectorProps) {
  const beforeUpload = useCallback(props.beforeUpload || (() => false), []);
  const onChange = useUploaderOnChange(props.onChange);

  return (
    <Upload fileList={[]}
            {...props}
            beforeUpload={beforeUpload}
            onChange={onChange}
    />
  );
}
