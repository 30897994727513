import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppButton} from "shared/UI/UIKit/Button";
import type {TemplateModalFooterProps} from "./types";

export const TemplateModalFooter = (props: TemplateModalFooterProps) => {
  return (
    <AppFlex direction="row" justifyContent="flex-start">
      <AppButton onClick={props.onSubmit}>
        {props.title}
      </AppButton>
      <AppButton type="outline" onClick={props.hideModal}>
        Отмена
      </AppButton>
    </AppFlex>
  );
};