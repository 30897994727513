import {AppForm, AppFormItem, formRules} from "shared/UI/UIKit/Form";
import {AppInput} from "shared/UI/UIKit/Input";
import type {RegionFormProps} from "./types";

export const RegionForm = (props: RegionFormProps) => {
  return (
    <AppForm form={props.form}>
      <AppFormItem label="Название региона" labelColor="gray" size="max"
                   name="name" rules={formRules.required}>
        <AppInput placeholder="Название региона"/>
      </AppFormItem>
    </AppForm>
  );
};