import type {PaginationAndSort} from "services/api/types";

export type UserProfile = {
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  password: string;
  emailVerificationToken: string;
  passwordRecoveryToken: string;
  processingOfPersonalData: boolean;
  emailConfirmed: boolean;
  profileActivated: boolean;
  avatar: string;
  region: UserProfileRegion;
  municipality: UserProfileMunicipality;
  organization: UserProfileOrganization;
  role: UserProfileRole;
};

export type UserProfileRegion = {
  id: number | string;
  name: string;
}

export type UserProfileRegionBody = Omit<UserProfileRegion, "id">;

export type UserProfileMunicipality = {
  id: number | string;
  name: string;
  region: UserProfileRegion;
}

export type UserProfileOrganization = {
  id: number | string;
  name: string;
  fullName: string;
  municipality: UserProfileMunicipality;
}

export type GetOrganizationParams = {
  municipalityId?: string;
  regionId?: string;
  name?: string;
  page?: number;
  limit?: number;
  sort?: string;
}

export type UserProfileOrganizationBody = {
  name: string;
  fullName: string;
  municipality: {
    id: number;
    region: {
      id: number;
    }
  }
};

export type UserProfileMunicipalityBody = {
  name: string;
  region: {
    id: number;
  }
};

export enum UserProfileRole {
  ADMIN = "ADMIN",
  USER = "USER",
}

export type UserProfileBody = Omit<UserProfile, "id" | "role">;

export type UsersRequest = PaginationAndSort & {
  email?: string;
}