import type {FormInstance} from "rc-field-form/lib/interface";
import type {AppFormRuleObject, AppFormRuleRender} from "../../rules";

export const valueEqualityValidator = (
  targetFieldName: string,
  message: string,
): AppFormRuleRender => {
  return (form: FormInstance) => {
    return {
      validator: (rule: AppFormRuleObject, value: string | null | undefined) => {
        if (form.getFieldValue(targetFieldName) === value) {
          return Promise.resolve();
        }

        return Promise.reject(message);
      },
    };
  };
};